<div class="category-wrapper">
  <div class="breadcum-wrapper">
    <div class="breadcrumbs">

      <span>
        <a [routerLink]="['']">Home</a>
        <span class="icon"> > </span>
      </span>

      <span>
        <a [routerLink]="['/category/all']">Categories</a>
        <span *ngIf="catData != null" class="icon"> > </span>
      </span>

      <span *ngIf="catData != null">
        <a [routerLink]="['/category', catData.code]">{{catData.name}}</a>
        <span *ngIf="SubCatData != null" class="icon"> > </span>
      </span>

      <span *ngIf="SubCatData != null">
        <a [routerLink]="['/category', SubCatData.code]">{{SubCatData.name}}</a>
      </span>

    </div>
  </div>
  <router-outlet></router-outlet>
</div>
