<div class="-user-settings-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 text-center">
        <div class="form-title">Thank You !</div>
        <div class="text-order">Your order <a [routerLink]="['/user/order', reference]" >{{reference}}</a> has been submitted and will arrive : soon </div>
        <div class="text-order">Shipping updates will arrive via email.</div>
        <div class="margin-30"></div>
        <a [routerLink]="['/user/order']" class="btn btn-theme" style="letter-spacing: 4px">ORDER HISTORY</a>
        <div class="margin-30"></div>
        <div class="divider"></div>
        <div class="margin-30"></div>
        <div class="text-2">Having second thoughts? <a href="javascript:void(0)" (click)="cancelOrderModal()">Click Here</a> to cancel your order.</div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="delete_add" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header" style="padding-top: 0; padding-bottom: 0">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><img width="20px" src="{{ENV.ASSET_URL}}/icons/close.png" alt=""></span>
        </button>
      </div>
      <div class="modal-body">
        <div>Cancel Order?</div>
        <div class="margin-10"></div>
        <div class="text-muted text-danger"> Are you sure you want to Cancel this order?</div>
        <div class="margin-10"></div>
        <div class="form-group">
          <textarea  rows="5" placeholder="Cancel Reason" [(ngModel)]="cancelReason" class="form-control"></textarea>
        </div>
        <div class="margin-30"></div>
        <button data-dismiss="modal" aria-label="Close" class="btn btn-theme-outline w-100"  style="text-transform: uppercase"  (click)="cancelOrder()"> Don't Cancel </button>
        <div class="margin-10"></div>
        <button *ngIf="loading === false" type="submit" class="btn btn-theme w-100" style="text-transform: uppercase" (click)="cancelOrder()">Cancel Order</button>
        <button *ngIf="loading === true" disabled type="button" class="btn btn-theme w-100">YES, DELETE <i class="fa fa-spinner fa-spin"></i></button>
      </div>
    </div>
  </div>
</div>
