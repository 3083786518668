import { Component, OnInit } from '@angular/core';
import {PageConfig, SiteConfig} from "../Helpers/config";
import {CategoryService} from "../services/category.service";
import {AuthenticationService} from "../services/authentication.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";

declare const $: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  social: any = {
    facebook: '',
    twitter: '',
    instagram: '',
    google_plus: '',
  };
  pageData: any = null;
  constructor( protected authenticationService: AuthenticationService, private router: Router, private route: ActivatedRoute) {
    this.socialLink();
    this.getPages();
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.getPages();
      }
    });
  }

  getPages() {
    this.authenticationService.getPages().subscribe( res => {
      if (res.status == true) {
        this.pageData = res.data;
      }
    })
  }

  socialLink() {
    if(PageConfig() != null){
      this.social.facebook = PageConfig().facebook;
      this.social.twitter = PageConfig().twitter;
      this.social.instagram = PageConfig().instagram;
      this.social.google_plus = PageConfig().google_plus;
    }
  }

  ngOnInit(): void {
    window.onscroll = (ev) => {
      if (window.scrollY >= 300) {
        $('#up-logo').addClass('active');
      }
      if (window.scrollY === 0) {
        $('#up-logo').removeClass('active');
      }
    };
  }

  scroll() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
}
