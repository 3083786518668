<div class="checkout-component">
  <div class="head-checkout">
    <a [routerLink]="['']" class="logo">
      <img src="../../../assets/image/logo.png" alt="">
      <div class="brand-title">A1daam</div>
    </a>
    <span class="material-icons">https</span> &nbsp;
    <div class="text"><span class="sstext">SECURE</span> CHECKOUT</div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="form-box">
          <div class="inside-box">
            <div class="head-text">
              <span class="text-form" *ngIf="noAddres == true">STEP 1 OF 2:</span> Shipping Information
              <span class="float-right cursor-pointer" *ngIf="noAddres === false && editAddress === false"
                    (click)="editAddress = true">Edit</span>
              <span class="float-right cursor-pointer" *ngIf="editAddress === true "
                    (click)="editAddress = false; noAddres = false">Cancel</span>
            </div>
            <div *ngIf="editAddress == false && noAddres == false">
              <div class="add-box" *ngIf="selectedAddress != [] && selectedAddress != null">
                <div class="name">{{selectedAddress.first_name}} {{selectedAddress.last_name}}</div>
                <div class="add-w">{{selectedAddress.line1}}</div>
                <div class="add-w">{{selectedAddress.line2}}</div>
                <div class="add-w">{{selectedAddress.city}}, {{selectedAddress.postal_code}}</div>
                <div class="add-w">{{selectedAddress.phone}}</div>
                <div class="margin-10"></div>
                <div class="margin-10"></div>
              </div>
            </div>
            <div *ngIf="editAddress == true && noAddres == false">
              <div>Select a shipping address:</div>
              <div class="margin-10"></div>
              <div class="margin-10"></div>
              <div class="w-divider"></div>
              <div class="margin-10"></div>
              <div class="margin-10"></div>
              <div class="add-box" *ngFor="let address of addressData; let i = index">
                <div class="checkbox">
                  <input type="checkbox" (change)="selectAddress(address)" [checked]="address.id == selectedAddress.id">
                </div>
                <div class="name">{{address.first_name}} {{address.last_name}}</div>
                <div class="add-w">{{address.line1}}</div>
                <div class="add-w">{{address.line2}}</div>
                <div class="add-w">{{address.city}}, {{address.postal_code}}</div>
                <div class="add-w">{{address.phone}}</div>
                <div class="margin-10"></div>
                <div class="w-divider" *ngIf="addressData.length != i+1"></div>
                <div class="margin-10"></div>
              </div>
              <button class="btn btn-theme w-100" (click)="noAddres = true">ADD A NEW ADDRESS</button>
            </div>
            <form *ngIf="noAddres == true" ngNativeValidate (ngSubmit)="addAddress()">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label><span class="required">*</span>FIRST NAME</label>
                    <input type="text" name="first_name" class="form-control" required=""
                           [(ngModel)]="param.first_name">
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label><span class="required">*</span>LAST NAME</label>
                    <input type="text" class="form-control" name="last_name" required="" [(ngModel)]="param.last_name">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label><span class="required">*</span>ADDRESS LINE 1</label>
                    <input type="text" class="form-control" name="line1" required="" [(ngModel)]="param.line1">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>ADDRESS LINE 2 <span class="helper-text">(optional)</span></label>
                    <input type="text" class="form-control" name="line2" [(ngModel)]="param.line2">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label><span class="required">*</span>COUNTRY</label>
                    <select name="country" *ngIf="countryData != null" required="" [(ngModel)]="param.country"
                            class="form-control" (change)="getState()">
                      <option value="{{country.id}}" *ngFor="let country of countryData">{{country.name}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label><span class="required">*</span>STATE</label>
                    <select name="country" required="" class="form-control" (change)="getState()"
                            [(ngModel)]="param.state">
                      <option value="">Select State</option>
                      <option value="{{state.id}}" *ngFor="let state of stateData">{{state.state_name}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label><span class="required">*</span>ZIP CODE</label>
                    <input type="text" class="form-control" name="postal_code" required=""
                           [(ngModel)]="param.postal_code">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label><span class="required">*</span>CITY</label>
                    <input type="text" class="form-control" name="city" required="" [(ngModel)]="param.city">
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label><span class="required">*</span>PHONE</label>
                    <input type="text" class="form-control" name="phone" required="" [(ngModel)]="param.phone">
                  </div>
                </div>
              </div>
              <div class="form-group">
                <button *ngIf="loading === false" type="submit" class="btn btn-theme w-100">SAVE & CONTINUE</button>
                <button *ngIf="loading === true" disabled type="button" class="btn btn-theme w-100">SAVE & CONTINUE <i
                  class="fa fa-spinner fa-spin"></i></button>
              </div>
            </form>
          </div>
          <div class="add-box" *ngIf="delivery_available != true">
            <div style="color: red;">{{availability_error}}</div>
            <div class="margin-10"></div>
          </div>

        </div>
        <div class="form-box">
          <div class="inside-box">
            <div class="head-text ">
              <span class="text-form" *ngIf="noPayment == true">STEP 2 OF 2:</span> <span class="text-form-2"></span>
              Payment Information
            </div>
            <div *ngIf="paymentData">
              <div class="add-box">
                <div style="color: red; font-size: 10px;" *ngIf="paymentMethod == ''">* Select your prefered payment
                  method
                </div>
                <div class="margin-10"></div>
              </div>
              <div class="each-method">
                <input type="radio" id="cod" (change)="setPaymentMethod('cod')" [checked]="paymentMethod == 'cod'">
                <label for="cod" [ngStyle]="{'color': paymentMethod == 'cod' ? 'green' : ''}"> <i class="fa fa-money mr-2"></i>  Cash On Delivery</label>
              </div>
              <div class="each-method">
                <input type="radio" id="razorpay" (change)="setPaymentMethod('razorpay')" [checked]="paymentMethod == 'razorpay'">
                <label for="razorpay" [ngStyle]="{'color': paymentMethod == 'razorpay' ? 'green' : ''}"> <i class="fa fa-money mr-2"></i> Pay via CC/DB/Net Banking</label>
              </div>
            </div>
          </div>
        </div>


        <div class="form-box">
          <div class="inside-box">
            <div class="head-text ">
              <span class="text-form-2"></span> Apply Coupon Code
            </div>
            <div>
              <div class="add-box">
                <div style="color: red; font-size: 10px;">{{coupon_error}}</div>
                <div style="color: green; font-size: 10px;">{{coupon_success}}</div>
                <div class="margin-10"></div>
              </div>


              <div class="form-group">
                <input type="text" placeholder="Coupon Code" [(ngModel)]="coupon_code" class="form-control"/>
              </div>
              <button type="button" *ngIf="loading_coupon === false" (click)="verifyCoupon()"
                      class="btn btn-theme w-100">Apply Coupon
              </button>
              <button type="button" *ngIf="loading_coupon === true" disabled class="btn btn-theme w-100">Apply Coupon <i
                class="fa fa-spinner fa-spin"></i></button>


            </div>
          </div>
        </div>


        <div class="form-box">
          <div class="inside-box">
            <div class="head-text ">
              <span class="text-form-2"></span> Coupon Codes
            </div>
            <div>

              <div *ngIf="couponData.length > 0">
                <div *ngFor="let coupon of couponData">
                  <div class="each-method" (click)="setCouponCode(coupon.coupon_code)">
                    <div>
                      <small>{{coupon.coupon_code}}</small>
                      <br>
                      <p style="font-size: 14px;">{{coupon.description}}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="couponData.length == 0">
                <div class="each-method">
                  <div>
                    <small>No coupons are available</small>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>


      </div>
      <div class="col-lg-6">
        <div class="order-box" *ngIf="cartInfo != null">
          <!--          <button type="button" *ngIf="noAddres === true" disabled class="btn btn-theme w-100">PLACE YOUR ORDER</button>
                    <button type="button" *ngIf="noAddres === false && loading === false" (click)="addOrder()"  class="btn btn-theme w-100">PLACE YOUR ORDER</button>
                    <button type="button" *ngIf="loading === true" disabled  class="btn btn-theme w-100">PLACE YOUR ORDER <i class="fa fa-spinner fa-spin"></i></button>-->
          <div class="text-summery">
            Your Order Summary
          </div>
          <table class="order-table">
            <tr>
              <th>Subtotal</th>
              <td>{{cartInfo.subtotal}}</td>
            </tr>
            <tr>
              <th>Shipping Costs</th>
              <td>{{cartInfo.shipping}}</td>
            </tr>
            <tr>
              <th>Sales Tax</th>
              <td>{{cartInfo.order_tax}}</td>
            </tr>
            <tr>
              <th>Total Item Tax</th>
              <td>{{cartInfo.total_item_tax}}</td>
            </tr>
            <tr>
              <th class="text-total">ORDER TOTAL</th>
              <td class="text-total">{{cartInfo.grand_total}}</td>
            </tr>
            <!--            <tr>
                          <th>
                            <a href="javascript:void(0)" class="promo-text">
                              Have a promo code/gift card?
                            </a>
                          </th>
                        </tr>-->
          </table>
          <div class="order-text">
            <span>Order Total</span>
            <span *ngIf="discount_amount > 0"><span
              style="color: red; text-decoration: line-through;">{{cartInfo.grand_total}}</span>&nbsp;₹{{discounted_grand_total}}</span>
            <span *ngIf="discount_amount <= 0">{{cartInfo.grand_total}}</span>
          </div>
          <div class="form-group">
            <textarea placeholder="Notes" [(ngModel)]="note" rows="5" class="form-control"></textarea>
          </div>
          <button type="button" *ngIf="noAddres === true || paymentMethod == '' || delivery_available == false" disabled class="btn btn-theme w-100">
            PLACE YOUR ORDER
          </button>
          <button type="button"
                  *ngIf="(noAddres === false && paymentMethod != '' && delivery_available == true) && loading === false"
                  (click)="addOrder()" class="btn btn-theme w-100">PLACE YOUR ORDER
          </button>
          <button type="button" *ngIf="loading === true" disabled class="btn btn-theme w-100">PLACE YOUR ORDER <i
            class="fa fa-spinner fa-spin"></i></button>

        </div>


        <div class="order-box" *ngIf="cartInfo != null">
	  <div *ngIf="order_error" style="color: red;">{{order_error}}</div>
          <div *ngIf="delivery_available == true" style="color: red;">Your order will be delivered in {{cartInfo.delivery_days}} days</div>
          <div class="margin-10"></div>
          <div class="ordered-item-head" (click)="toggleProductOrdered()">
            <h3>YOUR ORDER <small class="small-text">({{cartInfo.total_unique_items}} items)</small></h3>
            <i class="fa fa-angle-up ilo" style="display: none"></i>
            <i class="fa fa-angle-down ilo"></i>
          </div>
          <div class="product-list or-pro">
            <div class="title">Items shipped by A1daam</div>
            <ul class="product-wrapper" *ngIf="cartInfo != null">
              <li class="each-product" *ngFor="let each of cartInfo.contents | keyvalue">
                <div class="product-ordered">
                  <!-- <div class="sale"  *ngIf="each.discount_price > 0">  SALE</div>-->
                  <div class="img-sec">
                    <img src="{{each.value.image}}" alt="">
                  </div>
                  <div class="text-sec">
                    <div class="text-up">
                      <div class="brand-name">{{each.value.name}}</div>
                      <div class="quantity">
                        <!--                        <span>12 x 16 oz</span>-->
                      </div>
                    </div>
                    <div class="item-orderd-text">Qty: {{each.value.qty}}</div>
                  </div>
                  <div class="price-text">
                    <div class="text-q">
                      <div class="original-price">{{each.value.price}}</div>
                      <!--                      <div class="old-price">$18.69</div>-->
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
