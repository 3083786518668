<div class="_cart_list_wrapper">
  <div class="breadcum-wrapper">
    <div class="breadcrumbs">
      <span>
        <a [routerLink]="['']">Home</a>
        <span class="icon"> > </span>
      </span>
      <span>
        <a [routerLink]="['/user/cart']">Shopping cart</a>
        <span class="icon">  </span>
      </span>
    </div>
  </div>
  <div class="container">
    <div class="page-heading">
      <div class="title">SHOPPING CART</div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="cart-wrapper">
          <div class="title" style="border: none">Cart</div>
          <div class="body-cart">
           <!-- <div class="cart-msg">
              <span>Standard Order (2)</span> <span>$26.70</span>
            </div>
            <div class="italic-msg">
              <span>Add $22.30 for free shipping or try a1daam Up</span>
            </div>
            <div class="bottom-border">
              <div class="inner-bar" style="width: 52%"></div>
            </div>-->
            <ul class="item-box" *ngIf="cartInfo != null">
              <li class="each-item" *ngFor="let each of cartInfo.contents | keyvalue">
                <a href="javascript:void(0)" class="item-info">
                  <span class="img-sec">
<!--                    <span class="sale">SALE</span>-->
                    <span class="img-p">
                     <img src="{{each.value.image}}" alt="">
                  </span>
                  </span>
                        <span class="info-sec">
                    <span>{{each.value.name}}</span>
                    <span class="bottom-info">
                      <span>{{each.value.subtotal}}</span>
                    </span>
                   </span>
                </a>
                <div class="add-section">
                  <div class="count">
                    <span class="minus" (click)="minusItem(each.value.product_id, each.value)">-</span>
                    <span class="quantity">{{each.value.qty}}</span>
                    <span class="plus" (click)="plusItem(each.value.product_id, each.value)">+</span>
                  </div>
                  <a href="javascript:void(0)" (click)="removeItem(each.value.rowid)">Remove</a>
                </div>
              </li>
            </ul>
          </div>
          <div  [routerLink]="['/secure/checkout']" class="bottom-text" *ngIf="cartInfo != null">
            CHECKOUT - {{cartInfo.total}}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="check-trigger-wrapper"  [routerLink]="['/secure/checkout']" *ngIf="cartInfo != null">
          <div class="title">ORDER SUBTOTAL</div>
          <button class="btn btn-theme">CHECKOUT - {{cartInfo.total}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
