import { Component, OnInit } from '@angular/core';
import {environment} from "../../environments/environment";
import {AuthenticationService} from "../services/authentication.service";
import {ActivatedRoute, Router} from '@angular/router';
declare const $: any;
declare let toastr: any;
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
})
export class RegisterComponent implements OnInit {
  ENV = environment;
  menuOpen = false;
  regInfo: any = {
    first_name: '',
    last_name: '',
    username: '',
    email: '',
    phone: '',
    password: '',
    password_confirm: '',
  };
  loading = false;


  constructor(protected authenticationService : AuthenticationService, private router: Router, private route: ActivatedRoute) {
    const Auth = this.authenticationService.getAuthFromSession();
    if (Auth !== null) {
      this.router.navigate(['']);
    }
    window.scrollTo(0, 0);
    this.closeSideMenu();
  }


  register() {
    this.loading = true;
    this.authenticationService.apiRegister(this.regInfo).subscribe( res => {
      if (res.status === true) {
        toastr.success(res.message);
        toastr.options.closeButton = true;
        this.router.navigate(['/login']);
      } else {
        this.ErrorHandaler(res.message);
        this.loading = false;
      }
    })
  }

  ErrorHandaler(errors) {
    $.each(errors, function (i, v) {
      $('[name=' + i + ']').closest('.form-group').find('.form-control').addClass('is-invalid');
      $('[name=' + i + ']').closest('.form-group').find('.invalid-feedback').html(v);
    })
  }


  ngOnInit(): void {
  }

  closeSideMenu() {
    $('.nav-bar-mobile').removeClass('visible');
    $('.hamburger .fa-times').hide();
    $('.hamburger .fa-bars').show();
    $('.display-overflow').hide();
    this.menuOpen = false;
  }

}
