<div class="_featured_brands_wrapper">
  <div class="container-fluid">
    <div class="page-header-cover">
      <div class="cover-image-wrapper"
           [ngStyle]="{'background-image': 'url('+ENV.ASSET_URL+'/dummy/brands/header.jpg)'}"></div>
      <div class="content-wrapper">
        <div class="content-wrapper-inner">
          <p class="slogan">CHECK OUT FEATURED BRANDS AT a1daam</p>
          <h2 class="title">Explore New Brands</h2>
          <button class="btn btn-theme">EXPLORE NOW</button>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="margin-20"></div>
    <div class="full-length-banner">
      <div class="cover-image-wrapper"
           [ngStyle]="{'background-image': 'url('+ENV.ASSET_URL+'/dummy/brands/1.jpg)'}"></div>
      <div class="content-wrapper">
        <div class="content-wrapper-inner">
          <h2 class="title">Something Different, Something Better</h2>
          <button class="btn btn-theme">SHOP CALIFIA FARMS</button>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="mini-length-banner">
          <div class="header-section">
            <h3 class="title">Dog Tested and Approved Treats and Toys</h3>
            <p class="slogan">With Dogly®</p>
          </div>
          <div class="cover-image">
            <img src="{{ENV.ASSET_URL}}/dummy/brands/m1.jpg" alt="">
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="mini-length-banner">
          <div class="header-section">
            <h3 class="title">Discover the Wholesome Goodness</h3>
            <p class="slogan">with Quaker®</p>
          </div>
          <div class="cover-image">
            <img src="{{ENV.ASSET_URL}}/dummy/brands/m2.jpg" alt="">
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="container">
    <div class="margin-10"></div>
    <div class="full-length-banner">
      <div class="cover-image-wrapper" [ngStyle]="{'background-image': 'url('+ENV.ASSET_URL+'/dummy/brands/2.jpg)'}"></div>
      <div class="content-wrapper">
        <div class="content-wrapper-inner">
          <h2 class="title">Premium Products For Home, Work, and Life</h2>
          <button class="btn btn-theme">SHOP PRINCE & SPRING</button>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="mini-length-banner">
          <div class="header-section">
            <h3 class="title">Focus on the moment. Not the dishes.</h3>
            <p class="slogan">With Dixie®</p>
          </div>
          <div class="cover-image">
            <img src="{{ENV.ASSET_URL}}/dummy/brands/m3.jpg" alt="">
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="mini-length-banner">
          <div class="header-section">
            <h3 class="title">What It Takes To Protect™</h3>
            <p class="slogan">With Lysol</p>
          </div>
          <div class="cover-image">
            <img src="{{ENV.ASSET_URL}}/dummy/brands/m4.jpg" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="margin-10"></div>
    <div class="full-length-banner">
      <div class="cover-image-wrapper" [ngStyle]="{'background-image': 'url('+ENV.ASSET_URL+'/dummy/brands/3.jpg)'}"></div>
      <div class="content-wrapper">
        <div class="content-wrapper-inner">
          <h2 class="title">Easy Recipes for Family Meals</h2>
          <button class="btn btn-theme">SHOP KRAFT</button>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="mini-length-banner">
          <div class="header-section">
            <h3 class="title">Fun On The Go!</h3>
            <p class="slogan">With Frito-Lay</p>
          </div>
          <div class="cover-image">
            <img src="{{ENV.ASSET_URL}}/dummy/brands/m5.jpg" alt="">
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="mini-length-banner">
          <div class="header-section">
            <h3 class="title">Real Energy Wherever You Need It</h3>
            <p class="slogan">With Nature Valley</p>
          </div>
          <div class="cover-image">
            <img src="{{ENV.ASSET_URL}}/dummy/brands/m6.jpg" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="margin-10"></div>
    <div class="full-length-banner">
      <div class="cover-image-wrapper" [ngStyle]="{'background-image': 'url('+ENV.ASSET_URL+'/dummy/brands/4.jpg)'}"></div>
      <div class="content-wrapper">
        <div class="content-wrapper-inner">
          <h2 class="title">Kellogg's Family Rewards</h2>
          <button class="btn btn-theme">SHOP KELLOGG'S</button>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="mini-length-banner">
          <div class="header-section">
            <h3 class="title">Fun On The Go!</h3>
            <p class="slogan">With Frito-Lay</p>
          </div>
          <div class="cover-image">
            <img src="{{ENV.ASSET_URL}}/dummy/brands/m7.jpg" alt="">
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="mini-length-banner">
          <div class="header-section">
            <h3 class="title">Real Energy Wherever You Need It</h3>
            <p class="slogan">With Nature Valley</p>
          </div>
          <div class="cover-image">
            <img src="{{ENV.ASSET_URL}}/dummy/brands/m8.jpg" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="margin-10"></div>
    <div class="full-length-banner">
      <div class="cover-image-wrapper" [ngStyle]="{'background-image': 'url('+ENV.ASSET_URL+'/dummy/brands/5.jpg)'}"></div>
      <div class="content-wrapper">
        <div class="content-wrapper-inner">
          <h2 class="title">Pure Protection That Works</h2>
          <button class="btn btn-theme">SHOP PAMPERS PURE</button>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="mini-length-banner">
          <div class="header-section">
            <h3 class="title">Fun On The Go!</h3>
            <p class="slogan">With Frito-Lay</p>
          </div>
          <div class="cover-image">
            <img src="{{ENV.ASSET_URL}}/dummy/brands/m9.jpg" alt="">
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="mini-length-banner">
          <div class="header-section">
            <h3 class="title">Real Energy Wherever You Need It</h3>
            <p class="slogan">With Nature Valley</p>
          </div>
          <div class="cover-image">
            <img src="{{ENV.ASSET_URL}}/dummy/brands/m10.jpg" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="margin-10"></div>
    <div class="full-length-banner">
      <div class="cover-image-wrapper" [ngStyle]="{'background-image': 'url('+ENV.ASSET_URL+'/dummy/brands/6.jpg)'}"></div>
      <div class="content-wrapper">
        <div class="content-wrapper-inner">
          <h2 class="title">The Newest Way to Hydrate</h2>
          <button class="btn btn-theme">SHOP SMARTWATER</button>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="mini-length-banner">
          <div class="header-section">
            <h3 class="title">Fun On The Go!</h3>
            <p class="slogan">With Frito-Lay</p>
          </div>
          <div class="cover-image">
            <img src="{{ENV.ASSET_URL}}/dummy/brands/m11.jpg" alt="">
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="mini-length-banner">
          <div class="header-section">
            <h3 class="title">Real Energy Wherever You Need It</h3>
            <p class="slogan">With Nature Valley</p>
          </div>
          <div class="cover-image">
            <img src="{{ENV.ASSET_URL}}/dummy/brands/m12.jpg" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="margin-10"></div>
    <div class="full-length-banner">
      <div class="cover-image-wrapper" [ngStyle]="{'background-image': 'url('+ENV.ASSET_URL+'/dummy/brands/7.jpg)'}"></div>
      <div class="content-wrapper">
        <div class="content-wrapper-inner">
          <h2 class="title">Improve Your Game Everyday</h2>
          <button class="btn btn-theme">SHOP UNILEVER</button>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="mini-length-banner">
          <div class="header-section">
            <h3 class="title">Fun On The Go!</h3>
            <p class="slogan">With Frito-Lay</p>
          </div>
          <div class="cover-image">
            <img src="{{ENV.ASSET_URL}}/dummy/brands/m13.jpg" alt="">
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="mini-length-banner">
          <div class="header-section">
            <h3 class="title">Real Energy Wherever You Need It</h3>
            <p class="slogan">With Nature Valley</p>
          </div>
          <div class="cover-image">
            <img src="{{ENV.ASSET_URL}}/dummy/brands/m14.jpg" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="margin-10"></div>
    <div class="full-length-banner">
      <div class="cover-image-wrapper" [ngStyle]="{'background-image': 'url('+ENV.ASSET_URL+'/dummy/brands/8.jpg)'}"></div>
      <div class="content-wrapper">
        <div class="content-wrapper-inner">
          <h2 class="title">Bring Goodness To The World</h2>
          <button class="btn btn-theme">SHOP HERSHEYS</button>
        </div>
      </div>
    </div>
  </div>
</div>
