<footer>
  <div class="footer-container">
    <div class="up-container">
      <div class="list">
        <!-- <h2 class="section-title">
          COMPANY
        </h2>
	-->
        <ul>
          <li>
            <a [routerLink]="['/category/all']"> All Products</a>
          </li>
          <li>
            <a [routerLink]="['/product/popular-products']" >
              <span>Popular Products</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="list">
        <!-- <h2 class="section-title">
          RESOURCES
        </h2>
	-->
        <ul>
          <li>
            <a [routerLink]="['/product/all-new']" >
              <span>All New  Products</span>
            </a>
          </li>
          <li>
            <a [routerLink]="['/product/deals']" >
              <span>Deals</span>
            </a>
          </li>
          <li>
            <a [routerLink]="['/subscriptions']" >
              <span> Subscriptions </span>
            </a>
          </li>
        </ul>
      </div>
      <div class="list">
	<!--
        <h2 class="section-title">
          QUICK LINKS
        </h2>
	-->
        <ul *ngIf="pageData != null">
          <li *ngFor="let pd of pageData">
            <a [routerLink]="['', pd.slug]"> {{pd.title}}</a>
          </li>
        </ul>
      </div>
      <div class="list big" *ngIf="social.facebook || social.twitter || social.instagram || social.google_plus">
        <h2 class="section-title">
          Follow Us
        </h2>
        <ul class="social-ul">
          <li *ngIf="social.facebook">
            <a href="{{social.facebook}}" target="_blank">
              <i class="fa fa-facebook-square"></i>
            </a>
          </li>
          <li *ngIf="social.twitter">
            <a href="{{social.twitter}}" target="_blank">
              <i class="fa fa-twitter"></i>
            </a>
          </li>
          <li *ngIf="social.instagram">
            <a href="{{social.instagram}}" target="_blank">
              <i class="fa fa-instagram"></i>
            </a>
          </li>
          <li *ngIf="social.google_plus">
            <a href="{{social.google_plus}}" target="_blank">
              <i class="fa fa-google-plus-square"></i>
            </a>
          </li>
        </ul>
        <!--
	<div class="download">
          <h2 class="title">Download our apps</h2>
          <a href="javascript:void(0)">
            <img width="100%" height="100%" src="../../assets/image/download-on-app-store.png" alt="">
          </a>
          <a href="javascript:void(0)">
            <img width="100%" height="100%" src="../../assets/image/app-store.png" alt="">
          </a>
        </div>
	-->
      </div>
    </div>
    <div class="tail">© 2020 A1daam - venture owned by medic4us.com</div>
  </div>
 <!-- <a href="javascript:void(0)" class="help">
    <span class="material-icons ques">help_outline</span>
    <span>Help</span>
  </a>-->

  <a href="javascript:void(0)" (click)="scroll()" id="up-logo">
    <span class="material-icons">
      expand_less
    </span>
  </a>
</footer>
