import { Component, OnInit } from '@angular/core';
import {environment} from '../../environments/environment';
import {NavigationEnd, Router} from "@angular/router";
import {CategoryService} from "../services/category.service";
declare const $: any;

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
})
export class CategoryComponent implements OnInit {

  ENV = environment;
  menuOpen = false;
  accType: any = '';

  catData: any = null;
  SubCatData: any = null;

  constructor(private router: Router, protected categoryService : CategoryService) {
    window.scrollTo(0, 0);
    this.closeSideMenu();
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
	const catCodeArr = event.url.split('/');
console.log("cacac")
	console.log(event.url);
        let catCode = catCodeArr[catCodeArr.length - 1];
        this.catData = null;
        this.SubCatData = null;
        if(catCode !== 'all'){
          this.categoryService.currentCategory.subscribe(data => {
            this.SubCatData = null;
            if (data == null) {
              // do nothing
            } else {
              data.forEach((v) => {
                if (catCode.replace(/%20/g, ' ') == v.code) {
                  this.catData = v;
                }
                v.sub_categories.forEach((vv) => {
                  if (catCode.replace(/%20/g, ' ') == vv.code) {
                    this.catData = v;
                    this.SubCatData = vv;
                  }
                });
              });
            }
          });
        }
      }
    });
  }

  ngOnInit(): void {}

  selectAcc(e) {
    let trigger = $(e.target);
    $('.box').removeClass('active');
    trigger.closest('.option-wrapper').find('.box').toggleClass('active');
  }

  openCatUnderMenu(e) {
    let trigger = $(e.target);
    let me =  trigger.closest('.list-li').find('.under-menu');
    if(me.is(":visible")){
      $('.under-menu').hide();
    } else{
      $('.under-menu').hide();
      trigger.closest('.list-li').find('.under-menu').toggle();
    }
  }

  closeSideMenu() {
    $('.nav-bar-mobile').removeClass('visible');
    $('.hamburger .fa-times').hide();
    $('.hamburger .fa-bars').show();
    $('.display-overflow').hide();
    this.menuOpen = false;
  }
}
