import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import {environment} from '../../../environments/environment';
import {CategoryService} from '../../services/category.service';
import {AuthenticationService} from '../../services/authentication.service';
declare const $: any;
declare let toastr: any;
declare let Ladda: any;

@Component({
  selector: 'app-favorite-products',
  templateUrl: './FavoriteProducts.component.html',
})
export class FavoriteProductsComponent implements OnInit {

  ENV = environment;
  menuOpen = false;
  ProductData: any = null;
  param = {
    max_price: '',
    min_price: '',
    brand_code: '',
    order_by: '',
    page: '',
    search: '',
  };

  cartParam: any = {
    quantity: 1,
    option: 2,
    cart_id: '',
  };
  catCode: any = '';
  is_logged = false;
  Loading = true;

  constructor(private router: Router, protected categoryService : CategoryService, protected authenticationService : AuthenticationService) {
    const Auth = this.authenticationService.getAuthFromSession();
    if (Auth !== null) {
      this.is_logged = Auth.is_logged;
      this.cartParam.cart_id = Auth.cart_id;
      this.getWishProduct()
    } else {
      this.router.navigate(['']);
      setTimeout(function () {
        $('#register').modal('show');
      },100)
    }
    this.categoryService.currentCategory.subscribe(data => {
      if (data == null) {
        // do nothing
      } else {
      }
    });

    window.scrollTo(0, 0);
    this.closeSideMenu();
  }


  getWishProduct() {
    this.Loading = true;
    this.categoryService.getWishProduct().subscribe( res => {
      if (res.status === true) {
        this.ProductData = res.data.product_detail;
        this.Loading = false;
      }
    })
  }

  addTowish(product) {
    this.categoryService.apiDeleteWish(product.id).subscribe( res => {
      if (res.status === true) {
        this.getWishProduct()
      }
    });
  }

  addToCart(product, e) {
    let ldd = e.target;
    let ladda = Ladda.create(ldd);
    if (this.is_logged === true) {
      ladda.start();
      this.categoryService.apiAddCart(this.cartParam, product.id).subscribe( res => {
        if (res.status === true && res.is_logged === true) {
          this.getCartDetail();
          ladda.stop();
        } else if (res.status === false) {
          toastr.error(res.message);
          toastr.options.closeButton = true;
          ladda.stop();
        }
      })
    } else {
      let cart_id = localStorage.getItem('guestCartID');
      cart_id = JSON.parse(cart_id);
      if (cart_id != null) {
        this.cartParam.cart_id = cart_id;
        ladda.start();
        this.categoryService.apiAddCart(this.cartParam, product.id).subscribe( res => {
          ladda.stop();
          if (res.status === true ) {
            this.getCartDetail();
          } else {
            toastr.error(res.message);
            toastr.options.closeButton = true;
          }
        })
      } else {
        ladda.start();
        this.categoryService.apiAddCart(this.cartParam, product.id).subscribe( res => {
          ladda.stop();
          if (res.status === true ) {
            this.cartParam.cart_id = res.cart_id;
            let cartID_set = JSON.stringify(res.cart_id);
            localStorage.setItem("guestCartID", cartID_set);
            this.getCartDetail();
          } else {
            toastr.error(res.message);
            toastr.options.closeButton = true;
          }
        });
      }
    }
  }


  getCartDetail() {
    this.categoryService.apiGetCart(this.cartParam.cart_id).subscribe( res => {
      if (res.status === true) {
        this.authenticationService.updateCart(res.data);
      }
    })
  }


  ngOnInit(): void {

  }

  closeSideMenu() {
    $('.nav-bar-mobile').removeClass('visible');
    $('.hamburger .fa-times').hide();
    $('.hamburger .fa-bars').show();
    $('.display-overflow').hide();
    this.menuOpen = false;
  }
}
