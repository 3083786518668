<div class="page-wrapper">
  <div class="side-nav-wrapper">
    <ul class="menu-ul" *ngIf="CategoryData != []">
      <li class="list-li">
        <div class="title-item">Category</div>
      </li>
      <li class="list-li" *ngFor="let eachCat of CategoryData; let i = index">
        <a href="javascript:void(0)" (click)="openCatUnderMenu($event)" class="item">{{eachCat.name}}
        </a>
        <i class="fa fa-angle-down ud-icon"></i>
        <ul class="under-menu"  *ngIf="eachCat.sub_categories != []">
          <li>
            <a [routerLink]="['/category', eachCat.code]">All {{eachCat.name}}</a>
          </li>
          <li *ngFor="let eachSubCat of eachCat.sub_categories">
            <a [routerLink]="['/category', eachSubCat.code]">{{eachSubCat.name}}</a>
          </li>
        </ul>
      </li>
    </ul>
    <ul class="menu-ul" *ngIf="BrandData != null && BrandData != []">
      <li class="list-li">
        <div class="title-item">FEATURED BRANDS</div>
      </li>
      <li class="list-li select-box" *ngFor="let eachBrand of BrandData">
        <div class="form-group">
          <div class="option-wrapper">
            <div class="g-input" (click)="selectAcc($event, eachBrand.code)">
              <div  [ngClass]="{box: true, active: param.brand_code == eachBrand.code}">
                <span></span>
              </div>
              <label >{{eachBrand.name}}({{eachBrand.product_count}})</label>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="under-category">
    <div class="loading" *ngIf="Loading == true">
      <div class="blobs">
        <div class="blob-center"></div>
        <div class="blob"></div>
        <div class="blob"></div>
        <div class="blob"></div>
        <div class="blob"></div>
        <div class="blob"></div>
        <div class="blob"></div>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
        <defs>
          <filter id="goo">
            <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
            <feBlend in="SourceGraphic" in2="goo" />
          </filter>
        </defs>
      </svg>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="header-cat">
          <h1 class="title-name">All A1daam Products</h1>
        </div>
      </div>
      <div class="col-md-6">
        <div class="sort-input">
          <select class="category-select" [(ngModel)]="param.order_by" (ngModelChange)="param.page=1;getProductByCategory()">
            <option value="">Sort</option>
            <option value="views,desc">Sort by Popularity</option>
            <option value="rating,decs">Sort by Average Rating</option>
            <option value="id,desc">Sort by Newness</option>
            <option value="price,asc">Price (Low to High)</option>
            <option value="price,desc">Price (High to Low)</option>
          </select>
        </div>
      </div>
    </div>
    <div class="alert alert-info text-center" *ngIf="errMsg != ''">{{errMsg}}</div>
    <ul class="p-item" *ngIf="ProductData != null && ProductData != []">
      <li *ngFor="let product of ProductData; let index = index;">
        <div class="product" [ngStyle]="{'filter': (product.hide == 1) ? 'grayscale(100%)' : ''}">
          <div class="sale" *ngIf="product.discount_price > 0">sale</div>
          <a   [routerLink]="['/product',product.slug]">
            <div class="p-img">
              <img [ngClass]="{'img0' : product.photos.length > 0}" src="{{product.image_url}}" alt="">
              <img *ngIf="product.photos.length > 0" class="img1" src="{{product.photos[0].photo_url}}" alt="">
            </div>
            <div class="p-info">
	      <div class="sub-feature" *ngIf="product.discount_price > 0 && product.hide == 0">OFFERED PRODUCT</div>
              <div class="sub-feature" *ngIf="product.hide == 1">OUT OF STOCK</div>
              <span class="p-title"> {{product.name}} </span>
              <div class="p-subtitle"  *ngIf="product.variants != []">
                <div class="text-1" *ngFor="let v of product.variants; let kk = index">{{v.name}}  <i *ngIf="product.variants.length != kk+1" class="dot fa fa-circle"></i></div>
              </div>
            </div>
          </a>
          <span *ngIf="product.discount_price == 0" class="price">{{product.formated_price}}</span>
          <div *ngIf="product.discount_price > 0"  class="product-price-wrapper">
            <div class="current-price">
              <div class="value">{{product.formated_price}}</div>
            </div>
            <div class="real-price">
              <span class="value">{{product.formated_list_price}}</span><br>
              <span class="sub-slogan">List Price</span>
            </div>
          </div>
          <div class="retail_save" *ngIf="product.discount_percent > 0">save {{product.discount_percent}}% vs retail</div>
          <div class="options-wrapper">
	    <button type="button" class="btn btn-outline-secondary add-cart-btn" [ngClass]="{'disabled': product.hide == 1}" (click)="product.hide == 0 ? addToCart(product, $event) : null">ADD</button>
            <div class="love-wrapper">
               <span class="cursor-pointer" (click)="addTowish(product, index)">
                <i *ngIf="product.wishlist === 0" aria-hidden="true" class="fa fa-heart-o"></i>
                <i *ngIf="product.wishlist === 1" aria-hidden="true" class="fa fa-heart"></i>
              </span>
            </div>
          </div>
        </div>
      </li>
      <a href="javascript:void(0)" class="btn-login btn-block load-more" *ngIf="param.page <= lastPage" (click)="loadMore()">VIEW MORE</a>
    </ul>
  </div>
</div>

