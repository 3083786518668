import {Inject, Injectable, Optional} from '@angular/core';
import {environment} from "../../environments/environment";
import {
  HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent
} from '@angular/common/http';
import {Observable, BehaviorSubject} from 'rxjs';
import {AuthenticationService} from '../services/authentication.service';
declare const $: any;

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  protected basePath = environment.API_URL;
  protected token = '';
  public defaultHeaders = new HttpHeaders();


  category = new BehaviorSubject<any>(null);
  currentCategory = this.category.asObservable();

  brand = new BehaviorSubject<any>(null);
  currentBrand = this.brand.asObservable();

  constructor(protected httpClient: HttpClient, protected authenticationService: AuthenticationService) {
    const user = this.authenticationService.getAuthFromSession();
    if(user !== null){
      this.token = user.token;
    }
    this.authenticationService.currentUser.subscribe(user => {
      if(user !== null){
        this.token = user.token;
      }
    });
  }

  updateCategory(data) {
    this.category.next(data);
  }

  updateBrand(data) {
    this.brand.next(data);
  }

  public getAllNotification(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);
    headers = headers.set('X-token', this.token);
    // headers = headers.set('mba', this.token);

    return this.httpClient.get(`${this.basePath}/notifications/get_notifications`, { headers: headers });
  }

  public getAllCategories(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);
    headers = headers.set('X-token', this.token);
    // headers = headers.set('mba', this.token);

    return this.httpClient.get(`${this.basePath}/categories`, { headers: headers });
  }
  public getAllBrands(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);
    headers = headers.set('X-token', this.token);
    // headers = headers.set('mba', this.token);

    return this.httpClient.get(`${this.basePath}/brands`, { headers: headers });
  }
  public getProductByCategory(codeName: any = '', param: any = {}, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);
    headers = headers.set('X-token', this.token);
    // headers = headers.set('mba', this.token);

    const paramUrl = $.param(param);
    return this.httpClient.get(`${this.basePath}/products/list?category_code=${encodeURIComponent(String(codeName))}&${paramUrl}`, { headers: headers });
  }

  public getProductSingle(productID: any = '', observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);
    headers = headers.set('X-token', this.token);
    // headers = headers.set('mba', this.token);

    return this.httpClient.get(`${this.basePath}/products/read?product_id=${encodeURIComponent(String(productID))}`, { headers: headers });
  }
  public getWishProduct(productID: any = '', observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);
    headers = headers.set('X-token', this.token);

    return this.httpClient.get(`${this.basePath}/wishlist/list`, { headers: headers });
  }

  public apiAddWish(productID: any = '', observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);
    headers = headers.set('X-token', this.token);
    // headers = headers.set('mba', this.token);

    return this.httpClient.post(`${this.basePath}/wishlist/add/${encodeURIComponent(String(productID))}`, '',{ headers: headers });
  }
  public apiDeleteWish(productID: any = '', observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);
    headers = headers.set('X-token', this.token);
    // headers = headers.set('mba', this.token);

    return this.httpClient.post(`${this.basePath}/wishlist/delete/${encodeURIComponent(String(productID))}`, '',{ headers: headers });
  }

  public apiAddCart(body: any = '', productID: any = '', observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);
    headers = headers.set('X-token', this.token);
    let formData = new FormData();
    for ( let key in body ) {
      formData.append(key, body[key]);
    }
    return this.httpClient.post(`${this.basePath}/cart/add/${encodeURIComponent(String(productID))}`, formData,{ headers: headers });
  }

  public apiGetCart(cartID: any = '', pincode: any = '', observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);
    headers = headers.set('X-token', this.token);

	  if(pincode) {
	  	return this.httpClient.get(`${this.basePath}/cart/read?cart_id=${encodeURIComponent(String(cartID))}&pincode=${encodeURIComponent(String(pincode))}`,{ headers: headers });
	  } else {
		return this.httpClient.get(`${this.basePath}/cart/read?cart_id=${encodeURIComponent(String(cartID))}`,{ headers: headers });
	  }
	  }


   public apiPincodeAvailablity(pincode: any = '', observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);
    headers = headers.set('X-token', this.token);

	   return this.httpClient.get(`${this.basePath}/address/availability?pincode=${encodeURIComponent(String(pincode))}`,{ headers: headers });
     }

  public apiGetReview(productID: any = '', page: any = '', observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);
    headers = headers.set('X-token', this.token);

    return this.httpClient.get(`${this.basePath}/review/read/${encodeURIComponent(String(productID))}?page=${encodeURIComponent(String(page))}`,{ headers: headers });
  }

  public apiRemovaCart(body: any = '', rowID: any = '', observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);
    headers = headers.set('X-token', this.token);
    let formData = new FormData();
    formData.append('cart_id', body);
    return this.httpClient.post(`${this.basePath}/cart/delete/${encodeURIComponent(String(rowID))}`,formData, { headers: headers });
  }

  public apiAddReview(body: any = '', observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);
    headers = headers.set('X-token', this.token);
    let formData = new FormData();
    for ( let key in body ) {
      formData.append(key, body[key]);
    }
    return this.httpClient.post(`${this.basePath}/review/add`,formData, { headers: headers });
  }

}
