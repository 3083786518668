import { Component } from '@angular/core';
import {AuthenticationService} from "./services/authentication.service";
import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'a1daam';
  template = 0;
  constructor(@Inject(DOCUMENT) private _document: HTMLDocument, protected authenticationService: AuthenticationService) {
    this.getConfig();
  }

  getConfig = () => {
    this.authenticationService.getConfig().subscribe(res => {
    localStorage.setItem('_site_config_', JSON.stringify(res.data));
    	this._document.getElementById('appFavicon').setAttribute('href', res.data.logo_slug);
      localStorage.setItem('pageSettings', JSON.stringify(res.data.shop_settings));
      this.template = 1;
    })
  }
}
