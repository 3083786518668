import { Component, OnInit } from '@angular/core';
import {environment} from '../../../environments/environment';
import {AuthenticationService} from '../../services/authentication.service';
import {Router} from '@angular/router';
declare const $: any;
declare const toastr: any;

@Component({
  selector: 'app-list-address',
  templateUrl: './ListAddress.component.html'
})
export class ListAddressComponent implements OnInit {

  ENV = environment;
  menuOpen = false;
  is_logged = false;
  loading = false;
  addressData: any = null;
  addID: any = '';
  constructor(protected authenticationService : AuthenticationService,  private router: Router) {
    const Auth = this.authenticationService.getAuthFromSession();
    if (Auth !== null) {
      this.is_logged = Auth.is_logged;
      this.getAddress();
    } else {
      this.router.navigate(['']);
      setTimeout(function () {
        $('#register').modal('show');
      },100)
    }
    window.scrollTo(0, 0);
    this.closeSideMenu();
  }
  ngOnInit(): void {}


  getAddress() {
    this.authenticationService.getAddress().subscribe(res => {
      if (res.status === true) {
        this.addressData = res.data;
      }
    })
  }

  openModal(id) {
    this.addID = id;
    $('#delete_add').modal('show');
  }

  deleteAddress() {
    this.loading = true;
    this.authenticationService.apiDeleteAddress('', this.addID).subscribe( res => {
      if (res.status === true) {
        toastr.success(res.message);
        toastr.options.closeButton = true;
        $('#delete_add').modal('hide');
        this.getAddress();
        this.loading = false;
      }
    })
  }

  closeSideMenu() {
    $('.nav-bar-mobile').removeClass('visible');
    $('.hamburger .fa-times').hide();
    $('.hamburger .fa-bars').show();
    $('.display-overflow').hide();
    this.menuOpen = false;
  }
}
