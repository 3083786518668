<div class="-user-settings-wrapper">
  <div class="container">
    <div class="page-heading bg-padding">
      <div class="title">Manage Your Inbox</div>
      <div class="margin-30"></div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <p>Unsubscribe All</p>
        <p class="form-upper-slogan">We’d be sad to see you go, but you can always come back!</p>
        <div class="row">
          <div class="col-md-6">
            <div class="margin-10"></div>
            <form action="">
              <div class="form-group">
                <select class="form-control">
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>
              </div>
            </form>
          </div>
        </div>
        <button class="btn btn-theme w-100">SAVE MY PREFERENCES</button>
      </div>
    </div>
  </div>
</div>
