export const PageConfig = () => {
  return  JSON.parse(localStorage.getItem('pageSettings'));
};

export const SiteConfig = () => {
  return  JSON.parse(localStorage.getItem('_site_config_'));
};

export const GetAuthUserSession = () => {
  return  JSON.parse(localStorage.getItem('userData'));
};



