<div class="-user-settings-wrapper">
  <div class="breadcum-wrapper">
    <div class="breadcrumbs">
      <span>
        <a [routerLink]="['']">Home</a>
        <span class="icon"> > </span>
      </span>
      <span>
        <a [routerLink]="['/user/account-settings']">Settings</a>
        <span class="icon"> > </span>
      </span>
      <span>
        <a [routerLink]="['/user/order']">Order History</a>
        <span class="icon">  </span>
      </span>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="form-title">Order History</div>
        <!-- <div class="text-center w-100">
           <div>No order</div>
           <br>
           <a [routerLink]="['']" class="btn btn-theme ">Start Shopping</a>
         </div>-->
        <div class="loading" *ngIf="Loading == true">
          <div class="blobs">
            <div class="blob-center"></div>
            <div class="blob"></div>
            <div class="blob"></div>
            <div class="blob"></div>
            <div class="blob"></div>
            <div class="blob"></div>
            <div class="blob"></div>
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
            <defs>
              <filter id="goo">
                <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
                <feBlend in="SourceGraphic" in2="goo" />
              </filter>
            </defs>
          </svg>
        </div>
        <div class="row" *ngFor="let order of orderData; let i = index">
          <div class="col-sm-6">
            <div class="text-order"> Order: <a [routerLink]="['/user/order', order.reference_no]" class="order-no">{{order.reference_no}}</a></div>
            <div class="text-order"> {{order.date | date}}</div>
            <div class="text-order">  Standard Order <span class="status">{{order.sale_status}}</span></div>
            <div class="text-order" *ngIf="order.customer_request_status != ''">  Customer request status <span class="status">{{order.request_status}}</span></div>
            <img style="width: 70px;margin: 10px;" *ngFor="let img of order.items" src="{{img.image_url}}" class="order-item-img" alt="">
          </div>
          <div class="col-sm-6 text-right">
            <a  [routerLink]="['/user/order', order.reference_no]" class="order-no"> Details <i class="fa fa-angle-double-right" aria-hidden="true"></i></a>
            <div class="text-order"><!--Saved: $0.80  |-->  Total: {{order.formatted_grand_total}}</div>
            <div class="margin-10"></div>
            <button class="btn btn-theme" style="letter-spacing: 4px" (click)="openCartModal(order)">ADD TO CART</button>
          </div>
          <div class="margin-30"></div>
          <div class="divider" *ngIf="orderData.length != i+1"></div>
          <div class="margin-30" *ngIf="orderData.length != i+1"> </div>
        </div>
        <a href="javascript:void(0)" class="btn-login btn-block load-more" *ngIf="param.page < lastPage" (click)="getOrder()" >VIEW MORE</a>
        <div class="margin-30"></div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="cart_add" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header" style="padding-top: 0; padding-bottom: 0">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><img width="20px" src="{{ENV.ASSET_URL}}/icons/close.png" alt=""></span>
        </button>
      </div>
      <div class="modal-body" *ngIf="eachOrder != null">
        <div class="row">
          <div class="col-lg-3"  *ngFor="let each of eachOrder.items">
            <div class="modal-product">
              <div class="img">
                <img width="100%" height="200px" src="{{each.image_url}}" alt="">
              </div>
              <div class="name">{{each.product_name}}</div>
              <div class="margin-10"></div>
              <div class="price-modal">{{each.formatted_unit_price}}</div>
              <div class="btn btn-outline-secondary add-cart-btn-modal" *ngIf="!each.hide" (click)="addToCart(each, $event)"> ADD</div>
	      <div class="btn btn-outline-secondary" *ngIf="each.hide"> Out Of Stock</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
