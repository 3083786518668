import { Component, OnInit } from '@angular/core';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import {environment} from '../../environments/environment';
import {CategoryService} from '../services/category.service';
import {AuthenticationService} from '../services/authentication.service';
declare const $: any;
declare let toastr: any;
declare let Ladda: any;

@Component({
  selector: 'app-FreeSample',
  templateUrl: './FreeSample.component.html',
})
export class FreeSampleComponent implements OnInit {

  menuOpen = false;
  ENV = environment;
  Loading = true;
  is_logged = false;
  ProductData: any = [];
  CategoryData: any = null;
  BrandData: any = null;
  lastPage: any = '';
  errMsg: any = '';
  param = {
    max_price: '',
    min_price: '',
    brand_code: '',
    order_by: '',
    page: 1,
    search: '',
  };
  cartParam: any = {
    quantity: 1,
    option: 2,
    cart_id: '',
  };
  cartInfo: any = null;
  catCode: any = 'subscription';
  constructor(private router: Router, protected authenticationService : AuthenticationService,  protected categoryService : CategoryService,  private route: ActivatedRoute) {
    const Auth = this.authenticationService.getAuthFromSession();
    this.authenticationService.currentCart.subscribe( res => {
      this.cartInfo = res;
    });
    if (Auth !== null) {
      this.is_logged = Auth.is_logged;
      this.cartParam.cart_id = Auth.cart_id;
      // this.getCartDetailForCheck();
    }
    this.categoryService.currentCategory.subscribe(data => {
      if (data == null) {
        // do nothing
      } else {
        this.CategoryData = data;
        this.getProductByCategory()
      }
    });
    this.categoryService.currentBrand.subscribe(data => {
      if (data == null) {
        // do nothing
      } else {
        this.BrandData = data;
      }
    });
    window.scrollTo(0, 0);
    this.closeSideMenu();
  }

  getProductByCategory() {
    this.Loading = true;
    this.errMsg = '';
    this.categoryService.getProductByCategory(this.catCode, this.param).subscribe( res => {
      if (res.status === true) {
        if (this.param.page === 1) {
          this.ProductData = [];
          this.lastPage = res.page_info.total;
          this.param.page += 1;
          res.data.forEach((v) => {
            this.ProductData.push(v);
          });
        } else {
          this.param.page += 1;
          res.data.forEach((v) => {
            this.ProductData.push(v);
          });
        }
        this.Loading = false;
      } else {
        this.ProductData = [];
        this.Loading = false;
        this.errMsg = res.message
      }
    })
  }

  loadMore() {
    this.getProductByCategory()
  }

  addTowish(product, i) {
    const productId = product.id;
    if (this.is_logged == true) {
      if (product.wishlist == 0) {
        this.categoryService.apiAddWish(productId).subscribe( res => {
          if (res.status === true) {
            this.ProductData[i].wishlist = 1;
          } else {
            $('#login').modal('show');
          }
        });
      } else {
        this.categoryService.apiDeleteWish(productId).subscribe( res => {
          if (res.status === true) {
            this.ProductData[i].wishlist = 0;
          }
        });
      }
    }  else {
      $('#login').modal('show');
    }
  }

  addToCart(product, e) {
    let ldd = e.target;
    let ladda = Ladda.create(ldd);
    if (this.cartInfo != null) {
      if (this.cartInfo.total_items == 0) {
        if (this.is_logged === true) {
          ladda.start();
          this.categoryService.apiAddCart(this.cartParam, product.id).subscribe( res => {
            ladda.stop();
            if (res.status === true && res.is_logged === true) {
              this.getCartDetail();
            } else if (res.status === false) {
              toastr.error(res.message);
              toastr.options.closeButton = true;
              ladda.stop();
            }
          })
        } else {
          $('#login').modal('show');
        }
      } else {
        toastr.error('Please remove item from cart before adding subscription');
        toastr.options.closeButton = true;
      }
    } else {
      if (this.is_logged === true) {
        ladda.start();
        this.categoryService.apiAddCart(this.cartParam, product.id).subscribe( res => {
          if (res.status === true && res.is_logged === true) {
            this.getCartDetail();
            ladda.stop();
          } else if (res.status === false) {
            toastr.error(res.message);
            toastr.options.closeButton = true;
            ladda.stop();
          }
        })
      } else {
        $('#login').modal('show');
      }
    }
  }

  getCartDetail() {
    this.categoryService.apiGetCart(this.cartParam.cart_id).subscribe( res => {
      if (res.status === true) {
        this.authenticationService.updateCart(res.data);
      }
    })
  }

  getCartDetailForCheck() {
    this.categoryService.apiGetCart(this.cartParam.cart_id).subscribe( res => {
      if (res.status === true) {
        this.cartInfo = res.data;
      }
    })
  }



  ngOnInit(): void {
  }

  selectAcc(e, code) {
    this.param.page = 1
    this.param.brand_code = code;
    this.getProductByCategory();
    let trigger = $(e.target);
    $('.box').removeClass('active');
    trigger.closest('.option-wrapper').find('.box').toggleClass('active');

  }

  openCatUnderMenu(e) {
    let trigger = $(e.target);
    trigger.closest('.list-li').find('.under-menu').toggle();
  }

  closeSideMenu() {
    $('.nav-bar-mobile').removeClass('visible');
    $('.hamburger .fa-times').hide();
    $('.hamburger .fa-bars').show();
    $('.display-overflow').hide();
    this.menuOpen = false;
  }

}
