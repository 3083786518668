<div class="-user-settings-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="loading" *ngIf="Loading == true">
          <div class="blobs">
            <div class="blob-center"></div>
            <div class="blob"></div>
            <div class="blob"></div>
            <div class="blob"></div>
            <div class="blob"></div>
            <div class="blob"></div>
            <div class="blob"></div>
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
            <defs>
              <filter id="goo">
                <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur"/>
                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
                               result="goo"/>
                <feBlend in="SourceGraphic" in2="goo"/>
              </filter>
            </defs>
          </svg>
        </div>
        <div class="row about-page" *ngIf="pageInfo != null">
          <div class="title">{{pageInfo.title}}</div>
          <div class="description">{{pageInfo.description}}</div>
          <div [innerHTML]="pageInfo.body"></div>
          <div class="margin-30"></div>
        </div>
      </div>
    </div>
  </div>
</div>
