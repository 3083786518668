<div class="bread-crumb-wrapper" style="padding-top: 15px">
  <div class="bread-crumb-p">
    <div>
      <span>
        <a [routerLink]="['']">Home</a> <span class="divi" > > </span></span>
      <span>
        <a href="javascript:void(0)" *ngIf="ProductData != null">{{ProductData.name}}</a> <span class="divi"></span></span>
    </div>
  </div>
  <div class="social-p">
    <div class="social-box">
      <a href="javascript:void(0)" (click)="copyLink()">
        <input type="text" value="{{url}}" id="uurl" style="opacity: 0; pointer-events: none;">
        <span>Share</span><span style="font-size: 13px;" class="material-icons">share</span>
      </a>
    </div>
  </div>
</div>

<div class="loading" *ngIf="Loading == true">
  <div class="blobs">
    <div class="blob-center"></div>
    <div class="blob"></div>
    <div class="blob"></div>
    <div class="blob"></div>
    <div class="blob"></div>
    <div class="blob"></div>
    <div class="blob"></div>
  </div>
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
    <defs>
      <filter id="goo">
        <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur"/>
        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo"/>
        <feBlend in="SourceGraphic" in2="goo"/>
      </filter>
    </defs>
  </svg>
</div>

<div class="container" style="display:block;" *ngIf="ProductData != null">
  <div class="row">
    <div class="col-lg-7">
      <div class="mobile-show-detail">
        <div class="love">
          <i class="fa fa-heart-o"></i> {{ProductData.views}}
        </div>
        <div class="info-section" [ngStyle]="{'filter': (ProductData.hide == 1) ? 'grayscale(100%)' : ''}">
          <div class="sale" *ngIf="ProductData.discount_price > 0">sale</div>
          <h1><span *ngIf="ProductData.brand != null">{{ProductData.brand.name}}</span> <br> {{ProductData.name}}</h1>
          <div class="p-subtitle" style=" display: flex;justify-content: center;" *ngIf="ProductData.variants != []">
            <div class="text-1" *ngFor="let v of ProductData.variants; let kk = index">{{v.name}}  <i
              *ngIf="ProductData.variants.length != kk+1" style="font-size: 6px;margin-right: 5px;" class="dot fa fa-circle"></i></div>
          </div>
        </div>
      </div>
      <div class="product-img-slider">
        <div class="owl-carousel single-p-carosule">
          <div class="image-each">
            <img src="{{ProductData.image_url}}" alt="">
          </div>
          <div class="image-each" *ngFor="let eachPhoto of ProductData.photos">
            <img src="{{eachPhoto.photo_url}}" alt="">
          </div>

        </div>
        <div class="slider-side-img" id="carousel-custom-dots">
          <div class="each-dot">
            <img src="{{ProductData.image_url}}" alt="">
          </div>
          <div class="each-dot" *ngFor="let eachPhoto of ProductData.photos">
            <img src="{{eachPhoto.photo_url}}" alt="">
          </div>
        </div>
        <div class="love-people"><i class="fa fa-eye"></i> {{ProductData.views}} Customers views this item</div>
      </div>
      <div class="mobile-show-detail no-margin">
	<div class="sub-feature" *ngIf="ProductData.discount_price > 0 && ProductData.hide == 0">OFFERED PRODUCT</div>
        <div class="sub-feature" *ngIf="ProductData.hide == 1">OUT OF STOCK</div>
        <div *ngIf="ProductData.discount_price == 0" class="price">{{ProductData.formated_price}}</div>
        <div *ngIf="ProductData.discount_price > 0" class="product-price-wrapper">
          <div class="current-price">
            <div class="value">{{ProductData.formated_price}}</div>
          </div>
          <div class="real-price">
            <span class="value">{{ProductData.formated_list_price}}</span><br>
            <span class="sub-slogan">List Price</span>
          </div>
        </div>
        <div class="text-learn" *ngIf="ProductData.discount_percent > 0">{{ProductData.discount_percent}}% savings vs
          retail
        </div>
        <!--<div class="text-ship">Orders over $49.00 ship free</div>
        <div class="text-add">Add $22.30 for free shipping</div>-->
        <div class="add-cart-section">
          <select [(ngModel)]="cartParam.quantity" style="margin-right: 10px;">
            <option value="1">01</option>
            <option value="2">02</option>
            <option value="3">03</option>
            <option value="4">04</option>
            <option value="5">05</option>
            <option value="6">06</option>
            <option value="7">07</option>
            <option value="8">08</option>
          </select>
          <select [(ngModel)]="cartParam.option" *ngIf=" ProductData.variants.length >0">
            <option value="{{v.id}}" *ngFor="let v of ProductData.variants">{{v.name}}</option>
          </select>
          <a  *ngIf=" ProductData.variants.length == 0" href="javascript:void(0)" class="btn btn-login" [ngClass]="{'disabled': ProductData.hide == 1}" (click)="addToCart(ProductData, $event)">ADD TO CART</a>
        </div>
        <a *ngIf=" ProductData.variants.length > 0"   href="javascript:void(0)" class="btn btn-login" [ngClass]="{'disabled': ProductData.hide == 1}" (click)="addToCart(ProductData, $event)">ADD TO CART</a>
        <div class="love" (click)="addTowish(ProductData)">
          <a href="javascript:void(0)" *ngIf="ProductData.wishlist === 0"> <i class="fa fa-heart-o"></i> Add to
            Favourites</a>
          <a href="javascript:void(0)" *ngIf="ProductData.wishlist === 1"> <i class="fa fa-heart"></i> Remove from
            Favourites</a>
        </div>
      </div>
    </div>
    <div class="col-lg-5">
      <div class="text-box" [ngStyle]="{'filter': (ProductData.hide == 1) ? 'grayscale(100%)' : ''}">
        <div class="sale" *ngIf="ProductData.discount_price > 0">sale</div>
        <a class="text-s" href="javascript:void(0)" *ngIf="ProductData.brand != null">{{ProductData.brand.name}}</a>
        <h1>{{ProductData.name}}</h1>
        <div class="p-subtitle" *ngIf="ProductData.variants != []">
          <div class="text-1" *ngFor="let v of ProductData.variants; let kk = index">{{v.name}}  <i
            *ngIf="ProductData.variants.length != kk+1" class="dot fa fa-circle"></i></div>
        </div>
	<div class="sub-feature" *ngIf="ProductData.discount_price > 0 && ProductData.hide == 0">OFFERED PRODUCT</div>
        <div class="sub-feature" *ngIf="ProductData.hide == 1">OUT OF STOCK</div>
        <div *ngIf="ProductData.discount_price == 0" class="price">{{ProductData.formated_price}}</div>
        <div *ngIf="ProductData.discount_price > 0" class="product-price-wrapper">
          <div class="current-price">
            <div class="value">{{ProductData.formated_price}}</div>
          </div>
          <div class="real-price">
            <span class="value">{{ProductData.formated_list_price}}</span><br>
            <span class="sub-slogan">List Price</span>
          </div>
        </div>
        <div class="text-learn" *ngIf="ProductData.discount_percent > 0">{{ProductData.discount_percent}}% savings vs
          retail
        </div>
        <!-- <div class="text-ship">Orders over $49.00 ship free</div>
         <div class="text-add">Add $22.30 for free shipping</div>-->
        <div class="add-cart-section">
          <select [(ngModel)]="cartParam.quantity" style="margin-right: 10px;">
            <option value="1">01</option>
            <option value="2">02</option>
            <option value="3">03</option>
            <option value="4">04</option>
            <option value="5">05</option>
            <option value="6">06</option>
            <option value="7">07</option>
            <option value="8">08</option>
          </select>
          <select [(ngModel)]="cartParam.option" *ngIf=" ProductData.variants.length >0">
            <option value="{{v.id}}" *ngFor="let v of ProductData.variants">{{v.name}}</option>
          </select>
          <a  *ngIf=" ProductData.variants.length == 0" href="javascript:void(0)" class="btn btn-login" [ngClass]="{'disabled': ProductData.hide == 1}" (click)="addToCart(ProductData, $event)">ADD TO CART</a>
        </div>
        <a *ngIf=" ProductData.variants.length > 0" href="javascript:void(0)" class="btn btn-login" (click)="addToCart(ProductData, $event)">ADD TO CART</a>
        <div class="love" (click)="addTowish(ProductData)">
          <a href="javascript:void(0)" *ngIf="ProductData.wishlist === 0"> <i class="fa fa-heart-o"></i> Add to
            Favourites</a>
          <a href="javascript:void(0)" *ngIf="ProductData.wishlist === 1"> <i class="fa fa-heart"></i> Remove from
            Favourites</a>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-7">
      <div class="about-product">
        <div class="text-h">About the Product</div>
        <div class="text-ab-p" [innerHTML]="ProductData.details"></div>
        <br>

      </div>
    </div>
    <div class="col-lg-5">

    </div>
  </div>
  <div class="review-sec">
    <h2  (click)="showAboutText()" class="cursor-pointer title">User Review
      <span class="read-more">+</span>
      <span class="hide-show" style="display: none">-</span>
    </h2>
    <div class="text-ab-p hide-show" style="display: none">
      <div class="each-comment" *ngIf="commentData != null">
       <div *ngFor="let each of commentData" class="each">
         <div class="name">{{each.first_name}} {{each.last_name}}
           <span class="ml-3">
             <i class="fa fa-star gold mr-1" *ngFor="let l of [].constructor(rating(each.rating))"></i>
           </span>
         </div>
         <div class="date">{{each.date}}</div>
         <p class="comment">{{each.comment}}</p>
       </div>
      </div>
      <div class="row" *ngIf="is_logged === true">
        <div class="col-lg-8">
          <textarea rows="2" class="form-control"  [(ngModel)]="reviewData.comment" placeholder="Comment"></textarea>
        </div>
        <div class="col-lg-4">
          <select class="form-control" [(ngModel)]="reviewData.rating">
            <option value="">Select Rating</option>
            <option value="1">1 Star</option>
            <option value="2">2 Star</option>
            <option value="3">3 Star</option>
            <option value="4">4 Star</option>
            <option value="5">5 Star</option>
          </select>
        </div>
        <div class="margin-10"></div>
        <div class="col-sm-12 text-right">
          <button class="btn btn-outline-secondary add-cart-btn-modal" *ngIf="loading == false" (click)="addReview()">Add review </button>
          <button class="btn btn-outline-secondary add-cart-btn-modal"  *ngIf="loading == true" disabled>Add review <i class="fa fa-spinner fa-spin"></i></button>
        </div>
      </div>
    </div>
  </div>

</div>

<div class="container text-center" *ngIf="homeData == null">
  <i class="fa fa-spin fa-circle-o-notch fa-xl" style="font-size: 80px"></i>
</div>

<div class="container" *ngIf="homeData != null">

  <div class="slider-product">
    <div class="title-p-slider">Promotional Products</div>
    <div class="owl-carousel owl-theme slider-p">
      <div class="each-item item" *ngFor="let eachFeatProduct of homeData.promotional_products; let index = index">
        <div class="sale" *ngIf="eachFeatProduct.discount_price > 0">sale</div>
        <a [routerLink]="['/product', eachFeatProduct.slug]">
          <div class="cover-image">
            <img [ngClass]="{'img0' : eachFeatProduct.photos.length > 0}" src="{{eachFeatProduct.image_url}}" alt="">
            <img *ngIf="eachFeatProduct.photos.length > 0" class="img1" src="{{eachFeatProduct.photos[0].photo_url}}"
                 alt="">
            <div class="sub-feature" *ngIf="eachFeatProduct.discount_price > 0">OFFERED PRODUCT</div>
          </div>
          <div class="product-details-info">
            <div class="product-title"> {{eachFeatProduct.name}}</div>
            <div class="p-subtitle" *ngIf="eachFeatProduct.variants != []">
              <div class="text-1" *ngFor="let v of eachFeatProduct.variants; let kk = index">{{v.name}}  <i
                *ngIf="eachFeatProduct.variants.length != kk+1" class="dot fa fa-circle"></i></div>
            </div>
            <span *ngIf="eachFeatProduct.discount_price == 0" class="price">{{eachFeatProduct.formated_price}}</span>
            <div class="product-price-wrapper" *ngIf="eachFeatProduct.discount_price > 0">
              <div class="current-price">
                <div class="value">{{eachFeatProduct.formated_price}}</div>
              </div>
              <div class="real-price">
                <span class="value">{{eachFeatProduct.formated_list_price}}</span><br>
                <span class="sub-slogan">List Price</span>
              </div>
            </div>
            <div class="retail_save" *ngIf="eachFeatProduct.discount_percent > 0">
              save {{eachFeatProduct.discount_percent}}% vs retail
            </div>
          </div>
        </a>
        <div class="options-wrapper">
          <button type="button" class="btn btn-outline-secondary add-cart-btn"
                  (click)="addToCart(eachFeatProduct, $event)">ADD
          </button>
          <div class="love-wrapper">
                  <span (click)="addTowishPromo(eachFeatProduct, index)" class="cursor-pointer">
                     <i *ngIf="eachFeatProduct.wishlist === 0" aria-hidden="true" class="fa fa-heart-o"></i>
                      <i *ngIf="eachFeatProduct.wishlist === 1" aria-hidden="true" class="fa fa-heart"></i>
                  </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="slider-product">
    <div class="title-p-slider">Popular Product</div>
    <div class="owl-carousel owl-theme slider-p">
      <div class="each-item item" *ngFor="let eachFeatProduct of homeData.popular_products; let index = index">
        <div class="sale" *ngIf="eachFeatProduct.discount_price > 0">sale</div>
        <a [routerLink]="['/product', eachFeatProduct.slug]">
          <div class="cover-image">
            <img [ngClass]="{'img0' : eachFeatProduct.photos.length > 0}" src="{{eachFeatProduct.image_url}}" alt="">
            <img *ngIf="eachFeatProduct.photos.length > 0" class="img1" src="{{eachFeatProduct.photos[0].photo_url}}"
                 alt="">
            <div class="sub-feature" *ngIf="eachFeatProduct.discount_price > 0">OFFERED PRODUCT</div>
          </div>
          <div class="product-details-info">
            <div class="product-title"> {{eachFeatProduct.name}}</div>
            <div class="p-subtitle" *ngIf="eachFeatProduct.variants != []">
              <div class="text-1" *ngFor="let v of eachFeatProduct.variants; let kk = index">{{v.name}}  <i
                *ngIf="eachFeatProduct.variants.length != kk+1" class="dot fa fa-circle"></i></div>
            </div>
            <span *ngIf="eachFeatProduct.discount_price == 0" class="price">{{eachFeatProduct.formated_price}}</span>
            <div class="product-price-wrapper" *ngIf="eachFeatProduct.discount_price > 0">
              <div class="current-price">
                <div class="value">{{eachFeatProduct.formated_price}}</div>
              </div>
              <div class="real-price">
                <span class="value">{{eachFeatProduct.formated_list_price}}</span><br>
                <span class="sub-slogan">List Price</span>
              </div>
            </div>
            <div class="retail_save" *ngIf="eachFeatProduct.discount_percent > 0">
              save {{eachFeatProduct.discount_percent}}% vs retail
            </div>
          </div>
        </a>
        <div class="options-wrapper">
          <button type="button" class="btn btn-outline-secondary add-cart-btn"
                  (click)="addToCart(eachFeatProduct, $event)">ADD
          </button>
          <div class="love-wrapper">
                  <span (click)="addTowishPopular(eachFeatProduct, index)" class="cursor-pointer">
                     <i *ngIf="eachFeatProduct.wishlist === 0" aria-hidden="true" class="fa fa-heart-o"></i>
                      <i *ngIf="eachFeatProduct.wishlist === 1" aria-hidden="true" class="fa fa-heart"></i>
                  </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="slider-product">
    <div class="title-p-slider">Featured Products</div>
    <div class="owl-carousel owl-theme slider-p">
      <div class="each-item item" *ngFor="let eachFeatProduct of homeData.featured_products; let index = index">
        <div class="sale" *ngIf="eachFeatProduct.discount_price > 0">sale</div>
        <a [routerLink]="['/product', eachFeatProduct.slug]">
          <div class="cover-image">
            <img [ngClass]="{'img0' : eachFeatProduct.photos.length > 0}" src="{{eachFeatProduct.image_url}}" alt="">
            <img *ngIf="eachFeatProduct.photos.length > 0" class="img1" src="{{eachFeatProduct.photos[0].photo_url}}"
                 alt="">
            <div class="sub-feature" *ngIf="eachFeatProduct.discount_price > 0">OFFERED PRODUCT</div>
          </div>
          <div class="product-details-info">
            <div class="product-title"> {{eachFeatProduct.name}}</div>
            <div class="p-subtitle" *ngIf="eachFeatProduct.variants != []">
              <div class="text-1" *ngFor="let v of eachFeatProduct.variants; let kk = index">{{v.name}}  <i
                *ngIf="eachFeatProduct.variants.length != kk+1" class="dot fa fa-circle"></i></div>
            </div>
            <span *ngIf="eachFeatProduct.discount_price == 0" class="price">{{eachFeatProduct.formated_price}}</span>
            <div class="product-price-wrapper" *ngIf="eachFeatProduct.discount_price > 0">
              <div class="current-price">
                <div class="value">{{eachFeatProduct.formated_price}}</div>
              </div>
              <div class="real-price">
                <span class="value">{{eachFeatProduct.formated_list_price}}</span><br>
                <span class="sub-slogan">List Price</span>
              </div>
            </div>
            <div class="retail_save" *ngIf="eachFeatProduct.discount_percent > 0">
              save {{eachFeatProduct.discount_percent}}% vs retail
            </div>
          </div>
        </a>
        <div class="options-wrapper">
          <button type="button" class="btn btn-outline-secondary add-cart-btn"
                  (click)="addToCart(eachFeatProduct, $event)">ADD
          </button>
          <div class="love-wrapper">
                  <span (click)="addTowishFeat(eachFeatProduct, index)" class="cursor-pointer">
                     <i *ngIf="eachFeatProduct.wishlist === 0" aria-hidden="true" class="fa fa-heart-o"></i>
                      <i *ngIf="eachFeatProduct.wishlist === 1" aria-hidden="true" class="fa fa-heart"></i>
                  </span>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
