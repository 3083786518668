import { Component, OnInit } from '@angular/core';
import {environment} from '../../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from "../../services/authentication.service";
declare const $: any;
declare const toastr: any

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html'
})
export class ThankyouComponent implements OnInit {

  ENV = environment;
  menuOpen = false;
  loading = false;
  reference: any = '';
  cancelReason = '';
  constructor(protected authenticationService : AuthenticationService,  private router: Router, private route: ActivatedRoute) {
    this.reference = this.route.snapshot.paramMap.get('ref');
    window.scrollTo(0, 0);
    this.closeSideMenu();
  }
  ngOnInit(): void {}
  cancelOrderModal() {
    $('#delete_add').modal('show');
  }

  cancelOrder() {
    this.loading= true;
    let cancelData = {
      reference: this.reference,
      cancel_reason: this.cancelReason
    }
    this.authenticationService.cancelOrder(cancelData).subscribe(res => {
       this.loading= false;
      if (res.status === true) {
        $('#delete_add').modal('hide');
        toastr.success(res.message);
        toastr.options.closeButton = true;
        this.router.navigate(['/user/order']);
      } else {
        toastr.error(res.message);
        toastr.options.closeButton = true;
      }

    })
  }
  closeSideMenu() {
    $('.nav-bar-mobile').removeClass('visible');
    $('.hamburger .fa-times').hide();
    $('.hamburger .fa-bars').show();
    $('.display-overflow').hide();
    this.menuOpen = false;
  }
}
