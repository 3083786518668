<div class="-user-settings-wrapper">
  <div class="breadcum-wrapper">
    <div class="breadcrumbs">
      <span>
        <a [routerLink]="['']">Home</a>
        <span class="icon"> > </span>
      </span>
      <span>
        <a [routerLink]="['/user/account-settings']">Settings</a>
        <span class="icon"> > </span>
      </span>
      <span>
        <a [routerLink]="['/user/change-password']">Change Password</a>
        <span class="icon">  </span>
      </span>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="margin-30"></div>
      <div class="offset-md-3 col-md-6">
        <div class="form-title">Change Password</div>
        <form ngNativeValidate (ngSubmit)="updatePassword()">
          <div class="form-group">
            <label>CURRENT PASSWORD</label>
            <input [type]="inputType" name="old_password" class="form-control" [(ngModel)]="param.old_password">
            <a href="javascript:void(0)" class="view-toggle" (click)="showPass()" tabindex="0"><span *ngIf="inputType == 'password'">Show</span> <span *ngIf="inputType == 'text'">Hide</span></a>
            <div class="invalid-feedback"></div>
          </div>
          <div class="form-group">
            <label>NEW PASSWORD</label>
            <input [type]="inputType" name="new_password" class="form-control" [(ngModel)]="param.new_password">
            <small class="form-text text-muted">Must contain 8 characters with at least 1 uppercase and 1 lowercase letter and either 1 number or 1 special character.</small>
            <a href="javascript:void(0)" class="view-toggle" (click)="showPass()" tabindex="0"><span *ngIf="inputType == 'password'">Show</span> <span *ngIf="inputType == 'text'">Hide</span></a>
          </div>
          <div class="form-group">
            <label>RE-ENTER NEW PASSWORD</label>
            <input [type]="inputType" class="form-control" name="new_password_confirm" [(ngModel)]="param.new_password_confirm">
            <a href="javascript:void(0)" class="view-toggle" (click)="showPass()" tabindex="0"><span *ngIf="inputType == 'password'">Show</span> <span *ngIf="inputType == 'text'">Hide</span></a>
          </div>
          <div class="form-group">
            <button *ngIf="loading === false" type="submit" class="btn btn-theme w-100">UPDATE</button>
            <button *ngIf="loading === true" disabled type="button" class="btn btn-theme w-100">UPDATE <i class="fa fa-spinner fa-spin"></i></button>
          </div>
        </form>
      </div>
      <div class="margin-30"></div>
    </div>
  </div>
</div>
