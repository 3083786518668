import { Component, OnInit } from '@angular/core';
import {environment} from '../../../environments/environment';
import {AuthenticationService} from "../../services/authentication.service";
import {Router} from "@angular/router";
import {CategoryService} from "../../services/category.service";
declare const $: any;
declare const toastr: any;
declare const Ladda: any;

@Component({
  selector: 'app-add-order',
  templateUrl: './order.component.html'
})
export class OrderComponent implements OnInit {

  ENV = environment;
  menuOpen = false;
  orderData: any = [];
  is_logged = false;
  param: any = {
    page: 0,
  }
  lastPage = '';
  cartParam: any = {
    quantity: 1,
    option: 2,
    cart_id: '',
  };
  Loading = false;
  eachOrder: any = null;

  constructor(protected authenticationService : AuthenticationService,   protected categoryService : CategoryService,  private router: Router) {
    const Auth = this.authenticationService.getAuthFromSession();
    if (Auth !== null) {
      this.getOrder();
      this.is_logged = Auth.is_logged;
      this.cartParam.cart_id = Auth.cart_id;
    } else {
      this.router.navigate(['']);
      setTimeout(function () {
        $('#register').modal('show');
      },100)
    }
    window.scrollTo(0, 0);
    this.closeSideMenu();
  }
  ngOnInit(): void {}

  getOrder() {
    this.Loading = true;
    this.param.page += 1;
    this.authenticationService.getOrder(this.param.page).subscribe(res => {
      this.Loading = false;
      if (res.is_logged === true) {
        this.lastPage = res.page_info.total;
        res.data.forEach((v) => {
          this.orderData.push(v);
        });
      }
    })
  }

  openCartModal (order) {
    this.eachOrder = order;
    $('#cart_add').modal('show');
  }
  addToCart(product, e) {
    let ldd = e.target;
    let ladda = Ladda.create(ldd);
    if (this.is_logged === true) {
      ladda.start();
      this.categoryService.apiAddCart(this.cartParam, product.product_id).subscribe( res => {
        if (res.status === true && res.is_logged === true) {
          this.getCartDetail();
          ladda.stop();
        } else if (res.status === false) {
          toastr.error(res.message);
          toastr.options.closeButton = true;
          ladda.stop();
        }
      })
    } else {
      let cart_id = localStorage.getItem('guestCartID');
      cart_id = JSON.parse(cart_id);
      if (cart_id != null) {
        this.cartParam.cart_id = cart_id;
        ladda.start();
        this.categoryService.apiAddCart(this.cartParam, product.product_id).subscribe( res => {
          ladda.stop();
          if (res.status === true ) {
            this.getCartDetail();
          } else {
            toastr.error(res.message);
            toastr.options.closeButton = true;
          }
        })
      } else {
        ladda.start();
        this.categoryService.apiAddCart(this.cartParam, product.product_id).subscribe( res => {
          ladda.stop();
          if (res.status === true  ) {
            this.cartParam.cart_id = res.cart_id;
            let cartID_set = JSON.stringify(res.cart_id);
            localStorage.setItem("guestCartID", cartID_set);
            this.getCartDetail();
          } else {
            toastr.error(res.message);
            toastr.options.closeButton = true;
          }
        });
      }
    }
  }
  getCartDetail() {
    this.categoryService.apiGetCart(this.cartParam.cart_id).subscribe( res => {
      if (res.status === true) {
        this.authenticationService.updateCart(res.data);
      }
    })
  }

  closeSideMenu() {
    $('.nav-bar-mobile').removeClass('visible');
    $('.hamburger .fa-times').hide();
    $('.hamburger .fa-bars').show();
    $('.display-overflow').hide();
    this.menuOpen = false;
  }
}
