<header class="header-wrapper">
  <div class="display-overflow"></div>
  <div class="container-fluid">
    <div class="top-nav">
      <div class="notification-bar">
        <div class="section-left">
          <span>{{topHeaderTitle}}</span>
        </div>
        <div class="section-right">
          <a href="javascript:void(0)"><i class="fa fa-phone"></i>&nbsp;{{pageSettings.phone}}</a>
          <a href="javascript:void(0)"><i class="fa fa-envelope-o" aria-hidden="true"></i>&nbsp;{{pageSettings.email}}</a>
        </div>
      </div>
      <div class="logo-bar">
        <a class="hamburger" href="javascript:void(0)">
          <i class="fa fa-bars"   (click)="openSideMenu()"></i>
          <i class="fa fa-times"  style="display: none" (click)="closeSideMenu()"> </i>
        </a>
        <div class="section-left">
          <a [routerLink]="['']" class="logo">
		  <img src="{{ logo_slug }}" alt="">
            <div class="brand-title">A1daam</div>
          </a>
          <div class="search-box">
            <input type="text" class="form-control custom-control"  placeholder="Search for your favorites on A1daam" (keyup)="searchProduct()" [(ngModel)]="searchParam.search">
            <span *ngIf="loading == true" class="fa fa-circle-o-notch kola fa-spin"></span>
            <i class="material-icons search">search</i>
            <i class="material-icons close">close</i>

            <div class="searchResult"  (clickOutside)="onClickedOutsideSearch()">
               <!--<div *ngIf="SearchKey == ''">
                 <div class="search-default">
                   <div class="title">Recent Searches</div>
                   <ul class="search-default-list">
                     <li>Wipes</li>
                     <li>Soap</li>
                     <li>makeup</li>
                   </ul>
                 </div>
                 <div class="search-default">
                   <div class="title">Trending Searches</div>
                   <ul class="search-default-list">
                     <li>Wipes</li>
                     <li>Soap</li>
                     <li>makeup</li>
                     <li>Soap</li>
                     <li>makeup</li>
                   </ul>
                 </div>
               </div>-->
              <div class="search-default" *ngIf="searchData == null && loading == false">
                <div class="title">No Result Found</div>
              </div>
              <div *ngIf="searchData != null">
		      <a   href="/product/{{product.id}}" class="each-search-product" *ngFor="let product of searchData">
                  <div class="img">
                    <img src="{{product.image_url}}" alt="">
                  </div>
                  <div class="info">
                    <div class="title">{{product.name}}</div>
                    <div class="sub-title">Price <a href="javascript:void(0)">{{product.formated_price}}</a></div>
                  </div>
                </a>
              </div>
              <a  [routerLink]="['/search', searchParam.search]"  class="view-all-btn" *ngIf="searchData != null && searchData.length > 4">
                VIEW ALL RESULTS
              </a>
            </div>
          </div>
        </div>
        <div class="section-right">
          <div *ngIf="is_logged == false">
            <a class="header-each-nav serach-icon" href="javascript:void(0)"data-target="#search" data-toggle="modal">
              <i class="fa fa-search" aria-hidden="true"></i>
            </a>
            <a class="header-each-nav hide-599" href="javascript:void(0)">
              <span  (click)="showRegModal()" class="">Login</span>
            </a>
            <a class="header-each-nav" href="javascript:void(0)" (click)="showCart()">
              <i class="material-icons-outlined md" aria-hidden="true">shopping_cart</i>
              <span *ngIf="cartInfo != null">{{cartInfo.total_items}}</span>
            </a>
          </div>
          <div *ngIf="is_logged === true">
            <div class="dropdown-wrapper noselect-text" data-query="dropdown-wrapper">
              <a class="action-trigger" data-query="dropdown-trigger">Account <i class="fa fa-angle-down" aria-hidden="true"></i></a>
              <div class="dropdown-box" data-query="dropdown-box">
                <div class="user-info">
                  <div class="title">Welcome to A1daam</div>
                  <div class="slogan">{{UserDetails.name}}</div>
                </div>
                <div class="divider"></div>
                <div class="nav-wrapper">
                  <ul>
                    <li><a [routerLink]="['/user/account-settings']">Settings</a></li>
                    <li><a [routerLink]="['/user/order']">Order History</a></li>
                    <li><a [routerLink]="['/user/favorite-products']">Favorites</a></li>
                  </ul>
                </div>
                <div class="divider"></div>
                <div class="bottom-section">
                  <ul>
                    <li><a href="javascript:void(0)" (click)="logOut()">Sign Out</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="dropdown-wrapper noselect-text" data-query="dropdown-wrapper-noti">
              <a class="action-trigger" data-query="dropdown-trigger-noti"><span class="material-icons md">notifications_none</span> </a>
              <div class="dropdown-box" data-query="dropdown-box-noti">
                <div class="user-info">
                  <div class="title t-btn">No Notification</div>
                </div>
              </div>
            </div>
            <a class="header-each-nav" href="javascript:void(0)" (click)="showCart()">
              <i class="material-icons-outlined md" aria-hidden="true">shopping_cart</i>
              <span *ngIf="cartInfo != null">{{cartInfo.total_items}}</span>
            </a>
          </div>
        </div>
      </div>
      <div class="logo-bar-mobile">
        <div class="search-box" data-target="#search" data-toggle="modal">
          <input type="text" class="form-control custom-control" placeholder="Search for your favorites on A1daam">
          <i class="fa fa-search"></i>
        </div>
      </div>
      <span class="show-991 notification-text-mobiel">{{pageSettings.description}}</span>
      <div class="nav-bar">
        <ul class="nav-ul">
          <li class="nav-li">
            <a [routerLink]="['/category/all']"  class="item">
              <span> Categories <i class="fa fa-angle-down"></i> </span>
            </a>
            <div class="drop-down-box">
              <ul class="drop-down-box-ul" *ngIf="categoriesData != []">
                <li *ngFor="let eachCat of categoriesData; let i = index">
                  <a [routerLink]="['/category', eachCat.code]">{{eachCat.name}} <i class="fa fa-angle-right sub-child-indicator"></i></a>
                  <div class="drop-down-box-ul-box">
                    <ul class="drop-down-box-ul-child" *ngIf="eachCat.sub_categories != []">
                      <li *ngFor="let eachSubCat of eachCat.sub_categories"><a [routerLink]="['/category', eachSubCat.code]">{{eachSubCat.name}}</a></li>
                      <!--<li class="divider"></li>-->
                    </ul>
                    <div class="menuBanner">
                      <div class="banner-title">{{eachCat.description}}</div>
                      <div class="banner-button"><a [routerLink]="['/category', eachCat.code]">Shop Now</a></div>
                      <div class="banner"><img src="{{eachCat.image_url}}" alt="Grap & Go' Goodies"></div>
                    </div>
                  </div>
                </li>
                <li class="divider"></li>
                <li>
                  <a [routerLink]="['/category/all']">Shop All Products</a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <ul class="nav-ul">
          <li class="nav-li">
            <a [routerLink]="['/product/popular-products']" class="item">
              <span>Popular Products</span>
            </a>
          </li>
          <li class="nav-li">
            <a [routerLink]="['/product/all-new']" class="item">
              <span>All New  </span>
            </a>
          </li>
          <li class="nav-li">
            <a [routerLink]="['/product/deals']" class="item">
              <span>Deals</span>
            </a>
          </li>
          <li class="nav-li">
            <a [routerLink]="['/subscriptions']" class="item">
              <span> Subscriptions </span>
            </a>
          </li>

          <li *ngIf="is_logged == true" class="nav-li">
            <a [routerLink]="['/user/favorite-products']" class="item">
              <span>Favorites</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="nav-bar-mobile" >
        <div class="menu-section">
          <div class="head-sec">
            <div *ngIf="is_logged == false">
              <a (click)="showLoginModal()" href="javascript:void(0)">Login</a>
              <span class="divider">|</span>
              <a title="Create Account" (click)="showRegModal()" href="javascript:void(0)">Create Account</a>
            </div>
          </div>
          <ul class="has-child-ul">
            <li class="lvl-1-li">
              <a class="item" href="javascript:void(0)" (click)="openChildMenu($event)">Shop Categories
                <i class="fa fa-angle-down lvl-1-ico"></i>
                <i class="fa fa-angle-up lvl-1-ico" style="display: none"></i>
              </a>
              <ul class="lvl-1-child" *ngIf="categoriesData != []">
                <li class="lvl-2-li">
                  <a class="item" [routerLink]="['/category/all']" (click)="openChildMenulvl2($event)">All Categories</a>
                </li>
                <li class="lvl-2-li"  *ngFor="let eachCat of categoriesData; let i = index">
                  <a class="item"   (click)="openChildMenulvl2($event)">{{eachCat.name}}
                    <i class="fa fa-angle-down"></i>
                  </a>
                  <ul class="lvl-2-child">
                    <li class="lvl-3-li">
                      <a class="item" [routerLink]="['/category', eachCat.code]">All {{eachCat.name}}
                      </a>
                    </li>
                    <li class="lvl-3-li" *ngFor="let eachSub of eachCat.sub_categories; let i = index">
                      <a class="item"  [routerLink]="['/category', eachSub.code]">{{eachSub.name}}
                      </a>
                    </li>
                  </ul>
                </li>
                <!--<li class="lvl-2-li">
                  <a class="item" href="javascript:void(0)" (click)="openChildMenulvl2($event)">Beverages
                    <i class="fa fa-angle-down lvl-2-ico"></i>
                    <i class="fa fa-angle-up lvl-2-ico" style="display: none"></i>
                  </a>
                  <ul class="lvl-2-child">
                    <li class="lvl-3-li">
                      <a class="item" [routerLink]="['/category/express/13']">Salty Snakes
                      </a>
                    </li>
                    <li class="lvl-3-li">
                      <a class="item"  [routerLink]="['/category/express/13']">Bars
                      </a>
                    </li>
                    <li class="lvl-3-li">
                      <a class="item"  [routerLink]="['/category/express/13']">Cokies
                      </a>
                    </li>
                    <li class="lvl-3-li">
                      <a class="item"  [routerLink]="['/category/express/13']">Nuts & Seeds
                      </a>
                    </li>
                    <li class="lvl-3-li">
                      <a class="item"  [routerLink]="['/category/express/13']">Chocolate & Candy
                      </a>
                    </li>
                    <li class="lvl-3-li">
                      <a class="item"  [routerLink]="['/category/express/13']">Fruits & Snakes
                      </a>
                    </li>
                    <li class="lvl-3-li">
                      <a class="item"  [routerLink]="['/category/express/13']">Wine
                      </a>
                    </li>
                    <li class="lvl-3-li">
                      <a class="item border-top" href="javascript:void(0)">Shop All Snkes</a>
                    </li>
                    <li class="lvl-3-li">
                      <a class="item " href="javascript:void(0)">Shop Featured Brands</a>
                    </li>
                  </ul>
                </li>
                <li class="lvl-2-li">
                  <a class="item" href="javascript:void(0)" (click)="openChildMenulvl2($event)">Grocery
                    <i class="fa fa-angle-down"></i>
                    <i class="fa fa-angle-up" style="display: none"></i>
                  </a>
                  <ul class="lvl-2-child">
                    <li class="lvl-3-li">
                      <a class="item"  [routerLink]="['/category/express/13']">Salty Snakes
                      </a>
                    </li>
                    <li class="lvl-3-li">
                      <a class="item" [routerLink]="['/category/express/13']">Bars
                      </a>
                    </li>
                    <li class="lvl-3-li">
                      <a class="item" [routerLink]="['/category/express/13']">Cokies
                      </a>
                    </li>
                    <li class="lvl-3-li">
                      <a class="item" [routerLink]="['/category/express/13']">Nuts & Seeds
                      </a>
                    </li>
                    <li class="lvl-3-li">
                      <a class="item" [routerLink]="['/category/express/13']">Chocolate & Candy
                      </a>
                    </li>
                    <li class="lvl-3-li">
                      <a class="item"  [routerLink]="['/category/express/13']">Fruits & Snakes
                      </a>
                    </li>
                    <li class="lvl-3-li">
                      <a class="item"  [routerLink]="['/category/express/13']">Wine
                      </a>
                    </li>
                    <li class="lvl-3-li">
                      <a class="item border-top" href="javascript:void(0)">Shop All Snkes</a>
                    </li>
                    <li class="lvl-3-li">
                      <a class="item " href="javascript:void(0)">Shop Featured Brands</a>
                    </li>
                  </ul>
                </li>-->
              </ul>
            </li>
           <!-- <li class="lvl-1-li">
              <a class="item" href="javascript:void(0)" (click)="openChildMenu($event)">
                <span>
                  <i style="color: #ffc61d; float: none" class="fa fa-bolt" aria-hidden="true"></i>&nbsp; Shop Express Grocery &nbsp;
                  <i class="fa fa-angle-down lvl-1-ico"></i>
                  <i class="fa fa-angle-up lvl-1-ico" style="display: none"></i>
                </span>
                <p class="sub-text">Hand-picked produce, frozen &amp; more delivered by a shopper. Schedule your
                  delivery at checkout.</p>
              </a>
              <ul class="lvl-1-child">
                <li class="lvl-2-li">
                  <a class="item"  [routerLink]="['/category/express/13']">Snakes
                    <i class="fa fa-angle-down"></i>
                    <i class="fa fa-angle-up" style="display: none"></i>
                  </a>
                </li>
                <li class="lvl-2-li">
                  <a class="item"  [routerLink]="['/category/express/13']">Beverages
                    <i class="fa fa-angle-down"></i>
                    <i class="fa fa-angle-up" style="display: none"></i>
                  </a>
                </li>
                <li class="lvl-2-li">
                  <a class="item"  [routerLink]="['/category/express/13']">Grocery
                    <i class="fa fa-angle-down"></i>
                    <i class="fa fa-angle-up" style="display: none"></i>
                  </a>
                </li>
                <li class="lvl-2-li">
                  <a class="item"  [routerLink]="['/category/express/13']">Household Essential
                    <i class="fa fa-angle-down"></i>
                    <i class="fa fa-angle-up" style="display: none"></i>
                  </a>
                </li>
                <li class="lvl-2-li">
                  <a class="item"  [routerLink]="['/category/express/13']">Personal Care
                    <i class="fa fa-angle-down"></i>
                    <i class="fa fa-angle-up" style="display: none"></i>
                  </a>
                </li>
                <li class="lvl-2-li">
                  <a class="item"  [routerLink]="['/category/express/13']">Health & Wellness
                    <i class="fa fa-angle-down"></i>
                    <i class="fa fa-angle-up" style="display: none"></i>
                  </a>
                </li>
                <li class="lvl-2-li">
                  <a class="item"  [routerLink]="['/category/express/13']">Baby
                    <i class="fa fa-angle-down"></i>
                    <i class="fa fa-angle-up" style="display: none"></i>
                  </a>
                </li>
                <li class="lvl-2-li">
                  <a class="item"  [routerLink]="['/category/express/13']">Home
                    <i class="fa fa-angle-down"></i>
                    <i class="fa fa-angle-up" style="display: none"></i>
                  </a>
                </li>
                <li class="lvl-2-li">
                  <a class="item" [routerLink]="['/category/express/13']">School and Office
                    <i class="fa fa-angle-down"></i>
                    <i class="fa fa-angle-up" style="display: none"></i>
                  </a>
                </li>
                <li class="lvl-2-li">
                  <a class="item"  [routerLink]="['/category/express/13']">Pets
                    <i class="fa fa-angle-down"></i>
                    <i class="fa fa-angle-up" style="display: none"></i>
                  </a>
                </li>
                <li class="lvl-2-li">
                  <a class="item"  [routerLink]="['/category/express/13']">Wine
                    <i class="fa fa-angle-down"></i>
                    <i class="fa fa-angle-up" style="display: none"></i>
                  </a>
                </li>
                <li class="lvl-2-li">
                  <a class="item border-top" href="javascript:void(0)">Shop All Products</a>
                </li>
                <li class="lvl-2-li">
                  <a class="item " href="javascript:void(0)">Shop Featured Brands</a>
                </li>
              </ul>
            </li>-->
          </ul>
          <ul class="no-child-ul">
            <li>
              <a [routerLink]="['/product/popular-products']">Popular Products</a>
            </li>
            <li>
              <a [routerLink]="['/product/all-new']" >All New</a>
            </li>
            <li>
              <a  [routerLink]="['/product/deals']">Deals</a>
            </li>
            <li class="nav-li">
              <a [routerLink]="['/subscriptions']">
                <span> Subscriptions </span>
              </a>
            </li>
            <li  *ngIf="is_logged == true">
              <a [routerLink]="['/user/favorite-products']">Favorites</a>
            </li>
          </ul>
         <!-- <ul class="no-child-ul">
            <li>
              <a href="javascript:void(0)">Contact Us</a>
            </li>
          </ul>-->
        </div>
      </div>
    </div>
  </div>
  <div class="cart-wrapper">
    <div class="head">
      <a href="javascript:void(0)">
        Cart
      </a>
      <a href="javascript:void(0)" class="cross" (click)="closeCart()"> <i class="fa fa-times fa-lg"></i></a>
      <a href="javascript:void(0)" class="cross-text-btn" (click)="closeCart()">Close</a>
    </div>

    <div class="body-cart">

      <ul class="item-box" *ngIf="cartInfo != null">
        <li class="each-item" *ngFor="let each of cartInfo.contents | keyvalue">
          <a href="javascript:void(0)" class="item-info">
            <span class="img-sec">
              <span class="img-p">
              <img src="{{each.value.image}}" alt="">
            </span>
            </span>
            <span class="info-sec">
              <span>{{each.value.name}}</span>
              <div style="color: cadetblue;">{{optionName(each.value)}}</div>
              <span class="bottom-info">
                <span>{{each.value.subtotal}}</span>
              </span>
            </span>
          </a>
          <div class="add-section">
            <div class="count">
              <span class="minus" (click)="minusItem(each.value,  $event)">-</span>
              <span class="quantity"><span class="gg">{{each.value.qty}}</span> <i class="fa fa-circle-o-notch  qq fa-spin" style="display: none" aria-hidden="true"></i></span>
              <span class="plus" (click)="plusItem(each.value,  $event)">+</span>
            </div>
            <a href="javascript:void(0)" (click)="removeItem(each.value.rowid, $event)">Remove <span> <i class="fa fa-circle-o-notch fa-spin" style="display: none" aria-hidden="true"></i></span></a>
          </div>
        </li>
      </ul>

      <div *ngIf="cartInfo != null">
        <div class="alert-bg" *ngIf="cartInfo.total_items == 0">
          <div  >Your shopping cart is empty</div>
        </div>
      </div>
      <div class="best-shop-text" *ngIf="cartInfo != null">
        <a *ngIf="cartInfo.total_items == 0" [routerLink]="['/product/popular-products']">Shop Popular Products</a>
      </div>
    </div>

    <div class="bottom-text" *ngIf="cartInfo == null">
      <span> ADD ITEM TO CHECK OUT</span>
    </div>

    <div class="bottom-text" [ngClass]="{'checkout' : cartInfo.total_items > 0}" *ngIf="cartInfo != null">
      <span *ngIf="cartInfo.total_items == 0"> ADD ITEM TO CHECK OUT</span>
      <span (click)="gotoCheckout()" *ngIf="cartInfo.total_items > 0"> CHECK OUT - {{cartInfo.total}}</span>
    </div>
  </div>
</header>

<div class="modal fade custom-modal " id="login">
  <div class="modal-dialog modal-lg  modal-dialog-centered">
    <div class="modal-content modal-content-custom">
      <div class="modal-header pb-0 border-0">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="resetRegisterModal()">
          <i class="material-icons">close</i>
        </button>
      </div>
      <div class="modal-body w-small">
        <h2 class="text-1">Welcome to A1daams</h2>
        <form ngNativeValidate (ngSubmit)="register()">
          <div class="form-group" *ngIf="(regInfo.type == 'mobile' && registration_step == 'generate_otp') || (regInfo.type == 'email' && registration_step == 'additional_info')" style="margin-bottom: 2px;">
            <input type="text"  name="phone" placeholder="Mobile Number"  class="custom-control form-control small-height"  [(ngModel)]="regInfo.phone" required="">
            <div class="invalid-feedback"></div>
          </div>

	  <div class="form-group" *ngIf="(regInfo.type == 'mobile' && registration_step != 'generate_otp')" style="margin-bottom: 2px;">
		  <p><span style="color: #385A82;">{{regInfo.phone}}</span>&nbsp;&nbsp;&nbsp;&nbsp;<span *ngIf="registration_step == 'validate_otp'" style="text-decoration: underline; cursor: pointer; color: #385A82;" (click)="resetRegisterModal()">Change</span></p>
	  </div>

          <div class="form-group" *ngIf="(regInfo.type == 'email' && registration_step == 'generate_otp') || (regInfo.type == 'mobile' && registration_step == 'additional_info')" style="margin-bottom: 2px;">
            <input type="email" placeholder="Email Address"  name="email"  class="custom-control form-control small-height"  [(ngModel)]="regInfo.email" required="">
            <div class="invalid-feedback"></div>
          </div>

	 <div class="form-group" *ngIf="registration_step == 'generate_otp'" style="margin-bottom: 2px;">
		  <p><span style="text-decoration: underline; cursor: pointer; color: #385A82;" (click)="toggleOtpType()">Use {{regInfo.other_type}} instead </span></p>
	  </div>


	  <div class="form-group" *ngIf="(regInfo.type == 'email' && registration_step != 'generate_otp')" style="margin-bottom: 2px;">
		  <p><span style="color: #385A82;">{{regInfo.email}}</span>&nbsp;&nbsp;&nbsp;&nbsp;<span style="text-decoration: underline; cursor: pointer; color: #385A82;" (click)="resetRegisterModal()">Change</span></p>
	  </div>


          <div class="form-group" *ngIf="registration_step == 'validate_otp'" style="margin-bottom: 2px;">
            <input type="number" placeholder="OTP" name="password" class="custom-control form-control small-height"  [(ngModel)]="regInfo.password" required="">
            <div class="invalid-feedback"></div>
          </div>

	  <div class="form-group" *ngIf="(registration_step == 'validate_otp')" style="margin-bottom: 2px;">
		  <p><span *ngIf="resend_otp_after < 1" style="text-decoration: underline; cursor: pointer; color: #385A82;" (click)="generateOtp()">Resend OTP </span> <span *ngIf="resend_otp_after > 0" style="text-decoration: underline; color: #385A82;" >Resend OTP after {{resend_otp_after}} </span> </p>
	  </div>

          <div class="form-group" *ngIf="registration_step == 'additional_info'" style="margin-bottom: 2px;">
            <input placeholder="First Name" type="text" name="first_name"  class="custom-control form-control small-height" [(ngModel)]="regInfo.first_name" required="">
            <div class="invalid-feedback"></div>
          </div>
          <div class="form-group" *ngIf="registration_step == 'additional_info'" style="margin-bottom: 2px;">
            <input type="text" placeholder="Last Name"  name="last_name"  class="custom-control form-control small-height"  [(ngModel)]="regInfo.last_name" required="">
            <div class="invalid-feedback"></div>
          </div>

          <div class="form-group" *ngIf="registration_step == 'additional_info'" style="margin-bottom: 8px;">
            <button type="submit" *ngIf="loadingR == false" class="btn-login">Register</button>
            <button type="button" *ngIf="loadingR == true" disabled class="btn-login">Register <i class="fa fa-spinner fa-spin"></i></button>
          </div>

	  <div class="form-group" *ngIf="registration_step == 'generate_otp'" style="margin-bottom: 8px;">
            <button type="button" *ngIf="loadingR == false" (click)="generateOtp()" class="btn-login" >Get Otp</button>
            <button type="button" *ngIf="loadingR == true" disabled class="btn-login">Get Otp <i class="fa fa-spinner fa-spin"></i></button>
          </div>

	  <div class="form-group" *ngIf="registration_step == 'validate_otp'" style="margin-bottom: 8px;">
            <button type="button" *ngIf="loadingR == false" (click)="validateOtp()" class="btn-login" >Verify OTP</button>
            <button type="button" *ngIf="loadingR == true" disabled class="btn-login">Verify Otp <i class="fa fa-spinner fa-spin"></i></button>
          </div>
        </form>

       <div class="form-text" *ngIf="registration_step == 'additional_info'">
          <p>Access and use of a1daam sites subject to our
            <a target="_blank" [routerLink]="['', 'term-condition']">Terms & Conditions</a> and
            <a target="_blank" [routerLink]="['', 'privacy-policy']">Privacy Policy</a>
          </p>
        </div>
      </div>

    </div>
  </div>

</div>

<div class="modal fade custom-modal" id="register">
  <div class="modal-dialog modal-lg  modal-dialog-centered">
    <div class="modal-content modal-content-custom">
      <div class="modal-header pb-0 border-0">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="resetRegisterModal()">
          <i class="material-icons">close</i>
        </button>
      </div>
      <div class="modal-body w-small">
        <h2 class="text-1">Welcome to A1daam</h2>
        <form ngNativeValidate (ngSubmit)="register()">
          <div class="form-group" *ngIf="(regInfo.type == 'mobile' && registration_step == 'generate_otp') || (regInfo.type == 'email' && registration_step == 'additional_info')" style="margin-bottom: 2px;">
            <input type="text"  name="phone" placeholder="Mobile Number"  class="custom-control form-control small-height"  [(ngModel)]="regInfo.phone" required="">
            <div class="invalid-feedback"></div>
          </div>

	  <div class="form-group" *ngIf="(regInfo.type == 'mobile' && registration_step != 'generate_otp')" style="margin-bottom: 2px;">
		  <p><span style="color: #385A82;">{{regInfo.phone}}</span>&nbsp;&nbsp;&nbsp;&nbsp;<span *ngIf="registration_step == 'validate_otp'" style="text-decoration: underline; cursor: pointer; color: #385A82;" (click)="resetRegisterModal()">Change</span></p>
	  </div>

          <div class="form-group" *ngIf="(regInfo.type == 'email' && registration_step == 'generate_otp') || (regInfo.type == 'mobile' && registration_step == 'additional_info')" style="margin-bottom: 2px;">
            <input type="email" placeholder="Email Address"  name="email"  class="custom-control form-control small-height"  [(ngModel)]="regInfo.email" required="">
            <div class="invalid-feedback"></div>
          </div>

	  <div class="form-group" *ngIf="registration_step == 'generate_otp'" style="margin-bottom: 2px;">
		  <p><span style="text-decoration: underline; cursor: pointer; color: #385A82;" (click)="toggleOtpType()">Use {{regInfo.other_type}} instead </span></p>
	  </div>


	  <div class="form-group" *ngIf="(regInfo.type == 'email' && registration_step != 'generate_otp')" style="margin-bottom: 2px;">
		  <p><span style="color: #385A82;">{{regInfo.email}}</span>&nbsp;&nbsp;&nbsp;&nbsp;<span style="text-decoration: underline; cursor: pointer; color: #385A82;" (click)="resetRegisterModal()">Change</span></p>
	  </div>


          <div class="form-group" *ngIf="registration_step == 'validate_otp'" style="margin-bottom: 2px;">
            <input type="number" placeholder="OTP" name="password" class="custom-control form-control small-height"  [(ngModel)]="regInfo.password" required="">
            <div class="invalid-feedback"></div>
          </div>

	  <div class="form-group" *ngIf="(registration_step == 'validate_otp')" style="margin-bottom: 2px;">
		  <p><span *ngIf="resend_otp_after < 1" style="text-decoration: underline; cursor: pointer; color: #385A82;" (click)="generateOtp()">Resend OTP </span> <span *ngIf="resend_otp_after > 0" style="text-decoration: underline; color: #385A82;" >Resend OTP after {{resend_otp_after}} </span></p>
	  </div>

          <div class="form-group" *ngIf="registration_step == 'additional_info'" style="margin-bottom: 2px;">
            <input placeholder="First Name" type="text" name="first_name"  class="custom-control form-control small-height" [(ngModel)]="regInfo.first_name" required="">
            <div class="invalid-feedback"></div>
          </div>
          <div class="form-group" *ngIf="registration_step == 'additional_info'" style="margin-bottom: 2px;">
            <input type="text" placeholder="Last Name"  name="last_name"  class="custom-control form-control small-height"  [(ngModel)]="regInfo.last_name" required="">
            <div class="invalid-feedback"></div>
          </div>

          <div class="form-group" *ngIf="registration_step == 'additional_info'" style="margin-bottom: 8px;">
            <button type="submit" *ngIf="loadingR == false" class="btn-login">Register</button>
            <button type="button" *ngIf="loadingR == true" disabled class="btn-login">Register <i class="fa fa-spinner fa-spin"></i></button>
          </div>

	  <div class="form-group" *ngIf="registration_step == 'generate_otp'" style="margin-bottom: 8px;">
            <button type="button" *ngIf="loadingR == false" (click)="generateOtp()" class="btn-login" >Get Otp</button>
            <button type="button" *ngIf="loadingR == true" disabled class="btn-login">Get Otp <i class="fa fa-spinner fa-spin"></i></button>
          </div>

	  <div class="form-group" *ngIf="registration_step == 'validate_otp'" style="margin-bottom: 8px;">
            <button type="button" *ngIf="loadingR == false" (click)="validateOtp()" class="btn-login" >Verify OTP</button>
            <button type="button" *ngIf="loadingR == true" disabled class="btn-login">Verify Otp <i class="fa fa-spinner fa-spin"></i></button>
          </div>
        </form>

       <div class="form-text" *ngIf="registration_step == 'additional_info'">
          <p>Access and use of a1daam sites subject to our
            <a target="_blank" [routerLink]="['', 'term-condition']">Terms & Conditions</a> and
            <a target="_blank" [routerLink]="['', 'privacy-policy']">Privacy Policy</a>
          </p>
        </div>
      </div>

    </div>
  </div>
</div>



<div class="modal fade custom-modal " id="forgot">
  <div class="modal-dialog modal-lg  modal-dialog-centered">
    <div class="modal-content modal-content-custom">
      <div class="modal-header pb-0 border-0">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <i class="material-icons">close</i>
        </button>
      </div>
      <div class="modal-body w-small">
        <h2 class="text-1">Forgot Password</h2>
        <p>
          Enter the email associated with your account for password reset instructions
        </p>
        <div class="alert alert-success" *ngIf="errMsg != ''">{{errMsg}}</div>
        <form ngNativeValidate (ngSubmit)="enterEmail()">
          <div class="form-group">
            <label class="custom-label">EMAIL ADDRESS</label>
            <input type="email" name="Forgotemail" class="custom-control form-control small-height" [(ngModel)]="mailData.email" required="">
            <div class="invalid-feedback"></div>
          </div>
          <div class="form-group">
            <button type="submit" *ngIf="loading === false" class="btn-login">SUBMIT</button>
            <button type="button" *ngIf="loading === true" disabled class="btn-login">SUBMIT <i class="fa fa-spinner fa-spin"></i></button>
          </div>
          <div class="margin-10"></div>
        </form>
      </div>
      <div class="bottom-bar">
        <div class="text-box">
          <div class="text-1">ALREASY HAVE AN ACCOUNT?</div>
          <a href="javascript:void(0)" (click)="showLoginModal()">Back to Login</a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade custom-modal " id="search">
  <div class="modal-dialog modal-lg  modal-dialog-centered">
    <div class="modal-content modal-content-custom allFixed">
      <div class="main-search-box">
        <input type="text" class="form-control custom-control" placeholder="Search for your favorites on A1daam" (keyup)="searchProduct()" [(ngModel)]="searchParam.search">
        <i class="material-icons search">search</i>
        <i class="material-icons close" data-dismiss="modal">close</i>
      </div>
      <div class="searchResult openAllModal" (clickOutside)="onClickedOutsideSearch()">
        <span *ngIf="loading == true" style="position: relative;left: 40%;" class="fa fa-circle-o-notch kola fa-spin"></span>
        <div class="search-default" *ngIf="searchData == null && loading == false">
          <div class="title">No Result Found</div>
        </div>
        <div *ngIf="searchData != null">
          <a  href="/product/{{product.id}}" class="each-search-product" *ngFor="let product of searchData">
            <div class="img">
              <img src="{{product.image_url}}" alt="">
            </div>
            <div class="info">
              <div class="title">{{product.name}}</div>
              <div class="sub-title">Price <a href="javascript:void(0)">{{product.formated_price}}</a></div>
            </div>
          </a>
        </div>
        <a  [routerLink]="['/search', searchParam.search]"  (click)="SearchmodalHide()" class="view-all-btn" *ngIf="searchData != null">
          VIEW ALL RESULTS
        </a>
      </div>
    </div>
  </div>
</div>
