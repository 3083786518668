import { Component, OnInit } from '@angular/core';
import {environment} from '../../environments/environment';
declare const $: any;

@Component({
  selector: 'app-user-component',
  templateUrl: './user.component.html',
})
export class UserComponent implements OnInit {

  ENV = environment;
  menuOpen = false;
  accType: any = '';
  constructor() {
    this.closeSideMenu();
  }

  ngOnInit(): void {}

  selectAcc(e) {
    let trigger = $(e.target);
    $('.box').removeClass('active');
    trigger.closest('.option-wrapper').find('.box').toggleClass('active');
  }
  closeSideMenu() {
    $('.nav-bar-mobile').removeClass('visible');
    $('.hamburger .fa-times').hide();
    $('.hamburger .fa-bars').show();
    $('.display-overflow').hide();
    this.menuOpen = false;
  }

}
