import { Component, OnInit } from '@angular/core';
import {environment} from '../../../environments/environment';
import {AuthenticationService} from '../../services/authentication.service';
import {CategoryService} from '../../services/category.service';
import {Router} from '@angular/router';
declare const $: any;
declare let toastr: any;

@Component({
  selector: 'app-Cart-component',
  templateUrl: './Cart.component.html'
})
export class CartComponent implements OnInit {
  menuOpen = false;
  is_logged = false;
  ENV = environment;
  cartInfo: any = null;
  cartParam: any = {
    quantity: '',
    option: 2,
    cart_id: '',
  };
  constructor(protected authenticationService: AuthenticationService, protected categoryService: CategoryService, private router: Router ) {
    this.authenticationService.currentCart.subscribe( res => {
      this.cartInfo = res;
    });
    const Auth = this.authenticationService.getAuthFromSession();
    if (Auth !== null) {
      this.is_logged = Auth.is_logged;
      this.cartParam.cart_id = Auth.cart_id;
    } else {
      this.router.navigate(['']);
      $('#register').modal('show');

      /*let cart_id = localStorage.getItem('guestCartID');
      cart_id = JSON.parse(cart_id);
      this.cartParam.cart_id = cart_id;*/
    }
    this.getCartDetail();

    window.scrollTo(0, 0);
    this.closeSideMenu();
  }
  ngOnInit(): void {}

  getCartDetail() {
    this.categoryService.apiGetCart(this.cartParam.cart_id).subscribe( res => {
      if (res.status === true) {
        this.cartInfo = res.data;
      }
    })
  }

  plusItem(productID, product) {
    this.cartParam.quantity = 1;
    this.categoryService.apiAddCart(this.cartParam, productID).subscribe( res => {
      if (res.status === true) {
        this.getCartDetail();
      } else {
        toastr.error(res.message);
        toastr.options.closeButton = true;
      }
    })
  }
  minusItem(productID, product) {
    if(product.qty > 1){
      this.cartParam.quantity = -1;
      this.categoryService.apiAddCart(this.cartParam, productID).subscribe( res => {
        if (res.status === true) {
          this.getCartDetail();
        } else {
          toastr.error(res.message);
          toastr.options.closeButton = true;
        }
      })
    }
  }
  removeItem(rowID) {
    this.categoryService.apiRemovaCart(this.cartParam.cart_id, rowID).subscribe( res => {
      if (res.status === true) {
        this.getCartDetail();
      }
    })
  }


  closeSideMenu() {
    $('.nav-bar-mobile').removeClass('visible');
    $('.hamburger .fa-times').hide();
    $('.hamburger .fa-bars').show();
    $('.display-overflow').hide();
    this.menuOpen = false;
  }
}
