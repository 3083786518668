<div class="_spring_prince_wrapper">
  <div class="container-fluid">
    <div class="page-header-cover">
      <div class="cover-image-wrapper">
        <img src="{{ENV.ASSET_URL}}/dummy/page-title/prince_spring.jpg" alt="">
      </div>
      <div class="content-wrapper">
        <div class="title">Premium essentials designed to elevate the everyday.</div>
        <button class="btn btn-theme">LEARN MORE</button>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="container">
      <div class="content-description-section">
        <div class="row">
          <div class="col-md-6">
            <div class="section-cover-image">
              <img src="{{ENV.ASSET_URL}}/dummy/princeSpring/1.webp" alt="">
            </div>
          </div>
          <div class="col-md-6 flex-box">
            <div class="inner-content">
              <h3 class="title">Meet Prince & Spring</h3>
              <p class="description">
                The Prince & Spring collection of essentials was created to simplify and enhance your life by making
                high-quality products conveniently accessible at incredible value. Our brand is made up of items that
                are both delightfully functional and reliable. In our eyes, every product’s packaging is a canvas and
                what you see on the outside should not just be engaging, it should also reflect the care that goes into
                developing the products on the inside. We hope our customers are as proud to display Prince & Spring
                products in their homes as we are.
              </p>
              <div class="trigger-btn">
                <button class="btn btn-theme">SHOP NOW</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-description-section">
        <div class="row">
          <div class="col-md-6 flex-box">
            <div class="inner-content">
              <h3 class="title">Commitment To Quality</h3>
              <p class="description">
                Our private brand team has literally combed the globe — from visiting pepper farms in Peru to picking
                ripe coffee beans in Costa Rica — to procure the best ingredients and learn the best processing methods
                to create the best new products and improve existing ones for a1daam’s premium private brand Prince &
                Spring. All Prince & Spring products meet strict quality and safety requirements, and compare very
                favorably to competitive national brands. Our rigorous testing guarantees the quality and consistency of
                all our products.
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="section-cover-image">
              <img src="{{ENV.ASSET_URL}}/dummy/princeSpring/2.webp" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="content-description-section">
        <div class="row">
          <div class="col-md-6">
            <div class="section-cover-image">
              <img src="{{ENV.ASSET_URL}}/dummy/princeSpring/3.webp" alt="">
            </div>
          </div>
          <div class="col-md-6 flex-box">
            <div class="inner-content">
              <h3 class="title">Elevated Design</h3>
              <p class="description">
                While most products scream for attention on a crowded shelf, Prince & Spring’s modern and simplistic
                designs engage with customers through artistic visuals and punny humor. Our talented designers use a
                variety of techniques from watercolor to food art to breathe life into Prince & Spring and amplify its
                personality.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="content-description-section">
        <div class="row">
          <div class="col-md-6 flex-box">
            <div class="inner-content">
              <h3 class="title">Products With Purpose</h3>
              <p class="description">
                We make an effort to be conscious of social and environmental issues that surround the products in our
                assortment. Each of our Prince & Spring Organic Coffees are Fair Trade Certified™ and support the fair
                treatment of coffee producers and workers and helping them build sustainable, healthy communities. When
                you choose Fair Trade Certified™ goods, you’re voting with your dollar supporting responsible companies,
                empowering workers, and protecting the environment.
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="section-cover-image">
              <img src="{{ENV.ASSET_URL}}/dummy/princeSpring/4.webp" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="content-description-section">
        <div class="row">
          <div class="col-md-6 flex-box">
            <div class="section-cover-image">
              <img src="{{ENV.ASSET_URL}}/dummy/princeSpring/5.webp" alt="">
            </div>
          </div>
          <div class="col-md-6">
            <div class="section-cover-image">
              <img src="{{ENV.ASSET_URL}}/dummy/princeSpring/5.webp" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="page-heading">
        <div class="row">
          <div class="col-md-6">
            <div class="title mini">All Prince & Spring</div>
          </div>
        </div>
      </div>
      <div class="products-list-wrapper">
        <div class="row">
          <div class="col-md-3" *ngFor="let product of ProductList; let index = index;">
            <div class="each-product">
              <a [routerLink]="['/product/'+index]">
                <div class="cover-image">
                  <img src="{{ENV.ASSET_URL}}/dummy/product/{{product.img}}" alt="">
                </div>
              </a>
              <div class="product-details-info">
                <a [routerLink]="['/product/'+index]">
                  <div class="product-title">Hint Fruit Infused Water Variety Pack</div>
                  <div class="product-quantity">12 x 16 oz</div>
                  <div class="product-price-wrapper">
                    <div class="current-price">
                      <div class="value">$14.56</div>
                    </div>
                  </div>
                  <div class="retail_save">save 32% vs retail</div>
                </a>
                <div class="options-wrapper">
                  <button type="button" class="btn btn-outline-secondary add-cart-btn">ADD</button>
                  <div class="love-wrapper"><span><i class="fa fa-heart-o" aria-hidden="true"></i></span> <span
                    class="count">10.3k</span></div>
                </div>
              </div>
              <div class="margin-40"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="load-more-btn">
        <button class="btn btn-theme">VIEW MORE</button>
      </div>
    </div>
  </div>
</div>
