<div class="position-relative">
  <div class="custom-container">
    <div class="full-page">
      <div class="section-login">
        <h2 class="text-1">Already a Customer</h2>
        <form ngNativeValidate (ngSubmit)="login()">
          <div class="form-group">
            <label  class="custom-label">USER NAME</label>
            <input type="text" name="identity"  class="custom-control form-control small-height" [(ngModel)]="loginData.identity" required="">
            <div class="invalid-feedback"></div>
          </div>
          <div class="form-group">
            <a href="javascript:void(0)" class="show-pass" (click)="showPass()"><span *ngIf="inputType == 'password'">Show</span> <span *ngIf="inputType == 'text'">Hide</span></a>
            <label class="custom-label">PASSWORD</label>
            <input [type]="inputType" name="password" class="custom-control form-control small-height" [(ngModel)]="loginData.password" required="">
          </div>
          <div class="form-group">
            <a href="javascript:void(0)" (click)="showForgotModal()" class="f-pass">Forgot password?</a>
          </div>
          <div class="form-group">
            <button type="submit" *ngIf="loading === false" class="btn-login">LOG IN</button>
            <button type="button" *ngIf="loading === true" disabled class="btn-login">LOG IN <i class="fa fa-spinner fa-spin"></i></button>
          </div>
        </form>
      </div>
      <div class="section-reg">
        <h2 class="text-1 pb-36">Create An Account</h2>
        <button class="btn-goreg" [routerLink]="['/register']">CREATE ACCOUNT</button>
        <div class="page-divider"></div>
      </div>
    </div>
    <div class="bottom-bar">
      <div class="text-box">
        <div class="text-1">NEW TO a1daam?</div>
        <a href="javascript:void(0)" >Create a FREE account</a>
      </div>
    </div>
  </div>
  <div class="bottom-bar">
    <div class="text-box">
      <div class="text-1">NEW TO a1daam?</div>
      <a href="javascript:void(0)" [routerLink]="['/register']">Create a FREE account</a>
    </div>
  </div>
</div>
