import { Component, OnInit } from '@angular/core';
import {environment} from '../../../environments/environment';
import {AuthenticationService} from '../../services/authentication.service';
import {Router} from '@angular/router';
declare const $: any;
declare const toastr: any;

@Component({
  selector: 'app-add-address',
  templateUrl: './AddAddress.component.html'
})
export class AddAddressComponent implements OnInit {

  ENV = environment;
  menuOpen = false;
  is_logged = false;
  countryData: any = null;
  stateData: any = null;
  param: any = {
    first_name: '',
    last_name: '',
    city: '',
    line1: '',
    country: '',
    state: '',
    line2: '',
    phone: '',
  };
  loading = false;
  constructor(protected authenticationService : AuthenticationService,  private router: Router) {
    const Auth = this.authenticationService.getAuthFromSession();
    if (Auth !== null) {
      this.getCountry();
      this.is_logged = Auth.is_logged;
    } else {
      this.router.navigate(['']);
      setTimeout(function () {
        $('#register').modal('show');
      },100)
    }

    window.scrollTo(0, 0);
    this.closeSideMenu();
  }
  ngOnInit(): void {}

  addAddress() {
    this.loading = true;
    this.authenticationService.apiAddAddress(this.param).subscribe( res =>  {
      if (res.status === true) {
        toastr.success(res.message);
        toastr.options.closeButton = true;
        this.router.navigate(['/user/list-address']);
        this.loading = false;
      }
    });
  }

  getCountry() {
    this.authenticationService.getCountry().subscribe(res => {
      if (res.status === true) {
        this.countryData = res.data;
      }
    })
  }

  getState() {
    this.authenticationService.getState(this.param.country).subscribe(res => {
      if (res.status === true) {
        this.stateData = res.data;
      }
    })
  }

  closeSideMenu() {
    $('.nav-bar-mobile').removeClass('visible');
    $('.hamburger .fa-times').hide();
    $('.hamburger .fa-bars').show();
    $('.display-overflow').hide();
    this.menuOpen = false;
  }
}
