<div class="-user-settings-wrapper">
  <div class="breadcum-wrapper">
    <div class="breadcrumbs">
      <span>
        <a [routerLink]="['']">Home</a>
        <span class="icon"> > </span>
      </span>
      <span>
        <a [routerLink]="['/user/account-settings']">Settings</a>
        <span class="icon"> > </span>
      </span>
      <span>
        <a [routerLink]="['/user/list-payment']">Manage Payment</a>
        <span class="icon">  </span>
      </span>
    </div>
  </div>
  <div class="container">
    <div class="page-heading">
      <div class="title">Manage Payment</div>
      <div class="slogan">Add and delete a payment option for a quick checkout</div>
      <div class="margin-30"></div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <p>Select a payment method:</p>
        <a [routerLink]="['/user/add-payment']" class="btn btn-theme w-100">ADD NEW PAYMENT</a>
      </div>
    </div>
  </div>
</div>
