import { Component, OnInit } from '@angular/core';
import {environment} from '../../environments/environment';
import {CategoryService} from '../services/category.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';
import {HomeService} from '../services/home.service';
declare const $: any;
declare let toastr: any;
declare let Ladda: any;

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
})
export class ProductComponent implements OnInit {

  ENV = environment;
  menuOpen = false;
  loading = false;
  is_logged = false;
  Loading = false;
  productID: any = '';
  ProductData: any = null;
  homeData: any = null;
  commentData: any = null;
  OWL = null;
  hover: any = '';
  cartParam: any = {
    quantity: 1,
    option: '',
    cart_id: '',
  };
  reviewData: any = {
    product_id: '',
    rating: '',
    comment: '',
  };
  url: any = '';
  cartInfo: any = null;
  constructor(private router: Router, protected authenticationService : AuthenticationService,  protected homeService: HomeService,  protected categoryService : CategoryService,  private route: ActivatedRoute) {
    this.authenticationService.currentCart.subscribe( res => {
      this.cartInfo = res;
    });
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.productID = this.route.snapshot.paramMap.get('id');
        this.getProductSingle();
        this.getReview();
        this.reviewData.product_id = this.productID
        const Auth = this.authenticationService.getAuthFromSession();
        if (Auth !== null) {
          this.is_logged = true;
          this.cartParam.cart_id = Auth.cart_id;
        }
        window.scrollTo(0, 0);
        this.closeSideMenu();
        this.url = window.location.href;
        this.authenticationService.currentCart.subscribe( res => {
          this.cartInfo = res;
        });
      }
    });
    this.productID = this.route.snapshot.paramMap.get('id');
    this.getProductSingle();
    this.getReview();
    this.reviewData.product_id = this.productID
    const Auth = this.authenticationService.getAuthFromSession();
    if (Auth !== null) {
      this.is_logged = true;
      this.cartParam.cart_id = Auth.cart_id;
    }
    this.url = window.location.href;

    this.getHome();
  }


  copyLink() {
    let copyText = document.getElementById("uurl") as HTMLInputElement;
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    toastr.success('Link copied to clipboard successfully');
    toastr.options.closeButton = true;
  }

  getProductSingle() {
    this.Loading = true;
    this.ProductData = null;
    this.categoryService.getProductSingle(this.productID).subscribe( res => {
      if (res.status === true) {
        this.ProductData = res.data;
        if(this.ProductData.variants.length > 0){
          this.cartParam.option = this.ProductData.variants[0].id;
        }
        this.Loading = false;
        setTimeout(() => {
          this.imgSlider();
          setTimeout(() => {
            $('.image-each').trigger('zoom.destroy');
            $('.image-each').zoom({magnify: 2});
          }, 500);
        }, 500);
      }
    })
  }

  makeWishList(product, wish){
    let idx = this.homeData.featured_products.map(e => e.id).indexOf(product.id);
    if(idx > -1){ this.homeData.featured_products[idx].wishlist = wish; }
    idx = this.homeData.promotional_products.map(e => e.id).indexOf(product.id);
    if(idx > -1){ this.homeData.promotional_products[idx].wishlist = wish; }
    idx = this.homeData.popular_products.map(e => e.id).indexOf(product.id);
    if(idx > -1){ this.homeData.popular_products[idx].wishlist = wish; }
    idx = this.ProductData.map(e => e.id).indexOf(product.id);
    if(idx > -1){ this.ProductData[idx].wishlist = wish; }

  }

  addTowish(product) {
    const productId = product.id;
    if (this.is_logged == true) {
      if (product.wishlist == 0) {
        this.categoryService.apiAddWish(productId).subscribe( res => {
          if (res.status === true) {
            this.makeWishList(product, 1);
          } else {
            $('#login').modal('show');
          }
        });
      } else {
        this.categoryService.apiDeleteWish(productId).subscribe( res => {
          if (res.status === true) {
            this.makeWishList(product, 0);
          } else {
            $('#login').modal('show');
          }
        });
      }
    }  else {
      $('#login').modal('show');
    }
  }


  getHome() {
    this.homeService.getHome().subscribe( res => {
      if (res.status === true) {
        this.homeData = res.data;
        setTimeout(() => {
          this.productSlider();
        }, 300);
      }
    })
  }



  addTowishFeat(product, i) {
    const productId = product.id;
    if (this.is_logged == true) {
      if (product.wishlist == 0) {
        this.categoryService.apiAddWish(productId).subscribe( res => {
          if (res.status === true) {
            this.makeWishList(product, 1);
          } else {
            $('#login').modal('show');
          }
        });
      } else {
        this.categoryService.apiDeleteWish(productId).subscribe( res => {
          if (res.status === true) {
            this.makeWishList(product, 0);
          } else {
            $('#login').modal('show');
          }
        });
      }
    }  else {
      $('#login').modal('show');
    }
  }

  addTowishPromo(product, i) {
    const productId = product.id;
    if (this.is_logged == true) {
      if (product.wishlist == 0) {
        this.categoryService.apiAddWish(productId).subscribe( res => {
          if (res.status === true) {
            this.makeWishList(product, 1);
          } else {
            $('#login').modal('show');
          }
        });
      } else {
        this.categoryService.apiDeleteWish(productId).subscribe( res => {
          if (res.status === true) {
            this.makeWishList(product, 0);
          }  else {
            $('#login').modal('show');
          }
        });
      }
    }  else {
      $('#login').modal('show');
    }
  }

  addTowishPopular(product, i) {
    const productId = product.id;
    if (this.is_logged == true) {
      if (product.wishlist == 0) {
        this.categoryService.apiAddWish(productId).subscribe( res => {
          if (res.status === true) {
            this.makeWishList(product, 1);
          } else {
            $('#login').modal('show');
          }
        });
      } else {
        this.categoryService.apiDeleteWish(productId).subscribe( res => {
          if (res.status === true) {
            this.makeWishList(product, 0);
          }  else {
            $('#login').modal('show');
          }
        });
      }
    }  else {
      $('#login').modal('show');
    }
  }

  productSlider() {
    $('.slider-p').owlCarousel({
      loop: true,
      nav: true,
      navText: ["<i class='material-icons-outlined md' aria-hidden='true'>keyboard_arrow_left</i>","<i class='material-icons-outlined md' aria-hidden='true'>keyboard_arrow_right</i>"],
      responsiveClass:true,
      responsive: {
        0: {
          items: 1,
        },
        599: {
          items: 2,
        },
        768: {
          items: 3,
        },
        991: {
          items: 4,
        },
      }
    })

  }

  ngOnInit(): void {}

  imgSlider(){
    this.OWL = $('.single-p-carosule').owlCarousel({
      margin: 0,
      items: 1,
      navText: ["<i class='material-icons-outlined md' aria-hidden='true'>keyboard_arrow_left</i>","<i class='material-icons-outlined md' aria-hidden='true'>keyboard_arrow_right</i>"],
      dotsContainer: '#carousel-custom-dots',
      animateIn: 'fadeIn',
      animateOut: 'fadeOut',
      responsiveClass:true,
      responsive: {
        0: {
          nav: true,
        },
        800: {
          nav: false,
        }
      }
    });

    let _this = this;

    $('.each-dot').click(function () {
      _this.OWL.trigger('to.owl.carousel', [$(this).index(), 300]);
    });

  }

  showAboutText() {
    $('.hide-show').toggle();
    $('.read-more').toggle();
  }

  closeSideMenu() {
    $('.nav-bar-mobile').removeClass('visible');
    $('.hamburger .fa-times').hide();
    $('.hamburger .fa-bars').show();
    $('.display-overflow').hide();
    this.menuOpen = false;
  }
  addToCart(product, e) {
    let ldd = e.target;
    let ladda = Ladda.create(ldd);
    if (product.type == 'service') {
      if (this.cartInfo != null) {
        if (this.cartInfo.total_items == 0) {
          if (this.is_logged === true) {
            ladda.start();
            this.categoryService.apiAddCart(this.cartParam, product.id).subscribe( res => {
              ladda.stop();
              if (res.status === true && res.is_logged === true) {
                this.getCartDetail();
              } else if (res.status === false) {
                toastr.error(res.message);
                toastr.options.closeButton = true;
                ladda.stop();
              }
            })
          } else {
            $('#login').modal('show');
          }
        } else {
          toastr.error('Please remove item from cart before adding subscription');
          toastr.options.closeButton = true;
        }
      }
    }
    else {
      if (this.is_logged === true) {
        ladda.start();
        this.categoryService.apiAddCart(this.cartParam, product.id).subscribe( res => {
          ladda.stop();
          if (res.status === true && res.is_logged === true) {
            this.getCartDetail();

          }
        })
      } else {
        let cart_id = localStorage.getItem('guestCartID');
        cart_id = JSON.parse(cart_id);
        if (cart_id != null) {
          this.cartParam.cart_id = cart_id;
          ladda.start();
          this.categoryService.apiAddCart(this.cartParam, product.id).subscribe( res => {
            ladda.stop();
            if (res.status === true ) {
              this.getCartDetail();
            } else {
              toastr.error(res.message);
              toastr.options.closeButton = true;
            }
          })
        } else {
          ladda.start();
          this.categoryService.apiAddCart(this.cartParam, product.id).subscribe( res => {
            ladda.stop();
            if (res.status === true  ) {
              this.cartParam.cart_id = res.cart_id;
              let cartID_set = JSON.stringify(res.cart_id);
              localStorage.setItem("guestCartID", cartID_set);
              this.getCartDetail();
            } else {
              toastr.error(res.message);
              toastr.options.closeButton = true;
            }
          });
        }
      }
    }
  }

  getCartDetail() {
    this.categoryService.apiGetCart(this.cartParam.cart_id).subscribe( res => {
      if (res.status === true) {
        this.authenticationService.updateCart(res.data);
      }
    })
  }
  getReview() {
    this.categoryService.apiGetReview(this.productID, 1).subscribe( res => {
      if (res.status === true) {
        this.commentData = res.data;
      }
    })
  }
  addReview() {
    this.loading = true;
    this.categoryService.apiAddReview(this.reviewData).subscribe( res => {
      this.loading = false;
      if (res.status === true) {
        toastr.success(res.message);
        toastr.options.closeButton = true;
        this.getReview();
        this.reviewData.comment = '';
        this.reviewData.rating = '';
      } else {
        if (res.message.rating != undefined){
          toastr.error(res.message.rating);
          toastr.options.closeButton = true;
        } else {
          toastr.warning(res.message);
          toastr.options.closeButton = true;
        }
      }
    })
  }
  rating(r) {
    return parseInt(r, 10)
  }
}
