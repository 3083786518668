import {Inject, Injectable, Optional} from '@angular/core';
import {environment} from "../../environments/environment";
import {
  HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent
} from '@angular/common/http';
import {Observable, BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  protected basePath = environment.API_URL;
  protected token = '';
  public defaultHeaders = new HttpHeaders();

  user = new BehaviorSubject<any>(null);
  currentUser = this.user.asObservable();


  cart = new BehaviorSubject<any>(null);
  currentCart = this.cart.asObservable();


  constructor(protected httpClient: HttpClient) {
    const user = this.getAuthFromSession();
    if(user !== null){
      this.token = user.token;
    }
    this.currentUser.subscribe(user => {
      if(user !== null){
        this.token = user.token;
      }
    });
  }


  updateUser(data) {
    this.user.next(data);
  }


  updateCart(data) {
    this.cart.next(data);
  }

  getAuthFromSession() {
    let userData = null;
    userData = localStorage.getItem('userData');
    return JSON.parse(userData) ;
  }

  public getUser(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);
    headers = headers.set('X-token', this.token);


    return this.httpClient.get(`${this.basePath}/auth/user`, { headers: headers });
  }
  public getCountry(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);
    headers = headers.set('X-token', this.token);


    return this.httpClient.get(`${this.basePath}/address/countries`, { headers: headers });
  }
  public getState(countryID: any = '', observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);
    headers = headers.set('X-token', this.token);

    return this.httpClient.get(`${this.basePath}/address/states/${encodeURIComponent(String(countryID))}`, { headers: headers });
  }

  public getConfig(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);
    // headers = headers.set('mba', this.token);

    return this.httpClient.get(`${this.basePath}/config`, { headers: headers });
	  }


  
  public getCoupons(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);
    headers = headers.set('X-token', this.token);

    return this.httpClient.get(`${this.basePath}/order/coupons`, { headers: headers });
  }

  public getAddress(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);
    headers = headers.set('X-token', this.token);

    return this.httpClient.get(`${this.basePath}/address/list`, { headers: headers });
  }

  public getPayment(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);
    headers = headers.set('X-token', this.token);

    return this.httpClient.get(`${this.basePath}/payment/list`, { headers: headers });
  }


  public addPayment(body: any = '', observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);
    headers = headers.set('X-token', this.token);

    let form_data = new FormData();
    for ( let key in body ) {
      form_data.append(key, body[key]);
    }
    return this.httpClient.post<any>(`${this.basePath}/payment/add`, form_data, {headers});
   }

  public apiAddAddress(body: any = '', observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);
    headers = headers.set('X-token', this.token);

    let form_data = new FormData();
    for ( let key in body ) {
      form_data.append(key, body[key]);
    }
    return this.httpClient.post<any>(`${this.basePath}/address/add`, form_data, {headers});
  }
  public apiDeleteAddress(body: any = '', addID: any = '', observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);
    headers = headers.set('X-token', this.token);

    let form_data = new FormData();
    for ( let key in body ) {
      form_data.append(key, body[key]);
    }
    return this.httpClient.post<any>(`${this.basePath}/address/delete/${encodeURIComponent(String(addID))}`, form_data, {headers});
  }
  public apiUpdatePassword(body: any = '', observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);
    headers = headers.set('X-token', this.token);

    let form_data = new FormData();
    for ( let key in body ) {
      form_data.append(key, body[key]);
    }
    return this.httpClient.post<any>(`${this.basePath}/auth/update_password`, form_data, {headers});
  }

  public apiUpdateProfile(body: any = '', observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);
    headers = headers.set('X-token', this.token);

    let form_data = new FormData();
    for ( let key in body ) {
      form_data.append(key, body[key]);
    }
    return this.httpClient.post<any>(`${this.basePath}/auth/update_profile`, form_data, {headers});
  }

  public apiRegister(body: any = '', observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);
   /* headers = headers.set('Content-Type',  'application/x-www-form-urlencoded');*/
    // headers = headers.set('mba', this.token);

    let form_data = new FormData();
    for ( let key in body ) {
      form_data.append(key, body[key]);
    }
    return this.httpClient.post<any>(`${this.basePath}/auth/register`, form_data, {headers});
  }

  public apiLogin(body: any = '', observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);
    // headers = headers.set('mba', this.token);

    let form_data = new FormData();
    for ( let key in body ) {
      form_data.append(key, body[key]);
    }
    return this.httpClient.post<any>(`${this.basePath}/auth/login`, form_data, {headers});
  }

  public apiLogout(body: any = '', observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);
    // headers = headers.set('mba', this.token);

    let form_data = new FormData();
    for ( let key in body ) {
      form_data.append(key, body[key]);
    }
    return this.httpClient.post<any>(`${this.basePath}/auth/logout`, form_data, {headers});
  }

  public apiEnterEmail(body: any = '', observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);
    // headers = headers.set('mba', this.token);

    let form_data = new FormData();
    form_data.append('email', body.email);
    return this.httpClient.post<any>(`${this.basePath}/auth/forgot`, form_data, {headers});
  }

  public addOrder(body: any = '', observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);
    headers = headers.set('X-token', this.token);

    let form_data = new FormData();
    for ( let key in body ) {
      form_data.append(key, body[key]);
    }
    return this.httpClient.post<any>(`${this.basePath}/order/add`, form_data, {headers});
  }


  public verifyCouponCode(body: any = '', observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);
    headers = headers.set('X-token', this.token);

    let form_data = new FormData();
    for ( let key in body ) {
      form_data.append(key, body[key]);
    }
    return this.httpClient.post<any>(`${this.basePath}/order/validate_coupon`, form_data, {headers});
  }



  public getOrder(page: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);
    headers = headers.set('X-token', this.token);

    return this.httpClient.get(`${this.basePath}/order/list?page=${encodeURIComponent(String(page))}`, { headers: headers });
  }

  public getOrderDetail(reference: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);
    headers = headers.set('X-token', this.token);

    return this.httpClient.get(`${this.basePath}/order/read?reference=${encodeURIComponent(String(reference))}`, { headers: headers });
  }

  public cancelOrder(body: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);
    headers = headers.set('X-token', this.token);

    let form_data = new FormData();
    for ( let key in body ) {
      form_data.append(key, body[key]);
    }
    return this.httpClient.post(`${this.basePath}/order/cancel`, form_data,{ headers: headers });
  }

  public returnOrder(body: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);
    headers = headers.set('X-token', this.token);
    headers = headers.set('Accept', 'text/plain');

    let ReturnItems = []
    for(let i = 0; i < body.items.length; i++){
      ReturnItems.push({
        product_id: body.items[i].product_id,
        quantity: body.items[i].quantity,
      })
    }
    let form_data = {
      reference: body.reference,
      return_reason: body.cancel_reason,
      items: ReturnItems
    }
    return this.httpClient.post(`${this.basePath}/order/return`, form_data,{ headers: headers });
  }

  public getAbout(slug: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);
    headers = headers.set('X-token', this.token);

    return this.httpClient.get(`${this.basePath}/pages?page=${encodeURIComponent(String(slug))}`, { headers: headers });
  }

  public getPages(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);
    headers = headers.set('X-token', this.token);

    return this.httpClient.get(`${this.basePath}/pages/all`, { headers: headers });
	  }

 public generateOtp(body: any = '', observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);

    let form_data = new FormData();
    for ( let key in body ) {
      form_data.append(key, body[key]);
    }
    return this.httpClient.post<any>(`${this.basePath}/auth/generate_otp`, form_data, {headers});
  }

}
