import {Component, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Router} from "@angular/router";
import {AuthenticationService} from '../../services/authentication.service';
import {CategoryService} from '../../services/category.service';
import {SiteConfig} from "../../Helpers/config";
import {WindowRefService} from '../../window-ref.service';

declare const $: any;
declare const toastr: any;

@Component({
  selector: 'app-checkout-component',
  templateUrl: './checkout.component.html',
  providers: [WindowRefService]
})
export class CheckoutComponent implements OnInit {

  ENV = environment;
  menuOpen = false;
  is_logged = false;
  cartParam: any = {
    quantity: '',
    option: 2,
    cart_id: '',
  };
  param: any = {
    first_name: '',
    last_name: '',
    city: '',
    line1: '',
    country: '',
    state: '',
    line2: '',
    phone: '',
  };
  addressData: any = [];
  couponData: any = [];
  loading = false;
  loading_coupon = false;
  cartInfo: any = null;
  countryData: any = null;
  paymentData: any = [];
  stateData: any = null;
  selectedAddress: any = {};
  editAddress = false;
  order_error: any = false;
  noAddres = false;
  noPayment = false;
  note = '';
  coupon_code = '';
  coupon_code_verified = '';
  coupon_error = '';
  coupon_success = '';
  discount_amount = 0;
  discounted_grand_total = 0;
  paymentMethod = '';
  delivery_available = false;
  availability_error = '';
  logo = '';

  constructor(private router: Router, protected authenticationService: AuthenticationService, protected categoryService: CategoryService, private winRef: WindowRefService) {
    const Auth = this.authenticationService.getAuthFromSession();
    if (Auth !== null) {
      this.getCountry();
      this.getAddress();
      this.getPayment();
      this.is_logged = Auth.is_logged;
      this.cartParam.cart_id = Auth.cart_id;
    } else {
      let cart_id = localStorage.getItem('guestCartID');
      cart_id = JSON.parse(cart_id);
      this.cartParam.cart_id = cart_id;
      this.router.navigate(['']);
      setTimeout(function () {
        $('#register').modal('show');
      }, 100)
    }

    this.getCoupons();
    //this.getCartDetail();

    window.scrollTo(0, 0);
    this.closeSideMenu();
    this.logo = SiteConfig().logo_slug;
    this.razorHandler = this.razorHandler.bind(this);

  }

  getCartDetail() {
    this.categoryService.apiGetCart(this.cartParam.cart_id, this.selectedAddress.postal_code).subscribe(res => {
      if (res.status === true) {
        this.cartInfo = res.data;
      }
    })
  }


  getPincodeAvailable() {
    this.categoryService.apiPincodeAvailablity(this.selectedAddress.postal_code).subscribe(res => {

	console.log(res);
      if (res.status == true) {
        this.delivery_available = true;
      } else {
        this.delivery_available = false;
        toastr.error(res.message);
        this.availability_error = res.message;
        toastr.options.closeButton = true;
      }
    })
  }

  selectAddress(data) {
    this.selectedAddress = data;
    this.noAddres = false;
    this.editAddress = false;
    this.getPincodeAvailable();
    this.getCartDetail();
  }

  addAddress() {
    this.loading = true;
    this.authenticationService.apiAddAddress(this.param).subscribe(res => {
      if (res.status === true) {
        toastr.success(res.message);
        toastr.options.closeButton = true;
        this.getAddress();
      }
    });
  }

  setCouponCode(code) {
    this.coupon_code = code;
  }


  verifyCoupon() {
    this.loading_coupon = true;
    this.coupon_code_verified = '';
    this.discount_amount = 0;
    this.discounted_grand_total = 0;
    this.coupon_error = '';
    this.coupon_success = '';
    this.authenticationService.verifyCouponCode({'coupon_code': this.coupon_code}).subscribe(res => {
      if (res.status === true) {
        this.coupon_success = res.message;
        this.coupon_code_verified = res.coupon_code;
        this.discounted_grand_total = this.cartInfo.grand_total_for_coupon - res.discount_amount;
        this.discount_amount = res.discount_amount;
      } else {
        this.coupon_error = res.message;
      }
      this.loading_coupon = false;
    });
  }

  getCoupons() {
    this.authenticationService.getCoupons().subscribe(res => {
      if (res.status === true) {
        if (res.data.length > 0) {
          this.couponData = res.data;
        }
      }
    })
  }

  getAddress() {
    this.authenticationService.getAddress().subscribe(res => {
      if (res.status === true) {
        this.loading = false;
        if (res.data.length > 0) {
          this.addressData = res.data;
          this.selectedAddress = res.data[0];
          this.noAddres = false;
          this.editAddress = false;
          this.getPincodeAvailable();
          this.getCartDetail();

        }
        if (res.data.length == 0) {
          this.noAddres = true;
        }
      }
    })
  }

  getPayment() {
    this.authenticationService.getPayment().subscribe(res => {
      this.paymentData = res.data;
    })
  }

  setPaymentMethod(method) {
    this.paymentMethod = method;
  }

  razorHandler(response) {
    const payAdd = {
      payment_method: 'razorpay',
      sale_id: response.razorpay_order_id,
      payment_id: response.razorpay_payment_id,
      signature: response.razorpay_signature
    };
    this.authenticationService.addPayment(payAdd).subscribe(resp => {
      this.router.navigate(['/user/thanks', resp.reference_no]);
    })
  }

  addOrder() {
  this.loading = true;
  this.order_error = false;
    const data = {
      address: this.selectedAddress.id,
      payment_method: this.paymentMethod,
      note: this.note,
      coupon_code: this.coupon_code_verified
    }
    this.authenticationService.addOrder(data).subscribe(res => {
      if (res.status === true) {
        if (res.payment_method == 'razorpay') {
          var options = {
            "key": res.razorpay_key,
            "amount": res.payment_gateway_price,
            "currency": "INR",
            "name": "A1daam",
            "description": "Payment against order",
            "image": this.logo,
            "order_id": res.order_id_gateway,
            "handler": this.razorHandler,
            "prefill": {
              "name": res.name,
              "email": res.email,
              "contact": res.phone
            },
            "notes": {},
            "theme": {},
            "modal": {
              "backdropclose": false,
              "escape": false,
              "ondismiss": function () {
                this.loading = false;
              }
            }
          };
          const rzp = new this.winRef.nativeWindow.Razorpay(options);
          rzp.open();

        } else {
          this.router.navigate(['/user/thanks', res.reference_no]);
        }
	} else {
		toastr.error(res.message);
		this.loading = false;
		this.order_error = res.message;
	}

    })
  }

  getCountry() {
    this.authenticationService.getCountry().subscribe(res => {
      if (res.status === true) {
        this.countryData = res.data;
      }
    })
  }

  getState() {
    this.authenticationService.getState(this.param.country).subscribe(res => {
      if (res.status === true) {
        this.stateData = res.data;
      }
    })
  }


  selectAcc() {
    $('.box').toggleClass('active');
    $('.use-commerce').toggle();
  }

  toggleProductOrdered() {
    $('.or-pro').toggle()
    $('.ilo').toggle()
  }

  toggleProductDiscount() {
    $('.discount').toggle()
    $('.llo').toggle()
  }

  collapseForm() {
    $('.creditForm').toggle()
  }


  closeSideMenu() {
    $('.nav-bar-mobile').removeClass('visible');
    $('.hamburger .fa-times').hide();
    $('.hamburger .fa-bars').show();
    $('.display-overflow').hide();
    this.menuOpen = false;
  }

  checkAuthDetails() {
    this.authenticationService.getUser().subscribe(res => {
      if (res.is_logged === false) {
        this.authenticationService.apiLogout().subscribe(res => {
          if (res.status === true) {
            localStorage.removeItem("userData");
            delete localStorage.is_logged_time;
            this.router.navigate(['']);
            this.is_logged = false;
            toastr.success("Session out");
            toastr.options.closeButton = true;
            setTimeout(() => {
              location.reload()
            }, 500);
          }
        })
      } else {
        if (res.status === true) {
          localStorage.setItem("is_logged_time", ((new Date()).getTime()).toString());
        }
      }
    })
  }


  ngOnInit(): void {
    $('body').on('click', () => {
      let currentTime = ((new Date()).getTime());
      let is_logged_time = localStorage.getItem('is_logged_time') == null ? '0' : localStorage.getItem('is_logged_time');
      if (is_logged_time !== '0') {
        let msDifference = currentTime - parseInt(is_logged_time);
        let minutes = Math.floor(msDifference / 1000 / 60);
        if (minutes >= 5) {
          this.checkAuthDetails();
        } else {

        }
      }
    })
  }

}
