import {Component, OnInit} from '@angular/core';
import {environment} from '../../environments/environment';
import {PageConfig, SiteConfig} from "../Helpers/config";
import {Router} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';
import {HomeService} from '../services/home.service';
import {CategoryService} from '../services/category.service';

declare const $: any;
declare let toastr: any;
declare let Ladda: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {

  ENV = environment;
  menuOpen = false;
  is_logged = false;
  OWL = null;
  HomeSlide = [];
  homeData: any = null;
  ProductData: any = [];
  AllNewProductData: any = [];
  lastPage: any = '';
  errMsg: any = '';
  catCode: any = '';
  param = {
    max_price: '',
    min_price: '',
    brand_code: '',
    order_by: 'views,desc',
    page: 1,
    search: '',
  };
  cartParam: any = {
    quantity: 1,
    option: 2,
    cart_id: '',
  };
  cartInfo: any = null;
  catOwl: any = null;


  constructor(protected authenticationService: AuthenticationService, protected categoryService: CategoryService, protected homeService: HomeService,  private router: Router,) {
    const Auth = this.authenticationService.getAuthFromSession();
    this.authenticationService.currentCart.subscribe( res => {
      this.cartInfo = res;
    });
    if (Auth !== null) {
      this.is_logged = Auth.is_logged;
      this.cartParam.cart_id = Auth.cart_id;
    }
      this.getHome();
    window.scrollTo(0, 0);
    this.closeSideMenu();
    this.HomeSliderItems();
  }

  ngOnInit(): void {

  }

  productCarosule() {
    $('.pp1').owlCarousel({
      loop:true,
      margin: 0,
      nav: true,
      autoplay: true,
      autoplayHoverPause: true,
      items: 4,
      navText: ["<i class='material-icons-outlined md' aria-hidden='true'>keyboard_arrow_left</i>", "<i class='material-icons-outlined md' aria-hidden='true'>keyboard_arrow_right</i>"],
      responsive: {
        0: {
          items: 1,
        },
        450: {
          items: 2,
        },
        599: {
          items: 2,
        },
        768: {
          items: 3,
        },
        991: {
          items: 4,
        },
      }
    });
  }

  productCarosule2() {
    $('.pp2').owlCarousel({
      loop:true,
      margin: 0,
      nav: true,
      autoplay: true,
      autoplayHoverPause: true,
      items: 4,
      navText: ["<i class='material-icons-outlined md' aria-hidden='true'>keyboard_arrow_left</i>", "<i class='material-icons-outlined md' aria-hidden='true'>keyboard_arrow_right</i>"],
      responsive: {
        0: {
          items: 1,
        },
        450: {
          items: 2,
        },
        599: {
          items: 2,
        },
        768: {
          items: 3,
        },
        991: {
          items: 4,
        },
      }
    });
  }

  productCarosule3() {
    $('.pp3').owlCarousel({
      loop:true,
      margin: 0,
      nav: true,
      autoplay: true,
      autoplayHoverPause: true,
      items: 4,
      navText: ["<i class='material-icons-outlined md' aria-hidden='true'>keyboard_arrow_left</i>", "<i class='material-icons-outlined md' aria-hidden='true'>keyboard_arrow_right</i>"],
      responsive: {
        0: {
          items: 1,
        },
        450: {
          items: 2,
        },
        599: {
          items: 2,
        },
        768: {
          items: 3,
        },
        991: {
          items: 4,
        },
      }
    });
  }

  categoryCarosule() {
    this.catOwl =  $('.category-carousel').owlCarousel({
      margin: 0,
      nav: true,
      autoplay: false,
      navText: ["<i class='material-icons-outlined md' aria-hidden='true'>keyboard_arrow_left</i>", "<i class='material-icons-outlined md' aria-hidden='true'>keyboard_arrow_right</i>"],
      responsive: {
        0: {
          items: 1,
          dots: true
        },
        768: {
          items: 1,
          dots: true
        },
        769: {
          items: 4,
          dots: false
        },
      }
    })
  }

  getHome() {
    this.homeService.getHome().subscribe(res => {
      if (res.status === true) {
        this.homeData = res.data;
        this.getBestProductByCategory();
        this.getAllNewProductByCategory();
        setTimeout(() => {
          this.categoryCarosule();
          this.productCarosule3();
        }, 300);
        setTimeout(() => {
          let _this = this;
          this.OWL = $('.full-slider-carousel').owlCarousel({
            margin: 0,
            nav: true,
            autoplay: true,
            items: 1,
            navText: ["<i class='material-icons-outlined md' aria-hidden='true'>keyboard_arrow_left</i>", "<i class='material-icons-outlined md' aria-hidden='true'>keyboard_arrow_right</i>"],
            dotsContainer: '#carousel-custom-dots',
            animateIn: 'fadeIn',
            animateOut: 'fadeOut',
          });
          $('.each-dot-wrapper').click(function () {
            _this.OWL.trigger('to.owl.carousel', [$(this).index(), 300]);
          });
        }, 300)
      }
    })
  }

  makeWishList(product, wish){
    let idx = this.homeData.featured_products.map(e => e.id).indexOf(product.id);
    if(idx > -1){ this.homeData.featured_products[idx].wishlist = wish; }
    idx = this.homeData.promotional_products.map(e => e.id).indexOf(product.id);
    if(idx > -1){ this.homeData.promotional_products[idx].wishlist = wish; }
    idx = this.homeData.popular_products.map(e => e.id).indexOf(product.id);
    if(idx > -1){ this.homeData.popular_products[idx].wishlist = wish; }
    idx = this.AllNewProductData.map(e => e.id).indexOf(product.id);
    if(idx > -1){ this.AllNewProductData[idx].wishlist = wish; }
    idx = this.ProductData.map(e => e.id).indexOf(product.id);
    if(idx > -1){ this.ProductData[idx].wishlist = wish; }
  }

  addTowish(product, i) {
    const productId = product.id;
    if (this.is_logged == true) {
      if (product.wishlist == 0) {
        this.categoryService.apiAddWish(productId).subscribe(res => {
          if (res.status === true) {
            this.makeWishList(product, 1);
          } else {
            $('#login').modal('show');
          }
        });
      } else {
        this.categoryService.apiDeleteWish(productId).subscribe(res => {
          if (res.status === true) {
            this.makeWishList(product, 0);
          } else {
            $('#login').modal('show');
          }
        });
      }
    } else {
      $('#login').modal('show');
    }
  }

  addTowishPromo(product, i) {
    const productId = product.id;
    if (this.is_logged == true) {
      if (product.wishlist == 0) {
        this.categoryService.apiAddWish(productId).subscribe(res => {
          if (res.status === true) {
            this.makeWishList(product, 1);
          } else {
            $('#login').modal('show');
          }
        });
      } else {
        this.categoryService.apiDeleteWish(productId).subscribe(res => {
          if (res.status === true) {
            this.makeWishList(product, 0);
          } else {
            $('#login').modal('show');
          }
        });
      }
    } else {
      $('#login').modal('show');
    }
  }

  addTowishPopular(product, i) {
    const productId = product.id;
    if (this.is_logged == true) {
      if (product.wishlist == 0) {
        this.categoryService.apiAddWish(productId).subscribe(res => {
          if (res.status === true) {
            this.makeWishList(product, 1);
          } else {
            $('#login').modal('show');
          }
        });
      } else {
        this.categoryService.apiDeleteWish(productId).subscribe(res => {
          if (res.status === true) {
            this.makeWishList(product, 0);
          } else {
            $('#login').modal('show');
          }
        });
      }
    } else {
      $('#login').modal('show');
    }
  }

  addTowishAllNew(product, i) {
    const productId = product.id;
    if (this.is_logged == true) {
      if (product.wishlist == 0) {
        this.categoryService.apiAddWish(productId).subscribe(res => {
          if (res.status === true) {
            this.makeWishList(product, 1);
          } else {
            $('#login').modal('show');
          }
        });
      } else {
        this.categoryService.apiDeleteWish(productId).subscribe(res => {
          if (res.status === true) {
            this.makeWishList(product, 0);
          } else {
            $('#login').modal('show');
          }
        });
      }
    } else {
      $('#login').modal('show');
    }
  }

  addTowishBestSeller(product, i) {
    const productId = product.id;
    if (this.is_logged == true) {
      if (product.wishlist == 0) {
        this.categoryService.apiAddWish(productId).subscribe(res => {
          if (res.status === true) {
            this.makeWishList(product, 1);
          } else {
            $('#login').modal('show');
          }
        });
      } else {
        this.categoryService.apiDeleteWish(productId).subscribe(res => {
          if (res.status === true) {
            this.makeWishList(product, 0);
          } else {
            $('#login').modal('show');
          }
        });
      }
    } else {
      $('#login').modal('show');
    }
  }

  addToCart(product, e) {
    let ldd = e.target;
    let ladda = Ladda.create(ldd);
    if (product.type == 'service') {
      if (this.cartInfo != null) {
        if (this.cartInfo.total_items == 0) {
          if (this.is_logged === true) {
            ladda.start();
            this.categoryService.apiAddCart(this.cartParam, product.id).subscribe( res => {
              ladda.stop();
              if (res.status === true && res.is_logged === true) {
                this.getCartDetail();
              } else if (res.status === false) {
                toastr.error(res.message);
                toastr.options.closeButton = true;
                ladda.stop();
              }
            })
          } else {
            $('#login').modal('show');
          }
        } else {
          toastr.error('Please remove item from cart before adding subscription');
          toastr.options.closeButton = true;
        }
      }
    }
    else {
      if (this.is_logged === true) {
        ladda.start();
        this.categoryService.apiAddCart(this.cartParam, product.id).subscribe(res => {
          ladda.stop();
          if (res.status === true && res.is_logged === true) {
            this.getCartDetail();
          }
          if (res.status == false) {
            toastr.error(res.message);
            toastr.options.closeButton = true;
          }
        })
      } else {
        let cart_id = localStorage.getItem('guestCartID');
        cart_id = JSON.parse(cart_id);
        if (cart_id != null) {
          this.cartParam.cart_id = cart_id;
          ladda.start();
          this.categoryService.apiAddCart(this.cartParam, product.id).subscribe(res => {
            ladda.stop();
            if (res.status === true) {
              this.getCartDetail();
            } else {
              toastr.error(res.message);
              toastr.options.closeButton = true;
            }
          })
        } else {
          ladda.start();
          this.categoryService.apiAddCart(this.cartParam, product.id).subscribe(res => {
            ladda.stop();
            if (res.status === true) {
              this.cartParam.cart_id = res.cart_id;
              let cartID_set = JSON.stringify(res.cart_id);
              localStorage.setItem("guestCartID", cartID_set);
              this.getCartDetail();
            } else {
              toastr.error(res.message);
              toastr.options.closeButton = true;
            }
          });
        }
      }
    }


  }

  getBestProductByCategory() {
    this.param.order_by = 'views,desc';
    this.errMsg = '';
    this.categoryService.getProductByCategory(this.catCode, this.param).subscribe(res => {
      if (res.status === true) {
        res.data.forEach((v) => {
          this.ProductData.push(v);
          setTimeout(() => {
            this.productCarosule2();
          }, 300);
        });
      } else {
        this.ProductData = [];
        this.errMsg = res.message
      }
    })
  }

  getAllNewProductByCategory() {
    this.param.order_by = 'id,desc';
    this.errMsg = '';
    this.categoryService.getProductByCategory(this.catCode, this.param).subscribe(res => {
      if (res.status === true) {
        if (this.param.page === 1) {
          this.lastPage = res.page_info.total;
          this.param.page += 1;
          res.data.forEach((v) => {
            this.AllNewProductData.push(v);
            setTimeout(() => {
              this.productCarosule();
            }, 300);
          });
        }
      } else {
        this.errMsg = res.message
      }
    })
  }

  getCartDetail() {
    this.categoryService.apiGetCart(this.cartParam.cart_id).subscribe(res => {
      if (res.status === true) {
        this.authenticationService.updateCart(res.data);
      }
    })
  }

  closeSideMenu() {
    $('.nav-bar-mobile').removeClass('visible');
    $('.hamburger .fa-times').hide();
    $('.hamburger .fa-bars').show();
    $('.display-overflow').hide();
  }

  HomeSliderItems() {
    this.HomeSlide = [];
    if (PageConfig() != null) {
      let HomeSlide = JSON.parse(PageConfig().slider);
      $.each(HomeSlide, (i, v) => {
        if (v.caption !== '') {
          this.HomeSlide.push(v);
        }
      });
      // console.log(HomeSlide);
      setTimeout(() => {
        let _this = this;
        this.OWL = $('.full-slider-carousel').owlCarousel({
          margin: 0,
          nav: true,
          autoplay: true,
          items: 1,
          navText: ["<i class='material-icons-outlined md' aria-hidden='true'>keyboard_arrow_left</i>", "<i class='material-icons-outlined md' aria-hidden='true'>keyboard_arrow_right</i>"],
          dotsContainer: '#carousel-custom-dots',
          animateIn: 'fadeIn',
          animateOut: 'fadeOut',
        });
        $('.each-dot-wrapper').click(function () {
          _this.OWL.trigger('to.owl.carousel', [$(this).index(), 300]);
        });
      }, 1000)
    }
  }
}
