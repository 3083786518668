<div class="-user-settings-wrapper">
  <div class="breadcum-wrapper">
    <div class="breadcrumbs">
      <span>
        <a [routerLink]="['']">Home</a>
        <span class="icon"> > </span>
      </span>
      <span>
        <a [routerLink]="['/user/account-settings']">Settings</a>
        <span class="icon"> > </span>
      </span>
      <span>
        <a [routerLink]="['/user/order']">Order History</a>
        <span class="icon"> > </span>
      </span>
      <span>
        <a href="javascript:void(0)">Order Detail</a>
        <span class="icon">  </span>
      </span>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="form-title">Order Detail</div>

        <div class="loading" *ngIf="Loading == true">
          <div class="blobs">
            <div class="blob-center"></div>
            <div class="blob"></div>
            <div class="blob"></div>
            <div class="blob"></div>
            <div class="blob"></div>
            <div class="blob"></div>
            <div class="blob"></div>
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
            <defs>
              <filter id="goo">
                <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
                <feBlend in="SourceGraphic" in2="goo" />
              </filter>
            </defs>
          </svg>
        </div>
        <div class="row" *ngIf="orderDetail != null">
          <div class="col-sm-6">
            <div class="text-order"> Order:  {{orderDetail.reference_no}}</div>
            <div class="text-order"> {{orderDetail.date | date}}</div>
            <div class="text-order">  Standard Order <span class="status">{{orderDetail.payment_status}}</span></div>
            <div class="text-order" *ngIf="orderDetail.customer_request_status != ''">  Customer request status <span class="status">{{orderDetail.request_status}}</span></div>
            <img style="width: 70px;margin: 10px;" *ngFor="let img of orderDetail.items" src="{{img.image_url}}" class="order-item-img" alt="">
          </div>
          <div class="col-sm-6 text-right">
            <div class="margin-10"></div>
            <button class="btn btn-theme-outline" style=" letter-spacing: 4px; width: 167px;padding: 6px;" *ngIf="orderDetail.can_cancel == 1"
                    (click)="cancelOrderModal(orderDetail.reference_no)">CANCEL ORDER</button>
            <div class="margin-10"></div>
            <button class="btn btn-theme-outline" style=" letter-spacing: 4px; width: 167px;padding: 6px;" *ngIf="orderDetail.can_return == 1"
                    (click)="returnOrderModal(orderDetail.reference_no, orderDetail.items)">RETURN ORDER</button>
            <div class="margin-10"></div>
            <button class="btn btn-theme" style="letter-spacing: 4px" (click)="openCartModal()">ADD TO CART</button>
          </div>
          <div class="divider"></div>
          <div class="margin-30"></div>
          <div class="col-sm-8 offset-2">
            <div class="row">
              <div class="col-sm-12 text-center">
                <div class="text-order">Track your order: <a href="{{orderDetail.tracking_url}}"  target="_blank">  {{orderDetail.reference_no}}</a></div>

              </div>
            </div>
          </div>
          <div class="margin-30"></div>
          <div class="divider"></div>
          <div class="margin-30"></div>
          <div class="col-sm-6" *ngIf="orderDetail.billing_address != null">
            <div class="title-text">SHIPPING INFO</div>
            <div class="text-d" >{{orderDetail.billing_address.first_name}} {{orderDetail.billing_address.last_name}}</div>
            <div class="text-d">  {{orderDetail.billing_address.line1}}</div>
            <div class="text-d"> {{orderDetail.billing_address.line2}}</div>
            <div class="text-d">{{orderDetail.billing_address.city}}, {{orderDetail.billing_address.postal_code}}</div>
            <div class="text-d">{{orderDetail.billing_address.phone}}</div>

          </div>
          <div class="col-sm-6">
            <div class="title-text">PAYMENT INFO</div>
            <div class="text-d">Payment Method: <span style="text-transform: capitalize">{{orderDetail.payment_method}}</span></div>
            <div class="text-d">Payment Status: <span style="text-transform: capitalize">{{orderDetail.payment_status}}</span></div>
           <!-- <div class="text-d"> Siddharth Mahajan</div>
            <div class="text-d">  13234 Telecom Drive</div>
            <div class="text-d"> Suite 108</div>
            <div class="text-d">Tampa, FL 33637</div>-->
          </div>
          <div class="margin-30"></div>
          <div class="divider"></div>
          <div class="margin-30"></div>
          <div class="col-sm-8">
            <div class="order-box">
              <div class="ordered-item-head" (click)="toggleProductOrdered()">
                <h3>YOUR a1da ORDER  <small class="small-text">({{orderDetail.total_items}} items)</small></h3>
                <i class="fa fa-angle-up ilo"  style="display: none"></i>
                <i class="fa fa-angle-down ilo"></i>
              </div>
              <div class="product-list or-pro">
                <div class="title">Items shipped by A1daam</div>
                <ul class="product-wrapper">
                  <li class="each-product" *ngFor="let each of orderDetail.items">
                    <div class="product-ordered">
                      <!-- <div class="sale"  *ngIf="each.discount_price > 0">  SALE</div>-->
                      <div class="img-sec">
                        <img src="{{each.image_url}}" alt="">
                      </div>
                      <div class="text-sec">
                        <div class="text-up">
                          <div class="brand-name">{{each.product_name}}</div>
                          <div class="quantity">
                            <!--                        <span>12 x 16 oz</span>-->
                          </div>
                        </div>
                        <div class="item-orderd-text">Qty: {{each.product_unit_quantity}}</div>
                      </div>
                      <div class="price-text">
                        <div class="text-q">
                          <div class="original-price">{{each.formatted_unit_price}}</div>
                          <!--                      <div class="old-price">$18.69</div>-->
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="inside-box">
              <div class="text-summery">
                Your Order Summary
              </div>
              <table class="order-table">
                <tr>
                  <th>Total</th>
                  <td>{{orderDetail.formatted_total}}</td>
                </tr>
                <tr>
                  <th>Shipping Costs</th>
                  <td>{{orderDetail.formatted_shipping}}</td>
                </tr>
                <tr>
                  <th>Discount</th>
                  <td>{{orderDetail.formatted_total_discount}}</td>
                </tr>
                <tr>
                  <th>Total Item Tax</th>
                  <td>{{orderDetail.formatted_total_tax}}</td>
                </tr>
                <tr>
                  <th class="text-total">ORDER TOTAL</th>
                  <td class="text-total">{{orderDetail.formatted_grand_total}}</td>
                </tr>
              </table>
              <div class="order-text">
                <span>Order Total</span>
                <span>{{orderDetail.formatted_grand_total}}</span>
              </div>
            </div>
            <div class="margin-30"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="delete_add" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header" style="padding-top: 0; padding-bottom: 0">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><img width="20px" src="{{ENV.ASSET_URL}}/icons/close.png" alt=""></span>
        </button>
      </div>
      <div class="modal-body">
        <div>Cancel Order?</div>
        <div class="margin-10"></div>
        <div class="text-muted text-danger"> Are you sure you want to Cancel this order?</div>
        <div class="margin-10"></div>
        <div class="form-group">
          <textarea  rows="5" placeholder="Cancel Reason" [(ngModel)]="cancelReason" class="form-control"></textarea>
        </div>
        <div class="margin-30"></div>
        <button data-dismiss="modal" aria-label="Close" class="btn btn-theme-outline w-100">Don't Cancel </button>
        <div class="margin-10"></div>
        <button *ngIf="loading === false" type="submit" class="btn btn-theme w-100" (click)="cancelOrder()">Cancel Order</button>
        <button *ngIf="loading === true" disabled type="button" class="btn btn-theme w-100">Cancel Order <i class="fa fa-spinner fa-spin"></i></button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="return_add" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header" style="padding-top: 0; padding-bottom: 0">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><img width="20px" src="{{ENV.ASSET_URL}}/icons/close.png" alt=""></span>
        </button>
      </div>
      <div class="modal-body">
        <div>Return Order?</div>
        <div class="margin-10"></div>
        <div class="text-muted text-danger"> Are you sure you want to Return this order?</div>
        <div class="margin-10"></div>
        <div class="form-group">
          <textarea  rows="5" placeholder="Return Reason" [(ngModel)]="returnReason" class="form-control"></textarea>
        </div>
        <div class="margin-30"></div>
        <button data-dismiss="modal" aria-label="Close" class="btn btn-theme-outline w-100">Don't Return</button>
        <div class="margin-10"></div>
        <button *ngIf="loading === false" type="submit" class="btn btn-theme w-100" (click)="returnOrder()">Return Order</button>
        <button *ngIf="loading === true" disabled type="button" class="btn btn-theme w-100">Cancel Order <i class="fa fa-spinner fa-spin"></i></button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="cart_add" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header" style="padding-top: 0; padding-bottom: 0">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><img width="20px" src="{{ENV.ASSET_URL}}/icons/close.png" alt=""></span>
        </button>
      </div>
      <div class="modal-body" *ngIf="orderDetail != null">
        <div class="row">
          <div class="col-lg-3"  *ngFor="let each of orderDetail.items">
            <div class="modal-product">
              <div class="img">
                <img width="100%" height="200px" src="{{each.image_url}}" alt="">
              </div>
              <div class="name">{{each.product_name}}</div>
              <div class="margin-10"></div>
              <div class="price-modal">{{each.formatted_unit_price}}</div>
              <div class="btn btn-outline-secondary add-cart-btn-modal" *ngIf="!each.hide" (click)="addToCart(each, $event)"> ADD</div>
	      <div class="btn btn-outline-secondary add-cart-btn-modal" *ngIf="each.hide" > Out Of Stock</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
