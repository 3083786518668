import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import {environment} from '../../environments/environment';
import {CategoryService} from '../services/category.service';
import {AuthenticationService} from '../services/authentication.service';
declare const $: any;
declare let toastr: any;
declare let Ladda: any;

@Component({
  selector: 'app-search-products',
  templateUrl: './SearchProducts.component.html',
})
export class SearchProductsComponent implements OnInit {

  ENV = environment;
  menuOpen = false;
  is_logged = false;
  searchData: any = [];
  param = {
    max_price: '',
    min_price: '',
    brand_code: '',
    order_by: '',
    page: 1,
    search: '',
  };
  cartParam: any = {
    quantity: 1,
    option: 2,
    cart_id: '',
  };

  lastPage: any = '';
  Loading = false;

  constructor(private router: Router, protected categoryService : CategoryService, protected authenticationService : AuthenticationService) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const catCodeArr = event.url.split('/');
        const catCode = catCodeArr[catCodeArr.length - 1];
        this.param.search = catCode;
        const Auth = this.authenticationService.getAuthFromSession();
        if (Auth !== null) {
          this.is_logged = Auth.is_logged;
          this.cartParam.cart_id = Auth.cart_id;
        }
        this.param.page = 1;
        this.searchData = [];
        this.searchProduct();
      }
    });

    window.scrollTo(0, 0);
    this.closeSideMenu();
  }

  addToCart(product, e) {
    let ldd = e.target;
    let ladda = Ladda.create(ldd);
    if (this.is_logged === true) {
      ladda.start();
      this.categoryService.apiAddCart(this.cartParam, product.id).subscribe( res => {
        ladda.stop();
        if (res.status === true && res.is_logged === true) {
          this.getCartDetail();
        } else if (res.status === false) {
          toastr.error(res.message);
          toastr.options.closeButton = true;
          ladda.stop();
        }
      })
    } else {
      let cart_id = localStorage.getItem('guestCartID');
      cart_id = JSON.parse(cart_id);
      if (cart_id != null) {
        this.cartParam.cart_id = cart_id;
        ladda.start();
        this.categoryService.apiAddCart(this.cartParam, product.id).subscribe( res => {
          ladda.stop();
          if (res.status === true  ) {
            this.getCartDetail();
          } else {
            toastr.error(res.message);
            toastr.options.closeButton = true;
          }
        })
      } else {
        ladda.start();
        this.categoryService.apiAddCart(this.cartParam, product.id).subscribe( res => {
          ladda.stop();
          if (res.status === true  ) {
            this.cartParam.cart_id = res.cart_id;
            let cartID_set = JSON.stringify(res.cart_id);
            localStorage.setItem("guestCartID", cartID_set);
            this.getCartDetail();
          } else {
            toastr.error(res.message);
            toastr.options.closeButton = true;
          }
        });
      }
    }
  }


  getCartDetail() {
    this.categoryService.apiGetCart(this.cartParam.cart_id).subscribe( res => {
      if (res.status === true) {
        this.authenticationService.updateCart(res.data);
      }
    })
  }

  searchProduct() {
    this.Loading = true;
    this.categoryService.getProductByCategory('',this.param).subscribe( res => {
      if (res.status === true) {
        res.data.forEach((v) => {
          this.searchData.push(v);
        });
        this.Loading = false;
        this.lastPage = res.page_info.total;
      } else {
        this.searchData = [];
        this.Loading = false;
      }
    });
  }

  addTowish(product, i) {
    if (this.is_logged == true) {
      if (product.wishlist == 0) {
        this.categoryService.apiAddWish(product.id).subscribe( res => {
          if (res.status === true) {
            this.searchData[i].wishlist = 1;
          } else {
            $('#login').modal('show');
          }
        });
      } else {
        this.categoryService.apiDeleteWish(product.id).subscribe( res => {
          if (res.status === true) {
            this.searchData[i].wishlist = 0;
          }  else {
            $('#login').modal('show');
          }
        });
      }
    }  else {
      $('#login').modal('show');
    }
  }

  loadMore() {
    this.param.page += 1;
    this.searchProduct()
  }

  ngOnInit(): void {

  }

  closeSideMenu() {
    $('.nav-bar-mobile').removeClass('visible');
    $('.hamburger .fa-times').hide();
    $('.hamburger .fa-bars').show();
    $('.display-overflow').hide();
    this.menuOpen = false;
  }
}
