import { Component, OnInit } from '@angular/core';
import {environment} from '../../../environments/environment';
import {AuthenticationService} from '../../services/authentication.service';
import {Router} from '@angular/router';
declare const $: any;
declare let toastr: any;

@Component({
  selector: 'app-change-password',
  templateUrl: './ChangePassword.component.html'
})
export class ChangePasswordComponent implements OnInit {

  ENV = environment;
  menuOpen = false;
  is_logged = false;
  inputType = 'password';
  param: any = {
    old_password: '',
    new_password: '',
    new_password_confirm: '',
  };
  loading = false;
  constructor( protected authenticationService : AuthenticationService,  private router: Router) {
    const Auth = this.authenticationService.getAuthFromSession();
    if (Auth !== null) {
      this.is_logged = Auth.is_logged;
    } else {
      this.router.navigate(['']);
      setTimeout(function () {
        $('#register').modal('show');
      },100)
    }
    window.scrollTo(0, 0);
    this.closeSideMenu();
  }
  ngOnInit(): void {}

  showPass() {
    if (this.inputType == 'password') {
      this.inputType = 'text'
    } else {
      this.inputType = 'password'
    }
  }
  updatePassword() {
    this.loading = true;
    this.authenticationService.apiUpdatePassword(this.param).subscribe( res => {
      if (res.status === true) {
        toastr.success(res.message);
        toastr.options.closeButton = true;
        this.router.navigate(['/user/account-settings']);
        this.loading = false;
      } else {
        $('[name="old_password"]').closest('.form-group').find('.invalid-feedback').html(res.message);
        $('[name="old_password"]').closest('.form-group').find('.form-control').addClass('is-invalid');
        this.loading = false;
      }
    });
  }

  closeSideMenu() {
    $('.nav-bar-mobile').removeClass('visible');
    $('.hamburger .fa-times').hide();
    $('.hamburger .fa-bars').show();
    $('.display-overflow').hide();
    this.menuOpen = false;
  }
}

