import {Inject, Injectable, Optional} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent} from '@angular/common/http';
import {Observable, BehaviorSubject} from 'rxjs';
import {AuthenticationService} from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  protected basePath = environment.API_URL;
  protected token = '';
  public defaultHeaders = new HttpHeaders();

  constructor(protected httpClient: HttpClient, protected authenticationService: AuthenticationService) {
    const user = this.getAuthFromSession();
    if(user !== null){
      this.token = user.token;
    }

    this.authenticationService.currentUser.subscribe(user => {
      if(user !== null){
        this.token = user.token;
      }
    });

  }

  getAuthFromSession() {
    let userData = null;
    userData = localStorage.getItem('userData');
    return JSON.parse(userData) ;
  }

  public getHome(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);
    headers = headers.set('X-token', this.token);
    return this.httpClient.get(`${this.basePath}/products/home`, { headers: headers });
  }

  public getStaticInfo(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let headers = this.defaultHeaders;
    headers = headers.set('X-Api-Key', environment.XApiKey);
    headers = headers.set('X-token', this.token);
    return this.httpClient.get(`${this.basePath}/pages/static_info`, { headers: headers });
  }
}
