import { Component, OnInit } from '@angular/core';
import {environment} from "../../environments/environment";
import {AuthenticationService} from '../services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';

declare const $: any;
declare let toastr: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  ENV = environment;
  menuOpen = false;
  inputType = 'password';
  loading = false;
  loginData: any = {
    identity: '',
    password: '',
  };
  mailData: any = {
    email: '',
  };
  constructor(protected authenticationService : AuthenticationService, private router: Router, private route: ActivatedRoute) {
    const Auth = this.authenticationService.getAuthFromSession();
    if (Auth !== null) {
      this.router.navigate(['']);
    }
    window.scrollTo(0, 0);
    this.closeSideMenu();

  }

  showPass() {
    if (this.inputType == 'password') {
      this.inputType = 'text'
    } else {
      this.inputType = 'password'
    }
  }

  login() {
    this.loading = true;
    this.authenticationService.apiLogin(this.loginData).subscribe( res => {
      if (res.status === true) {
        const userData = JSON.stringify(res);
        this.authenticationService.updateUser(res);
        localStorage.setItem("userData", userData);
        this.router.navigate(['']);
      } else {
        this.loading = false;
        $('[name="identity"]').closest('.form-group').find('.invalid-feedback').html(res.message);
        $('[name="identity"]').closest('.form-group').find('.form-control').addClass('is-invalid');
      }
    })
  }

  enterEmail() {
    this.loading = true;
    this.authenticationService.apiEnterEmail(this.mailData).subscribe( res => {
      if (res.status === true){

      } else {
        this.loading = false;
        $('[name="Forgotemail"]').closest('.form-group').find('.invalid-feedback').html(res.message);
        $('[name="Forgotemail"]').closest('.form-group').find('.form-control').addClass('is-invalid');
      }
    })
  }


  showForgotModal(){
    $('#forgot').modal('show');
  }

  ngOnInit(): void {
  }


  closeSideMenu() {
    $('.nav-bar-mobile').removeClass('visible');
    $('.hamburger .fa-times').hide();
    $('.hamburger .fa-bars').show();
    $('.display-overflow').hide();
    this.menuOpen = false;
  }

}
