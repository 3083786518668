import { Component, OnInit } from '@angular/core';
import {environment} from '../../../environments/environment';
import {AuthenticationService} from "../../services/authentication.service";
import {ActivatedRoute, Router} from "@angular/router";
import {CategoryService} from "../../services/category.service";
declare const $: any;
declare const toastr: any;
declare let Ladda: any;

@Component({
  selector: 'app-add-order-detail',
  templateUrl: './order-detail.component.html'
})
export class OrderDetailComponent implements OnInit {

  ENV = environment;
  menuOpen = false;
  is_logged = false;
  loading = false;
  Loading = false;
  reference = '';
  cancelRef = '';
  returnRef = '';
  returnItems = [];
  cancelReason = '';
  returnReason = '';
  orderDetail: any = null;
  cartParam: any = {
    quantity: 1,
    option: 2,
    cart_id: '',
  };
  constructor(protected authenticationService : AuthenticationService,  protected categoryService : CategoryService,  private router: Router, private route: ActivatedRoute) {
    this.reference = this.route.snapshot.paramMap.get('id');
    const Auth = this.authenticationService.getAuthFromSession();
    if (Auth !== null) {
      this.getOrderDetail();
      this.is_logged = Auth.is_logged;
      this.cartParam.cart_id = Auth.cart_id;
    } else {
      this.router.navigate(['']);
      setTimeout(function () {
        $('#register').modal('show');
      },100)
    }
    window.scrollTo(0, 0);
    this.closeSideMenu();
  }
  ngOnInit(): void {}

  getOrderDetail() {
    this.Loading = true;
    this.authenticationService.getOrderDetail(this.reference).subscribe(res => {
      this.Loading = false;
      this.orderDetail = res;
    })
  }
  cancelOrderModal(ref) {
    this.cancelRef = ref;
    $('#delete_add').modal('show');
  }
  returnOrderModal(ref, items) {
    this.returnRef = ref;
    this.returnItems = items;
    $('#return_add').modal('show');
  }
  cancelOrder() {
    let cancelData = {
      reference: this.cancelRef,
      cancel_reason: this.cancelReason
    }
    this.authenticationService.cancelOrder(cancelData).subscribe(res => {
      if (res.status === true) {
        $('#delete_add').modal('hide');
        toastr.success(res.message);
        toastr.options.closeButton = true;
        this.router.navigate(['/user/order']);
      } else {
        toastr.error(res.message);
        toastr.options.closeButton = true;
      }

    })
  }
  returnOrder() {
    let returnData = {
      reference: this.returnRef,
      cancel_reason: this.returnReason,
      items: this.returnItems
    }
    this.authenticationService.returnOrder(returnData).subscribe(res => {
      $('#return_add').modal('hide');
      if (res.status === true) {
        toastr.success('Order requested successfully submitted');
        toastr.options.closeButton = true;
        this.router.navigate(['/user/order']);
      } else {
        toastr.error(res.message);
        toastr.options.closeButton = true;
      }
    })
  }
  openCartModal () {
    $('#cart_add').modal('show');
  }
  addToCart(product, e) {
    let ldd = e.target;
    let ladda = Ladda.create(ldd);
    if (this.is_logged === true) {
      ladda.start();
      this.categoryService.apiAddCart(this.cartParam, product.product_id).subscribe( res => {
        if (res.status === true && res.is_logged === true) {
          this.getCartDetail();
          ladda.stop();
        } else if (res.status === false) {
          toastr.error(res.message);
          toastr.options.closeButton = true;
          ladda.stop();
        }
      })
    } else {
      let cart_id = localStorage.getItem('guestCartID');
      cart_id = JSON.parse(cart_id);
      if (cart_id != null) {
        this.cartParam.cart_id = cart_id;
        ladda.start();
        this.categoryService.apiAddCart(this.cartParam, product.product_id).subscribe( res => {
          ladda.stop();
          if (res.status === true ) {
            this.getCartDetail();
          } else {
            toastr.error(res.message);
            toastr.options.closeButton = true;
          }
        })
      } else {
        ladda.start();
        this.categoryService.apiAddCart(this.cartParam, product.product_id).subscribe( res => {
          ladda.stop();
          if (res.status === true  ) {
            this.cartParam.cart_id = res.cart_id;
            let cartID_set = JSON.stringify(res.cart_id);
            localStorage.setItem("guestCartID", cartID_set);
            this.getCartDetail();
          } else {
            toastr.error(res.message);
            toastr.options.closeButton = true;
          }
        });
      }
    }
  }
  getCartDetail() {
    this.categoryService.apiGetCart(this.cartParam.cart_id).subscribe( res => {
      if (res.status === true) {
        this.authenticationService.updateCart(res.data);
      }
    })
  }

  toggleProductOrdered() {
    $('.or-pro').toggle()
    $('.ilo').toggle()
  }

  closeSideMenu() {
    $('.nav-bar-mobile').removeClass('visible');
    $('.hamburger .fa-times').hide();
    $('.hamburger .fa-bars').show();
    $('.display-overflow').hide();
    this.menuOpen = false;
  }
}
