import { Component, OnInit } from '@angular/core';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../services/authentication.service';
declare const $: any;
declare let toastr: any;

@Component({
  selector: 'app-Account-component',
  templateUrl: './Account.component.html'
})
export class AccountComponent implements OnInit {

  ENV = environment;
  menuOpen = false;
  is_logged = false;
  authData: any = null;
  countryData: any = null;
  stateData: any = null;
  addressData: any = [];
  paymentData: any = [];
  orderData: any = [];
  param: any = {};
  loading = false;
  totalOrder: any = '';

  constructor(protected authenticationService : AuthenticationService,  private router: Router) {
    const Auth = this.authenticationService.getAuthFromSession();
    if (Auth !== null) {
      this.getUser();
      this.getCountry();
      this.getAddress();
      this.getPayment();
      this.getOrder();
      this.is_logged = Auth.is_logged;
    } else {
      this.router.navigate(['']);
      setTimeout(function () {
        $('#register').modal('show');
      },100)
    }
    window.scrollTo(0, 0);
    this.closeSideMenu();
  }

  getAddress() {
    this.authenticationService.getAddress().subscribe(res => {
      if (res.status === true) {
        this.addressData = res.data;
      }
    })
  }
  getPayment() {
    this.authenticationService.getPayment().subscribe(res => {
      if (res.status === true) {
        this.paymentData = res.data;
      }
    })
  }
  getUser() {
    this.authenticationService.getUser().subscribe(res => {
      if (res.status === true) {
        this.authData = res.data;
        this.param = res.data;
      }
    })
  }
  updateUser() {
    this.loading = true;
    this.authenticationService.apiUpdateProfile(this.param).subscribe( res => {
      if (res.status === true) {
        $('#exampleModal').modal('hide');
        this.getUser();
        this.loading = false;
      }
    })
  }

  getOrder() {
    this.authenticationService.getOrder(1).subscribe(res => {
      if (res.is_logged === true) {
        this.orderData =  res.data;
        this.totalOrder =  res.total;
      }
    })
  }
  getCountry() {
    this.authenticationService.getCountry().subscribe(res => {
      if (res.status === true) {
        this.countryData = res.data;
      }
    })
  }
  getState() {
    this.authenticationService.getState(this.param.country).subscribe(res => {
      if (res.status === true) {
        this.stateData = res.data;
      }
    })
  }
  ngOnInit(): void {}
  closeSideMenu() {
    $('.nav-bar-mobile').removeClass('visible');
    $('.hamburger .fa-times').hide();
    $('.hamburger .fa-bars').show();
    $('.display-overflow').hide();
    this.menuOpen = false;
  }
}
