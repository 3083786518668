import { Component, OnInit } from '@angular/core';
import {environment} from '../../environments/environment';
import {CategoryService} from '../services/category.service';
import {HomeService} from '../services/home.service';
import {AuthenticationService} from '../services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SiteConfig} from "../Helpers/config";
import { Observable } from 'rxjs';
import { interval } from 'rxjs';

declare let toastr: any;
declare const $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
ENV = environment;
 logo_slug: any = '';
  pageSettings: any = {
    description: '',
    email: '',
    phone: '',
  };
  UserDetails: any = {
    name: '',
    email: '',
  };
  menuOpen = false;
  cartOpen = false;
  is_logged = false;
  inputType = 'password';
  loading = false;
  loadingR = false;
  checkout_page = false;
  loginData: any = {
    identity: '',
    password: '',
    cart_id: '',
    from_api: true
  };
  regInfo: any = {
    first_name: '',
    last_name: '',
    username: '',
    email: '',
    phone: '',
    password: '',
    password_confirm: '',
    type: 'mobile',
    other_type: 'email',
    from_api: true
  };
  registration_step = 'generate_otp';
  existing_account = false;
  is_otp_validated = false;
  default_resend_time = 60;
  resend_otp_after = 0;
  otp = '';
  resend_sub: any = '';
  check_auth_sub: any = '';
  sms_send_status = true;


  mailData: any = {
    email: '',
  };
  categoriesData: any = null;
  searchParam: any = {
    max_price: '',
    min_price: '',
    brand_code: '',
    order_by: '',
    page : 1,
    search : '',
  };
  cartParam: any = {
    quantity: '',
    option: '',
    cart_id: '',
  };
  topHeaderTitle: '';
  searchData: any = null;
  errMsg: any = '';
  cartInfo: any = null;
  notificationData: any = null;
  keywordInterval: any = null;

  accType: any = 1;
  constructor(protected categoryService: CategoryService, protected homeService: HomeService, protected authenticationService: AuthenticationService,  private router: Router, private route: ActivatedRoute) {
	this.resend_otp_after = this.default_resend_time;
    this.authenticationService.currentCart.subscribe( res => {
      this.cartInfo = res;
      this.showCart();
      const Auth = this.authenticationService.getAuthFromSession();
      if (Auth !== null) {
        this.is_logged = Auth.is_logged;
	this.cartParam.cart_id = Auth.cart_id;
      } else {
        let cart_id = localStorage.getItem('guestCartID');
        cart_id = JSON.parse(cart_id);
        if ( cart_id != null) {
          this.cartParam.cart_id = cart_id;
          this.loginData.cart_id = cart_id;
        }
      }
    });
    const Auth = this.authenticationService.getAuthFromSession();
    if (Auth !== null) {
      this.is_logged = Auth.is_logged;
      this.cartParam.cart_id = Auth.cart_id;
      // this.getNotification();
    } else {
      let cart_id = localStorage.getItem('guestCartID');
      cart_id = JSON.parse(cart_id);
      if ( cart_id != null) {
        this.cartParam.cart_id = cart_id;
        this.loginData.cart_id = cart_id;
      }
    }
    this.getStaticInfo();
    this.getCategory();
    this.getCartDetailInit();
    this.getBrand();
    this.SiteConfigHeader();
    this.GetAuthDetails();
  }


  ngOnInit(): void {
    $('.cart-carousel').owlCarousel({
      responsiveClass:true,
      loop: true,
      animateIn: 'fadeIn', // add this
      animateOut: 'fadeOut', // and this
      responsive: {
        0:{
          items:1,
          nav:false,
          stagePadding: 40
        },
        600:{
          items:1,
          nav:true
        }
      }
    });
    this.initDropDown();
    this.initDropDownNoti();


	if(this.check_auth_sub !== '') {
		this.check_auth_sub.unsubscribe();
		this.check_auth_sub = '';
	}
	this.checkAuthDetails();
	this.check_auth_sub = interval(30 * 60 * 1000).subscribe((val) => { this.checkAuthDetails() });

    $('body').on('click', () => {
      let currentTime = ((new Date()).getTime());
      let is_logged_time = localStorage.getItem('is_logged_time') == null ? '0' : localStorage.getItem('is_logged_time');
      if(is_logged_time !== '0'){
        let msDifference =  currentTime - parseInt(is_logged_time);
        let minutes = Math.floor(msDifference/1000/60);
        if(minutes >= 5){
          this.checkAuthDetails();
        } else {

        }
      }
    })
  }

  ngOnDestroy() {
	if(this.resend_sub != '') {
		this.resend_sub.unsubscribe();
		this.resend_sub = '';
	}

	if(this.check_auth_sub != '') {
		this.check_auth_sub.unsubscribe();
		this.check_auth_sub = '';
	}

  }

  getStaticInfo() {
    this.homeService.getStaticInfo().subscribe(res => {
      if(res.data.length > 0){
        this.topHeaderTitle = res.data[0].description.description;
      }
    })
  }

  generateOtp() {
	this.loadingR = true;

	let otp_data = {type: this.regInfo.type, identity: ''};
	if(this.regInfo.type == 'mobile') {
		otp_data.identity = this.regInfo.phone;
 		this.regInfo.other_type = 'email';
	} else {
		otp_data.identity = this.regInfo.email;
		this.regInfo.other_type = 'mobile';
	}

    	this.authenticationService.generateOtp(otp_data).subscribe( res => {
	if (res.status === true) {
		this.otp = res.otp;
		this.existing_account = res.existing_account;
		this.registration_step = 'validate_otp';
		this.loadingR = false;
		this.resend_otp_after = this.default_resend_time;

	if(this.resend_sub !== '') {
		this.resend_sub.unsubscribe();
		this.resend_sub = '';
	}
	this.resend_sub = interval(1000).subscribe((val) => { this.resend_otp_after -= 1; });

      	} else {
		this.loadingR = false;
		this.sms_send_status = false;
		if(this.regInfo.type == 'mobile') {
			$('[name="phone"]').closest('.form-group').find('.invalid-feedback').html(res.message);
			$('[name="phone"]').closest('.form-group').find('.form-control').addClass('is-invalid');
		} else {
			$('[name="email"]').closest('.form-group').find('.invalid-feedback').html(res.message);
			$('[name="email"]').closest('.form-group').find('.form-control').addClass('is-invalid');
		}
      	}
    })

  }

  resetRegisterModal() {
	this.otp = '';
	this.existing_account = false;
	this.registration_step = 'generate_otp';
	this.loadingR = false;
	this.loading = false;
  }

  toggleOtpType() {
	this.otp = '';
	this.existing_account = false;
	this.registration_step = 'generate_otp';

	if(this.regInfo.type == 'mobile') {
		this.regInfo.type = 'email';
		this.regInfo.other_type = 'mobile';
	} else {
		this.regInfo.type = 'mobile';
		this.regInfo.other_type = 'email';
	}
	this.loadingR = false;
  }

  validateOtp() {
	if(this.regInfo.password == this.otp) {
		if(this.existing_account) {
			console.log("Before");
			this.login();
		} else {
			this.registration_step = "additional_info";
		}
	} else {
		$('[name="password"]').closest('.form-group').find('.invalid-feedback').html("OTP invalid");
		$('[name="password"]').closest('.form-group').find('.form-control').addClass('is-invalid');
	}
  }

  optionName(data) {
    let name = ''
    if (data.options != null && data.option != false) {
      data.options.forEach((k) => {
        if (k.id ==  data.option.toString()) {
          name = k.name;
        }
      })
    } else {
      name = '';
    }
    return name;
  }

  gotoCheckout() {
    this.checkout_page = true;
    if (this.is_logged == true) {
      this.router.navigate(['/secure/checkout']);
    } else {
      $('#login').modal('show');
    }
  }

  getCartDetail() {
    this.categoryService.apiGetCart(this.cartParam.cart_id).subscribe( res => {
      if (res.status === true) {
        this.cartInfo = res.data;
        this.authenticationService.updateCart(res.data);
      }
    })
  }
  getCartDetailInit() {
    this.categoryService.apiGetCart(this.cartParam.cart_id).subscribe( res => {
      if (res.status === true) {
        this.cartInfo = res.data;
      }
    })
  }

  plusItem(product, e) {
    let loading = $(e.target).closest('.count').find('.qq');
    let count = $(e.target).closest('.count').find('.gg');
    loading.show();
    count.hide()
    this.cartParam.quantity = 1;
    this.cartParam.option = product.option;

    this.categoryService.apiAddCart(this.cartParam, product.product_id).subscribe( res => {
      loading.hide();
      count.show()
      if (res.status === true) {
        this.getCartDetail();
      } else {
        toastr.error(res.message);
        toastr.options.closeButton = true;
      }
    })
  }

  minusItem(product, e) {
    if(product.qty > 1){
      let loading = $(e.target).closest('.count').find('.qq');
      let count = $(e.target).closest('.count').find('.gg');
      loading.show();
      count.hide()
      this.cartParam.quantity = -1;
      this.cartParam.option = product.option;

      this.categoryService.apiAddCart(this.cartParam, product.product_id).subscribe( res => {
        loading.hide();
        count.show()
        if (res.status === true) {
          this.getCartDetail();
        } else {
          toastr.error(res.message);
          toastr.options.closeButton = true;
        }
      })
    }
  }

  removeItem(rowID, e) {
    let loading = $(e.target).find('.fa-spin');
    loading.show();
    this.categoryService.apiRemovaCart(this.cartParam.cart_id, rowID).subscribe( res => {
      loading.hide();
      if (res.status === true) {
        this.getCartDetail();
      }
    })
  }

  logOut() {
    this.authenticationService.apiLogout().subscribe( res => {
      if (res.status === true) {
        localStorage.removeItem("userData");
        delete localStorage.is_logged_time;
        this.router.navigate(['']);
        this.is_logged = false;
	toastr.success(res.message);
        toastr.options.closeButton = true;
	setTimeout(() => {
          location.reload()
	}, 500);
      }
    })
  }

  logOutNoReload() {
    this.authenticationService.apiLogout().subscribe( res => {
      if (res.status === true) {
        localStorage.removeItem("userData");
        delete localStorage.is_logged_time;
        this.is_logged = false;
      }
    })
  }

  showPass() {
    if (this.inputType == 'password') {
      this.inputType = 'text'
    } else {
      this.inputType = 'password'
    }
  }

  login() {
    this.loading = true;
	this.loadingR = true;

	if(this.regInfo.type == 'mobile') {
	this.loginData.identity = this.regInfo.phone;
	} else {
		this.loginData.identity = this.regInfo.email;
	}
	this.loginData.password = this.regInfo.password;

    this.authenticationService.apiLogin(this.loginData).subscribe( res => {
      if (res.status === true) {
	const userData = JSON.stringify(res);
	this.authenticationService.updateUser(res);
	localStorage.setItem("userData", userData);
	this.router.navigate(['']);
	$('#login').modal('hide');
	this.loading = false;
	this.loadingR = false;
	this.is_logged = true;
	localStorage.setItem("is_logged_time", ((new Date()).getTime()).toString());
	toastr.success(res.message);
	toastr.options.closeButton = true;
	if(this.checkout_page) {
		setTimeout(() => {
		this.router.navigate(['/secure/checkout']);
		}, 500);
	} else {
	setTimeout(() => {
	  location.reload()
	}, 500);
	}
      } else {
	this.loading = false;
	this.loadingR = false;
        $('[name="first_name"]').closest('.form-group').find('.invalid-feedback').html(res.message);
        $('[name="first_name"]').closest('.form-group').find('.form-control').addClass('is-invalid');
      }
    })
  }

  register() {
    this.loadingR = true;
    this.authenticationService.apiRegister(this.regInfo).subscribe( res => {
      if (res.status === true) {
        toastr.success(res.message);
	toastr.options.closeButton = true;
    	this.loadingR = false;
	this.login();
      } else {
        this.ErrorHandaler(res.message);
        this.loadingR = false;
      }
    })
  }

  ErrorHandaler(errors) {
    $.each(errors, function (i, v) {
      $('[name=' + i + ']').closest('.form-group').find('.form-control').addClass('is-invalid');
      $('[name=' + i + ']').closest('.form-group').find('.invalid-feedback').html(v);
	if(i == 'username') {
      		$('[name="phone"]').closest('.form-group').find('.form-control').addClass('is-invalid');
      		$('[name="phone"]').closest('.form-group').find('.invalid-feedback').html(v);
	}
    })
  }

  enterEmail() {
    this.loading = true;
    this.authenticationService.apiEnterEmail(this.mailData).subscribe( res => {
      if (res.status === true){
        this.loading = false;
        this.errMsg = res.message;
      } else {
        this.loading = false;
        $('[name="Forgotemail"]').closest('.form-group').find('.invalid-feedback').html(res.message);
        $('[name="Forgotemail"]').closest('.form-group').find('.form-control').addClass('is-invalid');
      }
    })
  }

  getCategory() {
    this.categoryService.getAllCategories().subscribe( res => {
      this.categoriesData = res.data;
      this.categoryService.updateCategory(res.data);
    });
  }

  getBrand() {
    this.categoryService.getAllBrands().subscribe( res => {
      this.categoryService.updateBrand(res.data);
    });
  }

  getNotification() {
    this.categoryService.getAllNotification().subscribe( res => {
      if (res.status === true) {
        this.notificationData = res.data;
      }
    });
  }

  searchProduct() {
    this.loading = true;
    let _this = this;
    let timeout = 500;
    if(_this.keywordInterval != null){
      clearInterval(_this.keywordInterval);
    }
    this.keywordInterval = setInterval(() => {
      this.searchData = null;
      $('.searchResult').addClass('openAll');
      if (this.searchParam.search != '') {
        this.categoryService.getProductByCategory('',this.searchParam).subscribe( res => {
          this.loading = false;
          if (res.status === true) {
            if (res.data.length > 5) {
              res.data.length = 5;
            }
            this.searchData = res.data;
          } else {
            this.searchData = null;
          }
        });
      } else {
        this.searchData = null;
        this.loading = false;
      }
      clearInterval(_this.keywordInterval);
    }, timeout);

  }

  SearchmodalHide() {
    $('#search').modal('hide');
  }

  onClickedOutside() {
    $('.nav-bar-mobile').removeClass('visible');
    $('.hamburger .fa-times').hide();
    $('.hamburger .fa-bars').show();
    $('.display-overflow').hide();

  }

/*  onClickedOutsideCart() {
    if (this.cartOpen === true) {
      $('.cart-wrapper').removeClass('visible');
      $('.display-overflow').hide();
      this.cartOpen = false;
    }
  }*/

  onClickedOutsideSearch() {
    $('.searchResult').removeClass('openAll')
  }

  initDropDown = () => {
    $(document).mouseup(function (e) {
      let trigger = $('[data-query="dropdown-trigger"]');
      let container = $('[data-query="dropdown-box"]');

      let click = e.target;

      if($(e.target).hasClass("md") || $(e.target).hasClass("fa")){
        click = e.target.parentElement;
      }
      if (trigger.is(click)) {
        let target = trigger.closest('[data-query="dropdown-wrapper"]').find('[data-query="dropdown-box"]');

        if (!target.hasClass("_active")) {
          target.addClass("_active")
          e.stopPropagation();
          return;
        }else{
          target.removeClass("_active")
          e.stopPropagation();
          return;
        }
      }
      if (!container.is(click)) {
        container.removeClass('_active');
      }
    });
  };


  initDropDownNoti = () => {
    $(document).mouseup(function (e) {
      let trigger = $('[data-query="dropdown-trigger-noti"]');
      let container = $('[data-query="dropdown-box-noti"]');

      let click = e.target;

      if($(e.target).hasClass("md") || $(e.target).hasClass("fa")){
        click = e.target.parentElement;
      }
      if (trigger.is(click)) {
        let target = trigger.closest('[data-query="dropdown-wrapper-noti"]').find('[data-query="dropdown-box-noti"]');

        if (!target.hasClass("_active")) {
          target.addClass("_active")
          e.stopPropagation();
          return;
        }else{
          target.removeClass("_active")
          e.stopPropagation();
          return;
        }
      }
      if (!container.is(click)) {
        container.removeClass('_active');
      }
    });
  };

  showLoginModal(){
    $('#login').modal('hide');
    $('#register').modal('show');
    $('#forgot').modal('hide');
  }

  showRegModal(){
    $('#register').modal('show');
    $('#login').modal('hide');
    $('#forgot').modal('hide');
  }

  showForgotModal(){
    $('#forgot').modal('show');
    $('#login').modal('hide');
    $('#register').modal('hide');
  }

  closeSideBar() {
    $('.popup-menu').removeClass('side-bar');
    $('.side-popup').removeClass('side-open');
  }

  showCart() {
    $('.cart-wrapper').addClass('visible');
  }

  closeCart() {
    $('.cart-wrapper').removeClass('visible');
  }

  openSideMenu() {
    $('.nav-bar-mobile').addClass('visible');
    $('.hamburger .fa-times').show();
    $('.hamburger .fa-bars').hide();
    $('.display-overflow').show();
  }

  closeSideMenu() {
    $('.nav-bar-mobile').removeClass('visible');
    $('.hamburger .fa-times').hide();
    $('.hamburger .fa-bars').show();
    $('.display-overflow').hide();
  }

  openChildMenu(e) {
    let trigger = $(e.target);
    let me = trigger.closest('.lvl-1-li').find('.lvl-1-child');
    if(me.is(":visible")){
      $('.lvl-1-child').hide();
      trigger.closest('.lvl-1-li').find('.lvl-1-ico').toggle();
    } else{
      $('.lvl-1-child').hide();
      trigger.closest('.lvl-1-li').find('.lvl-1-child').toggle();
      trigger.closest('.lvl-1-li').find('.lvl-1-ico').toggle();
    }

  }

  openChildMenulvl2(e) {
    let trigger = $(e.target)
    let me =  trigger.closest('.lvl-2-li').find('.lvl-2-child');
    if(me.is(":visible")){
      $('.lvl-2-child').hide();
    } else{
      $('.lvl-2-child').hide();
      trigger.closest('.lvl-2-li').find('.lvl-2-child').toggle();
    }
  }

  selectAcc(id) {
    if (id == 1) {
      this.accType = 1;
    } else {
      this.accType = 2;
    }
  }

  SiteConfigHeader() {
    if (SiteConfig() != null) {
    this.pageSettings = SiteConfig().shop_settings;
    this.logo_slug = SiteConfig().logo_slug;
    }
  }

  GetAuthDetails() {
    this.authenticationService.getUser().subscribe(res => {
      if (res.status === true) {
        this.UserDetails = {
          name: res.data.first_name +' '+ res.data.last_name,
          email: res.data.email
        }
      }
    })
  }

  checkAuthDetails() {
    this.authenticationService.getUser().subscribe(res => {
	console.log(res)	
      if(res.is_logged != true){
        this.logOutNoReload();
      } else {
        if (res.status === true) {
          localStorage.setItem("is_logged_time", ((new Date()).getTime()).toString());
        }
      }
    })
  }
}
