import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { FormsModule } from '@angular/forms';
import {ClickOutsideModule} from 'ng-click-outside';
import {HttpClientModule} from '@angular/common/http';

import {AppComponent} from './app.component';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
/*===========================*/
/*====COMPONENT START========*/
/*===========================*/

import {LayoutComponent} from './layout/layout.component';

import {HomeComponent} from './home/home.component';

import {ProductsComponent} from './products/products.component';
import {FreeSampleComponent} from './FreeSample/FreeSample.component';
import {PrinceSpringComponent} from './PrinceSpring/PrinceSpring.component';
import {BrandsComponent} from './Brands/Brands.component';
import {BestSellersComponent} from './products/bestSellers/bestSellers.component';
import {BestSellersPopularComponent} from './products/bestSellersPopular/bestSellersPopular.component';
import {FeaturedComponent} from './products/featured/featured.component';
import {PromotionalComponent} from './products/promotional/promotional.component';
import {AllNewComponent} from './products/allNew/allNew.component';
import {DealsComponent} from './products/deals/deals.component';
import {CategoryComponent} from './category/category.component';
import {EachCategoryComponent} from './category/EachCategory/EachCategory.component';
import {AllCategoriesComponent} from './category/AllCategories/AllCategories.component';


import {ProductComponent} from './product/product.component';

import {SearchProductsComponent} from './SearchProducts/SearchProducts.component';

import {LoginComponent} from './auth/login.component';
import {RegisterComponent} from './auth/register.component';

import {UserComponent} from './user/user.component';
import {AccountComponent} from './user/Account/Account.component';
import {FavoriteProductsComponent} from './user/FavoriteProducts/FavoriteProducts.component';
import {CartComponent} from './user/Cart/Cart.component';
import {ChangePasswordComponent} from './user/ChangePassword/ChangePassword.component';
import {AddAddressComponent} from './user/AddAddress/AddAddress.component';
import {ListAddressComponent} from './user/ListAddress/ListAddress.component';
import {ListPaymentComponent} from './user/ListPayment/ListPayment.component';
import {AddPaymentComponent} from './user/AddPayment/AddPayment.component';
import {ManageInboxComponent} from './user/ManageInbox/ManageInbox.component';
import {OrderComponent} from './user/order/order.component';
import {OrderDetailComponent} from './user/order/order-detail.component';
import {ThankyouComponent} from './user/order/thankyou.component';

import {CheckoutComponent} from './user/checkout/checkout.component';
import {AboutComponent} from './about/about.component';


/*========================*/
/*====ROUTES START========*/
/*========================*/

const routes: Routes = [
  {
    path: '', component: LayoutComponent, children: [
      {path: '', component: HomeComponent},
      {path: 'login', component: LoginComponent},
      {path: 'register', component: RegisterComponent},
      {
        path: 'category', component: CategoryComponent,
        children: [
          {path: 'all', component: AllCategoriesComponent},
          {path: ':code', component: EachCategoryComponent},
        ]
      },

      {path: 'search/:keyword', component: SearchProductsComponent},

      {
        path: 'product', component: ProductsComponent,
        children: [
          {path: 'popular-products', component: BestSellersComponent},
          {path: 'best-seller', component: BestSellersPopularComponent},
          {path: 'promotional', component: PromotionalComponent},
          {path: 'featured', component: FeaturedComponent},
          {path: 'all-new', component: AllNewComponent},
          {path: 'deals', component: DealsComponent},
          {path: 'covid-19', component: BestSellersComponent},
          {path: ':id', component: ProductComponent},
        ]
      },
      {path: 'subscriptions', component: FreeSampleComponent},
      {path: 'prince-spring', component: PrinceSpringComponent},
      {path: 'featured-brands', component: BrandsComponent},
      {
        path: 'user', component: UserComponent,
        children: [
          {path: 'account-settings', component: AccountComponent},
          {path: 'favorite-products', component: FavoriteProductsComponent},
          {path: 'cart', component: CartComponent},
          {path: 'change-password', component: ChangePasswordComponent},
          {path: 'list-address', component: ListAddressComponent},
          {path: 'add-address', component: AddAddressComponent},
          {path: 'list-payment', component: ListPaymentComponent},
          {path: 'add-payment', component: AddPaymentComponent},
          {path: 'manage-inbox', component: ManageInboxComponent},
          {path: 'order', component: OrderComponent},
          {path: 'order/:id', component: OrderDetailComponent},
          {path: 'thanks/:ref', component: ThankyouComponent},
        ]
      },
      {path: ':slug', component: AboutComponent},
    ]
  },
  {path: 'secure/checkout', component: CheckoutComponent},
];

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    FreeSampleComponent, PrinceSpringComponent, BrandsComponent,
    UserComponent, FavoriteProductsComponent, CartComponent, AccountComponent, ChangePasswordComponent,AddAddressComponent,ListAddressComponent,ListPaymentComponent, AddPaymentComponent, ManageInboxComponent, OrderComponent, OrderDetailComponent, ThankyouComponent,

    ProductsComponent, CategoryComponent, EachCategoryComponent, AllCategoriesComponent,
    ProductComponent,
    SearchProductsComponent,
    BestSellersComponent, AllNewComponent, DealsComponent, BestSellersPopularComponent, PromotionalComponent, FeaturedComponent,
    LoginComponent, RegisterComponent,

    CheckoutComponent,
    AboutComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    ClickOutsideModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
