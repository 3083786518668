
<div class="category-wrapper">
  <div class="breadcum-wrapper">
    <div class="breadcrumbs">
      <span>
        <a [routerLink]="['']">Home</a>
        <span class="icon"> > </span>
      </span>
      <span>
        <a [routerLink]="['/product/subscriptions']">Subscriptions</a>
        <span class="icon">  </span>
      </span>
    </div>
  </div>
  <div class="page-wrapper">
    <div class="under-category">
      <div class="loading" *ngIf="Loading == true">
        <div class="blobs">
          <div class="blob-center"></div>
          <div class="blob"></div>
          <div class="blob"></div>
          <div class="blob"></div>
          <div class="blob"></div>
          <div class="blob"></div>
          <div class="blob"></div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
          <defs>
            <filter id="goo">
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
              <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
              <feBlend in="SourceGraphic" in2="goo" />
            </filter>
          </defs>
        </svg>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="header-cat">
            <h1 class="title-name">Subscriptions</h1>
          </div>
        </div>
        <div class="col-md-6">

        </div>
      </div>
      <div class="row" *ngIf="ProductData != []">
        <div class="col-lg-2 col-12 col-sm-3" *ngFor="let sub of ProductData">
          <div class="subscription-box">
            <div class="title">{{sub.name}}</div>
            <div class="price">{{sub.formated_price}}</div>
            <div class="divider"></div>
            <div class="texts">{{sub.cf1}}</div>
            <div class="texts">{{sub.cf2}}</div>
            <div class="texts">{{sub.cf3}}</div>
            <button class="btn round-btn" (click)="addToCart(sub, $event)">PURCHASE NOW</button>
          </div>
        </div>
        <a href="javascript:void(0)" class="btn-login btn-block load-more" *ngIf="param.page <= lastPage" (click)="loadMore()">VIEW MORE</a>
      </div>
    </div>
  </div>
</div>


