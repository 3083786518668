<div class="-user-settings-wrapper">
  <div class="breadcum-wrapper">
    <div class="breadcrumbs">
      <span>
        <a [routerLink]="['']">Home</a>
        <span class="icon"> > </span>
      </span>
      <span>
        <a [routerLink]="['/user/account-settings']">Settings</a>
        <span class="icon"> > </span>
      </span>
      <span>
        <a [routerLink]="['/user/add-payment']">Add Payment</a>
        <span class="icon">  </span>
      </span>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="offset-md-3 col-md-6">
        <div class="form-title">Add Payment Option</div>
        <form action="#">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label><span class="required">*</span>CARD NUMBER</label>
                <input type="text" class="form-control">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label><span class="required">*</span>EXPIRATION DATE</label>
                <select class="form-control">
                  <option class="placeholder" value="">Month</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label></label>
                <select class="form-control no-label">
                  <option class="placeholder" value="">Year</option>
                  <option value="1">2010</option>
                  <option value="2">2011</option>
                  <option value="3">2012</option>
                  <option value="4">2013</option>
                  <option value="5">2014</option>
                  <option value="6">2015</option>
                  <option value="7">2016</option>
                  <option value="8">2015</option>
                  <option value="9">2016</option>
                  <option value="10">2017</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label><span class="required">*</span>SECURITY CODE</label>
                <input type="text" class="form-control">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label><span class="required">*</span>FIRST NAME</label>
                <input type="text" class="form-control">
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label><span class="required">*</span>LAST NAME</label>
                <input type="text" class="form-control">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label><span class="required">*</span>ADDRESS LINE 1</label>
                <input type="text" class="form-control">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>ADDRESS LINE 2 <span class="helper-text">(optional)</span></label>
                <input type="text" class="form-control">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label><span class="required">*</span>CITY</label>
                <input type="text" class="form-control">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label><span class="required">*</span>STATE</label>
                <input type="text" class="form-control">
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label><span class="required">*</span>ZIP CODE</label>
                <input type="text" class="form-control">
              </div>
            </div>
          </div>
          <div class="form-group">
            <button class="btn btn-theme w-100">SAVE & CONTINUE</button>
          </div>
        </form>
      </div>
      <div class="margin-30"></div>
    </div>
  </div>
</div>
