<div class="-user-settings-wrapper">
  <div class="breadcum-wrapper">
    <div class="breadcrumbs">
      <span>
        <a [routerLink]="['']">Home</a>
        <span class="icon"> > </span>
      </span>
      <span>
        <a [routerLink]="['/user/account-settings']">Settings</a>
        <span class="icon"> > </span>
      </span>
      <span>
        <a [routerLink]="['/user/add-address']">Add address</a>
        <span class="icon">  </span>
      </span>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="margin-30"></div>
      <div class="offset-3 col-md-6">
        <div class="form-title">Add Shipping Address</div>
        <form ngNativeValidate (ngSubmit)="addAddress()">
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label><span class="required">*</span>FIRST NAME</label>
                <input type="text" name="first_name" class="form-control" required="" [(ngModel)]="param.first_name">
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label><span class="required">*</span>LAST NAME</label>
                <input type="text" class="form-control" name="last_name" required="" [(ngModel)]="param.last_name">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label><span class="required">*</span>ADDRESS LINE 1</label>
                <input type="text" class="form-control" name="line1" required="" [(ngModel)]="param.line1">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>ADDRESS LINE 2 <span class="helper-text">(optional)</span></label>
                <input type="text" class="form-control" name="line2" [(ngModel)]="param.line2">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label><span class="required">*</span>COUNTRY</label>
                <select name="country" *ngIf="countryData != null" required="" [(ngModel)]="param.country" class="form-control" (change)="getState()">
                  <option value="{{country.id}}" *ngFor="let country of countryData">{{country.name}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label><span class="required">*</span>STATE</label>
                <select name="country" *ngIf="stateData != null" required="" class="form-control" (change)="getState()" [(ngModel)]="param.state">
                  <option value="{{state.id}}" *ngFor="let state of stateData">{{state.state_name}}</option>
                </select>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label><span class="required">*</span>ZIP CODE</label>
                <input type="text" class="form-control" name="postal_code" required="" [(ngModel)]="param.postal_code">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label><span class="required">*</span>CITY</label>
                <input type="text" class="form-control" name="city" required="" [(ngModel)]="param.city">
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label><span class="required">*</span>PHONE</label>
                <input type="text" class="form-control" name="phone" required="" [(ngModel)]="param.phone">
              </div>
            </div>
          </div>
          <div class="form-group">
            <button *ngIf="loading === false" type="submit" class="btn btn-theme w-100">SAVE & CONTINUE</button>
            <button *ngIf="loading === true" disabled type="button" class="btn btn-theme w-100">SAVE & CONTINUE <i class="fa fa-spinner fa-spin"></i></button>
          </div>
        </form>
      </div>
      <div class="margin-30"></div>
    </div>
  </div>
</div>
