<div class="_user_pages_wrapper">
  <div class="breadcum-wrapper">
    <div class="breadcrumbs">
      <span>
        <a [routerLink]="['']">Home</a>
        <span class="icon"> > </span>
      </span>
      <span>
        <a [routerLink]="['/user/account-settings']">Settings</a>
        <span class="icon">  </span>
      </span>
    </div>
  </div>
  <div class="_account-settings-wrapper">
    <div class="loading" *ngIf="authData == null">
      <div class="blobs">
        <div class="blob-center"></div>
        <div class="blob"></div>
        <div class="blob"></div>
        <div class="blob"></div>
        <div class="blob"></div>
        <div class="blob"></div>
        <div class="blob"></div>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
        <defs>
          <filter id="goo">
            <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
            <feBlend in="SourceGraphic" in2="goo" />
          </filter>
        </defs>
      </svg>
    </div>
    <div class="container" *ngIf="authData != null">
      <div class="welcome-section">
        <div class="title">Welcome</div>
        <div class="username">
          <div class="trigger">
            <a href="javascript:void(0)">{{authData.first_name}} {{authData.last_name}}</a>
          </div>
        </div>
      </div>
      <div class="user-details">
        <div class="row">
          <div class="col-xl-3 col-lg-4 col-md-6 col-12">
            <div class="info-inner-wrapper">
              <div class="email">{{authData.email}}</div>
              <div class="user-phone">
                <span>{{authData.phone}}</span><br>
              </div>
            </div>
          </div>
          <div class="col-md-9 col-lg-8  col-md-6 col-12">
            <div class="settings-options">
              <div class="each-options">
                <div class="trigger">
                  <a href="javascript:void(0)" data-toggle="modal" data-target="#exampleModal">Edit profile ></a>
                </div>
                <div class="trigger">
                  <a [routerLink]="['/user/change-password']">Change password ></a>
                </div>
              </div>
             <!-- <div class="each-options">
                <div class="trigger">
                  <a href="javascript:void(0)">Connect with Facebook ></a>
                </div>
              </div>
              <div class="each-options">
                <div class="trigger">
                  <a href="javascript:void(0)">Connect with Google ></a>
                </div>
              </div>-->
            </div>
          </div>
        </div>
      </div>
      <div class="account-details">
        <div class="row">
          <div class="col-xl-3 col-lg-4 col-md-6 col-ms-12 mb-5">
            <div class="no-separator each-details">
              <div class="each-details-inner">
                <div class="title">Shipping:</div>
                <div class="info-each-wrapper" *ngIf="addressData.length == 0">
                  <div class="info-each">No shipping</div>
                </div>
                <div class="info-each-wrapper" *ngIf="addressData.length > 0">
                  <div class="name">{{addressData[0].first_name}} {{addressData[0].last_name}}</div>
                  <div class="">{{addressData[0].line1}}</div>
                  <div class="">{{addressData[0].line2}}</div>
                  <div class="">{{addressData[0].city}}, {{addressData[0].postal_code}}</div>
                  <div class="">{{addressData[0].phone}}</div>
                </div>
              </div>
              <div class="trigger">
                <a [routerLink]="['/user/list-address']">Manage shipping ></a>
              </div>
            </div>
          </div>
	  <!-- 
          <div class="col-xl-3 col-lg-4 col-md-6 col-ms-12 mb-5">
            <div class="each-details">
              <div class="each-details-inner">
                <div class="title">Default Payment:</div>
                <div class="info-each-wrapper" *ngIf="paymentData.length > 0">
                  <div class="info-each" *ngIf="paymentData[0] == 'cod'">Cash on delivery</div>
                </div>
              </div>
              <div class="trigger">
                <a [routerLink]="['/user/list-payment']">Manage payment ></a>
              </div>
            </div>
          </div>
	  
          <div class="col-xl-3 col-lg-4 col-md-6 col-ms-12 mb-5">
            <div class="each-details">
              <div class="each-details-inner">
                <div class="title">Email Subscriptions:</div>
                <div class="info-each-wrapper">
                  <div class="info-each">Promotional Emails</div>
                  <div class="info-each">Personalized Reminders</div>
                  <div class="info-each">Reminder Emails</div>
                </div>
              </div>
              <div class="trigger">
                <a [routerLink]="['/user/manage-inbox']">Manage inbox ></a>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-4 col-md-6 col-ms-12 mb-5">
            <div class="each-details">
              <div class="each-details-inner">
                <div class="title">Box Tops for Education™:</div>
                <div class="info-each-wrapper">
                  <div class="info-each">Connect your Box Tops For</div>
                  <div class="info-each">Education account to support</div>
                  <div class="info-each">your participating school.</div>
                </div>
              </div>
              <div class="trigger">
                <a href="javascript:void(0)">Connect to Box Tops ></a>
              </div>
            </div>
	    </div>
	  -->

        </div>
      </div>
      <div class="account-counter-wrapper">
        <div class="row">
          <div class="col-xl-3 col-lg-4 col-md-6 col-ms-12 mb-5">
            <div class="each-item-counter">
              <div class="each-item-counter-inner" *ngIf="orderData != null">
                <img class="counter-image" src="{{ENV.ASSET_URL}}/icons/food-delivery.png" alt="">
                <div class="title">Orders Placed:</div>
                <div class="count">{{totalOrder}}</div>
                <div class="trigger" *ngIf="orderData.length > 0">
                  <a [routerLink]="['/user/order']">View All Orders ></a>
                </div>
              </div>
              <div class="each-item-counter-inner" *ngIf="orderData == null">
                <img class="counter-image" src="{{ENV.ASSET_URL}}/icons/food-delivery.png" alt="">
                <div class="title">Orders Placed:</div>
                <div class="count">0</div>
               <!-- <div class="trigger">
                  <a [routerLink]="['/user/order']">View All Orders ></a>
                </div>-->
              </div>
            </div>
          </div>
          <!-- <div class="col-xl-3 col-lg-4 col-md-6 col-ms-12 mb-5">
            <div class="each-item-counter">
              <div class="each-item-counter-inner">
                <img class="counter-image" src="{{ENV.ASSET_URL}}/icons/contest.png" alt="">
                <div class="title">Cash Rewards Earned:</div>
                <div class="count">$0.00</div>
                <div class="trigger">
                  <a href="javascript:void(0)" >Explore a1daam Up ></a>
                </div>
              </div>
            </div>
	  </div> 
          <div class="col-xl-3 col-lg-4 col-md-6 col-ms-12 mb-5">
            <div class="each-item-counter">
              <div class="each-item-counter-inner">
                <img class="counter-image" src="{{ENV.ASSET_URL}}/icons/startup.png" alt="">
                <div class="title">Number of Favorites:</div>
                <div class="count">0</div>
                <div class="trigger">
                  <a [routerLink]="['/user/favorite-products']">See Favorites ></a>
                </div>
              </div>
            </div>
	  </div> 
          <div class="col-xl-3 col-lg-4 col-md-6 col-ms-12 mb-5">
            <div class="each-item-counter">
              <div class="each-item-counter-inner">
                <img class="counter-image" src="{{ENV.ASSET_URL}}/icons/coin.png" alt="">
                <div class="title">Lifetime Savings:</div>
                <div class="count">$0.00</div>
                <div class="trigger">
                  <a [routerLink]="['']">Start Shopping ></a>
                </div>
              </div>
            </div>
	  </div> -->

        </div>
      </div>
      <div class="account-counter-wrapper" *ngIf="authData != null">
        <div class="row">
          <div class="col-md-3">
            <div class="subscription-box"  *ngIf="authData.subscription_detail != ''">
              <div class="title">{{authData.subscription_detail.name}}</div>
              <div class="price">{{authData.subscription_detail.formated_price}}</div>
              <div class="divider"></div>
              <div class="texts">{{authData.subscription_detail.cf1}}</div>
              <div class="texts">{{authData.subscription_detail.cf2}}</div>
              <div class="texts">{{authData.subscription_detail.cf3}}</div>
              <button class="btn round-btn" >PURCHASED</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><img width="20px" src="{{ENV.ASSET_URL}}/icons/close.png" alt=""></span>
        </button>
      </div>

      <form ngNativeValidate (ngSubmit)="updateUser()">
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <label>FIRST NAME</label>
                <input type="text" name="first_name" class="form-control" [(ngModel)]="param.first_name" required="">
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label>LAST NAME</label>
                <input type="text" class="form-control" name="last_name"  [(ngModel)]="param.last_name" required="">
              </div>
            </div>
          </div>

          <div class="form-group">
            <label>PHONE</label>
            <input type="text" class="form-control"  name="phone" [(ngModel)]="param.phone" required="">
          </div>
          <div class="form-group">
            <label>EMAIL</label>
            <input type="text" class="form-control" name="email"  [(ngModel)]="param.email" required="">
          </div>
          <div class="form-group">
            <label>ADDRESS</label>
            <input type="text" class="form-control" name="address" [(ngModel)]="param.address">
          </div>
          <div class="form-group">
            <label>COUNTRY</label>
            <select class="form-control" name="country" *ngIf="countryData != null" (change)="getState()" [(ngModel)]="param.country">
              <option value="{{country.id}}" *ngFor="let country of countryData">{{country.name}}</option>
            </select>
          </div>
          <div class="form-group"  *ngIf="stateData != null">
            <label>STATE</label>
            <select class="form-control" name="state" [(ngModel)]="param.state">
              <option value="{{state.id}}" *ngFor="let state of stateData">{{state.state_name}}</option>
            </select>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <label>CITY</label>
                <input type="text" name="city" class="form-control" [(ngModel)]="param.city">
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label>POSTAL CODE</label>
                <input type="text" name="postal_code" class="form-control" [(ngModel)]="param.postal_code">
              </div>
            </div>
          </div>
          <button *ngIf="loading === false" type="submit" class="btn btn-theme w-100">SAVE & CONTINUE</button>
          <button *ngIf="loading === true" disabled type="button" class="btn btn-theme w-100">SAVE & CONTINUE <i class="fa fa-spinner fa-spin"></i></button>
        </div>
      </form>
    </div>
  </div>
</div>
