import { Component, OnInit } from '@angular/core';
import {environment} from '../../environments/environment';
import {AuthenticationService} from "../services/authentication.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {CategoryService} from "../services/category.service";
declare const $: any;
declare const toastr: any;
declare const Ladda: any;

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html'
})
export class AboutComponent implements OnInit {

  ENV = environment;
  menuOpen = false;
  Loading = false;
  pageInfo: any = null;
  slug: any = '';

  constructor(protected authenticationService : AuthenticationService,   protected categoryService : CategoryService,  private router: Router, private route: ActivatedRoute) {
    this.slug = this.route.snapshot.paramMap.get('slug');
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.slug = this.route.snapshot.paramMap.get('slug');
        this.getPage();
        window.scrollTo(0, 0);
        this.closeSideMenu();
      }
    });
    window.scrollTo(0, 0);
    this.closeSideMenu();
    this.getPage();
    $('#register').modal('hide')

  }
  ngOnInit(): void {}

  getPage() {
    this.Loading = true;
    this.authenticationService.getAbout(this.slug).subscribe(res => {
      this.Loading = false;
      if (res.status == true) {
        this.pageInfo = res.data;
      }
    })
  }

  closeSideMenu() {
    $('.nav-bar-mobile').removeClass('visible');
    $('.hamburger .fa-times').hide();
    $('.hamburger .fa-bars').show();
    $('.display-overflow').hide();
    this.menuOpen = false;
  }
}
