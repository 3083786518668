<div class="position-relative">
  <div class="custom-container">
    <div class="full-page">
      <div class="section-login hide-in-reg">
        <h2 class="text-1 pb-36">Already a Customer</h2>
        <button class="btn-goreg"  [routerLink]="['/login']">LOG IN</button>
      </div>
      <div class="section-reg in-reg">
        <h2 class="text-1">Create An Account</h2>
        <form ngNativeValidate (ngSubmit)="register()">
         <!-- <div class="form-group">
            <p class="type-t">TYPE OF ACCOUNT</p>
            <div class="option-wrapper">
              <div class="g-input" (click)="selectAcc(1)">
                <div class="box" [ngClass]="{'active' : accType == 1}">
                  <span></span>
                </div>
                <label >Personal</label>
              </div>
              <div class="g-input ml-4" (click)="selectAcc(2)">
                <div class="box" [ngClass]="{'active' : accType == 2}">
                  <span></span>
                </div>
                <label >Business</label>
              </div>
            </div>
          </div>-->
          <div class="form-group">
            <label class="custom-label">FIRST NAME</label>
            <input type="text" name="first_name"  class="custom-control form-control small-height" [(ngModel)]="regInfo.first_name" required="">
            <div class="invalid-feedback"></div>
          </div>
          <div class="form-group">
            <label class="custom-label">LAST NAME</label>
            <input type="text"  name="last_name"  class="custom-control form-control small-height"  [(ngModel)]="regInfo.last_name" required="">
            <div class="invalid-feedback"></div>
          </div>
          <div class="form-group">
            <label  class="custom-label">USER NAME</label>
            <input type="text"  name="username" class="custom-control  form-control small-height"  [(ngModel)]="regInfo.username" required="">
            <div class="invalid-feedback"></div>
          </div>
          <div class="form-group">
            <label  class="custom-label">EMAIL ADDRESS</label>
            <input type="email"  name="email"  class="custom-control form-control small-height"  [(ngModel)]="regInfo.email" required="">
            <div class="invalid-feedback"></div>
          </div>
          <div class="form-group">
            <label  class="custom-label">MOBILE</label>
            <input type="text"  name="phone"  class="custom-control form-control small-height"  [(ngModel)]="regInfo.phone" required="">
            <div class="invalid-feedback"></div>
          </div>
          <div class="form-group">
            <label  class="custom-label">PASSWORD</label>
            <input type="password"  name="password" class="custom-control form-control small-height"  [(ngModel)]="regInfo.password" required="">
            <div class="invalid-feedback"></div>
            <small class="pass-text">Must contain 8 characters with at least 1 uppercase and 1 lowercase letter and either 1 number or 1 special character.</small>
          </div>
          <div class="form-group">
            <label  class="custom-label">CONFIRM PASSWORD</label>
            <input type="password"  name="password_confirm" class="custom-control form-control small-height"  [(ngModel)]="regInfo.password_confirm" required="">
            <div class="invalid-feedback"></div>
          </div>
          <div class="form-group">
            <button type="submit" *ngIf="loading == false" class="btn-login">CREATE ACCOUNT</button>
            <button type="button" *ngIf="loading == true" disabled class="btn-login">CREATE ACCOUNT <i class="fa fa-spinner fa-spin"></i></button>
          </div>
        </form>
        <div class="term-text">
          <p>Access and use of a1daam sites subject to our
            <a [routerLink]="['', 'term-condition']">Terms & Conditions</a>
            and
            <a [routerLink]="['', 'privacy-policy']">Privacy Policy</a>
          </p>
        </div>
      </div>
      <div class="page-divider"></div>
    </div>
  </div>
  <div class="bottom-bar">
    <div class="text-box">
      <div class="text-1">ALREADY A CUSTOMER?</div>
      <a href="javascript:void(0)" [routerLink]="['/login']" >Welcome back, log in</a>
    </div>
  </div>
</div>

