<div class="slider-container">
  <div class="full-slider-section-wrapper" *ngIf="homeData != null">
    <div class="owl-carousel slider-wrapper full-slider-carousel">
      <div *ngFor="let EachSlide of homeData.slider" class="item each-slide" [ngStyle]="{'background-image': 'url('+EachSlide.image+')'}">
        <div class="outer-content-wrapper full">
          <div class="inner-content-wrapper">
            <div class="heading white">{{EachSlide.caption}}</div>
            <div class="slogan white">{{EachSlide.description}}</div>
            <div class="trigger">
              <a href="{{EachSlide.link}}" class="btn btn-theme letter-space-2">EXPLORE</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="owl-carousel slider-wrapper-mobile full-slider-carousel">
      <div *ngFor="let EachSlide of homeData.slider" class="item each-slide">
        <div class="each-slide-inner">
          <div class="cover-image" [ngStyle]="{'background-image': 'url('+EachSlide.image+')'}"></div>
          <div class="outer-content-wrapper">
            <div class="inner-content-wrapper">
              <div class="heading">{{EachSlide.caption}}</div>
              <div class="slogan">{{EachSlide.description}}</div>
              <div class="trigger">
                <a href="{{EachSlide.link}}" class="btn btn-theme letter-space-2">SHOP NOW</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="slider-dot-container">
      <div class="slider-dot-container-inner" id="carousel-custom-dots">
        <div *ngFor="let EachSlide of homeData.slider" class="each-dot-wrapper">
          <div class="cover-img">
            <img src="{{EachSlide.image}}" alt="">
          </div>
          <div class="title">{{EachSlide.caption}}</div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="loading" *ngIf="homeData == null">
  <div class="blobs">
    <div class="blob-center"></div>
    <div class="blob"></div>
    <div class="blob"></div>
    <div class="blob"></div>
    <div class="blob"></div>
    <div class="blob"></div>
    <div class="blob"></div>
  </div>
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
    <defs>
      <filter id="goo">
        <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
        <feBlend in="SourceGraphic" in2="goo" />
      </filter>
    </defs>
  </svg>
</div>

<div class="container" *ngIf="homeData != null">
  <div class="each-list-section-wrapper" *ngIf="homeData != null">
    <div class="section-title">FEATURED CATEGORIES</div>
    <div class="section-contents category-list product-list">
      <div class="owl-carousel category-carousel product-carousel">
        <a [routerLink]="['/category', eachCat.code]" class="each-item item" *ngFor="let eachCat of homeData.featured_categories">
          <div class="cover-image">
            <img src="{{eachCat.image_url}}" alt="">
          </div>
          <div class="item-title">{{eachCat.name}} &nbsp;<i class="fa fa-angle-double-right" aria-hidden="true"></i></div>
        </a>
      </div>
    </div>
  </div>

  <div class="each-list-section-wrapper">
    <div class="section-title">Featured Products</div>
    <div class="slogan">The essentials, must-haves and must wants to fill your pantry and more., view more <a
      class="link_trigger" [routerLink]="['/product/featured']">here</a></div>
    <div class="section-contents product-list">
      <div class="owl-carousel product-carousel pp3 ">
        <div class="each-item item" *ngFor="let eachFeatProduct of homeData.featured_products; let index = index">
          <div class="sale" *ngIf="eachFeatProduct.discount_price > 0">sale</div>
          <a [routerLink]="['/product', eachFeatProduct.slug]">
            <div class="cover-image">
              <img [ngClass]="{'img0' : eachFeatProduct.photos.length > 0}" src="{{eachFeatProduct.image_url}}" alt="">
              <img *ngIf="eachFeatProduct.photos.length > 0" class="img1" src="{{eachFeatProduct.photos[0].photo_url}}" alt="">
              <div class="sub-feature"*ngIf="eachFeatProduct.discount_price > 0">OFFERED PRODUCT</div>
            </div>
            <div class="product-details-info">
              <div class="product-title"> {{eachFeatProduct.name}}</div>
              <div class="p-subtitle"  *ngIf="eachFeatProduct.variants != []">
                <div class="text-1" *ngFor="let v of eachFeatProduct.variants; let kk = index">{{v.name}}  <i *ngIf="eachFeatProduct.variants.length != kk+1" class="dot fa fa-circle"></i></div>
              </div>
              <span *ngIf="eachFeatProduct.discount_price == 0" class="price">{{eachFeatProduct.formated_price}}</span>
              <div class="product-price-wrapper" *ngIf="eachFeatProduct.discount_price > 0" >
                <div class="current-price">
                  <div class="value">{{eachFeatProduct.formated_price}}</div>
                </div>
                <div class="real-price">
                  <span class="value">{{eachFeatProduct.formated_list_price}}</span><br>
                  <span class="sub-slogan">List Price</span>
                </div>
              </div>
              <div class="retail_save" *ngIf="eachFeatProduct.discount_percent > 0">save {{eachFeatProduct.discount_percent}}% vs retail</div>
            </div>
          </a>
          <div class="options-wrapper">
            <button type="button" class="btn btn-outline-secondary add-cart-btn" (click)="addToCart(eachFeatProduct, $event)">ADD</button>
            <div class="love-wrapper">
                  <span (click)="addTowish(eachFeatProduct, index)" class="cursor-pointer">
                     <i *ngIf="eachFeatProduct.wishlist === 0" aria-hidden="true" class="fa fa-heart-o"></i>
                      <i *ngIf="eachFeatProduct.wishlist === 1" aria-hidden="true" class="fa fa-heart"></i>
                  </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="each-banner-section-wrapper">
    <div class="two-cal-banner">
      <div class="row">
        <div class="col-xl-6">
          <div class="banner-bg-wrapper">
            <a  href="javascript:void(0)">
              <div class="banner-wrapper">
                <div class="banner-title">
                  <h2 class="heading">{{homeData.banner[0].caption}}</h2>
                  <p class="slogan hidden-xs">{{homeData.banner[0].description}}</p>
                  <a href="{{homeData.banner[0].link}}" class="btn banner-btn">EXPLORE</a>
                </div>
                <div class="image-wrapper">
                  <img class="lazy shown" src="{{homeData.banner[0].image}}" data-src="" alt="">
                </div>
              </div>
            </a>
          </div>
        </div>

        <div class="col-xl-6">
          <div class="hz-banner-wrapper">
            <div class="banner-hz-wrapper">
              <a  href="javascript:void(0)">
                <div class="banner-wrapper clearfix">
                  <div class="section-left">
                    <div class="banner-title">
                      <h2 >{{homeData.banner[1].caption}}</h2>
                      <p class="slogan">{{homeData.banner[1].description}}</p>
                      <a href="{{homeData.banner[1].link}}" class="btn banner-btn">EXPLORE</a>
                    </div>
                  </div>
                  <div class="section-right">
                    <div class="image-wrapper">
                      <img class="lazy shown" src="{{homeData.banner[1].image}}" data-src="" alt="">
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div class="hz-banner-wrapper">
            <div class="banner-hz-wrapper">
              <a href="javascript:void(0)">
                <div class="banner-wrapper clearfix">
                  <div class="section-left">
                    <div class="banner-title">
                      <h2 class="heading">{{homeData.banner[2].caption}}</h2>
                      <p class="slogan">{{homeData.banner[2].description}}</p>
                      <a  href="{{homeData.banner[2].link}}" class="btn banner-btn">EXPLORE</a>
                    </div>
                  </div>
                  <div class="section-right">
                    <div class="image-wrapper">
                      <img  class="lazy shown" src="{{homeData.banner[2].image}}"
                            data-src="" alt="">
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="each-list-section-wrapper">
    <div class="section-title">Popular Product</div>
    <div class="slogan">The essentials, must-haves and must wants to fill your pantry and more., view more <a
      class="link_trigger" [routerLink]="['/product/popular-products']">here</a></div>
    <div class="section-contents product-list">
      <div class="owl-carousel product-carousel pp3">
        <div class="each-item item" *ngFor="let eachFeatProduct of homeData.popular_products; let index = index">
          <div class="sale" *ngIf="eachFeatProduct.discount_price > 0">sale</div>
          <a [routerLink]="['/product', eachFeatProduct.slug]">
            <div class="cover-image">
              <img [ngClass]="{'img0' : eachFeatProduct.photos.length > 0}" src="{{eachFeatProduct.image_url}}" alt="">
              <img *ngIf="eachFeatProduct.photos.length > 0" class="img1" src="{{eachFeatProduct.photos[0].photo_url}}" alt="">
              <div class="sub-feature"*ngIf="eachFeatProduct.discount_price > 0">OFFERED PRODUCT</div>
            </div>
            <div class="product-details-info">
              <div class="product-title"> {{eachFeatProduct.name}}</div>
              <div class="p-subtitle"  *ngIf="eachFeatProduct.variants != []">
                <div class="text-1" *ngFor="let v of eachFeatProduct.variants; let kk = index">{{v.name}}  <i *ngIf="eachFeatProduct.variants.length != kk+1" class="dot fa fa-circle"></i></div>
              </div>
              <span *ngIf="eachFeatProduct.discount_price == 0" class="price">{{eachFeatProduct.formated_price}}</span>
              <div class="product-price-wrapper" *ngIf="eachFeatProduct.discount_price > 0" >
                <div class="current-price">
                  <div class="value">{{eachFeatProduct.formated_price}}</div>
                </div>
                <div class="real-price">
                  <span class="value">{{eachFeatProduct.formated_list_price}}</span><br>
                  <span class="sub-slogan">List Price</span>
                </div>
              </div>
              <div class="retail_save" *ngIf="eachFeatProduct.discount_percent > 0">save {{eachFeatProduct.discount_percent}}% vs retail</div>
            </div>
          </a>
          <div class="options-wrapper">
            <button type="button" class="btn btn-outline-secondary add-cart-btn" (click)="addToCart(eachFeatProduct,  $event)">ADD</button>
            <div class="love-wrapper">
                  <span (click)="addTowishPopular(eachFeatProduct, index)" class="cursor-pointer">
                     <i *ngIf="eachFeatProduct.wishlist === 0" aria-hidden="true" class="fa fa-heart-o"></i>
                      <i *ngIf="eachFeatProduct.wishlist === 1" aria-hidden="true" class="fa fa-heart"></i>
                  </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="each-list-section-wrapper">
    <div class="section-title">Best Seller</div>
    <div class="slogan">The essentials, must-haves and must wants to fill your pantry and more., view more <a
      class="link_trigger" [routerLink]="['/product/best-seller']">here</a></div>
    <div class="section-contents product-list">
      <div class="owl-carousel product-carousel pp2" *ngIf="ProductData != []">
        <div class="each-item item" *ngFor="let eachFeatProduct of ProductData; let index = index">
          <div class="sale" *ngIf="eachFeatProduct.discount_price > 0">sale</div>
          <a [routerLink]="['/product', eachFeatProduct.slug]">
            <div class="cover-image">
              <img [ngClass]="{'img0' : eachFeatProduct.photos.length > 0}" src="{{eachFeatProduct.image_url}}" alt="">
              <img *ngIf="eachFeatProduct.photos.length > 0" class="img1" src="{{eachFeatProduct.photos[0].photo_url}}" alt="">
              <div class="sub-feature"*ngIf="eachFeatProduct.discount_price > 0">OFFERED PRODUCT</div>
            </div>
            <div class="product-details-info">
              <div class="product-title"> {{eachFeatProduct.name}}</div>
              <div class="p-subtitle"  *ngIf="eachFeatProduct.variants != []">
                <div class="text-1" *ngFor="let v of eachFeatProduct.variants; let kk = index">{{v.name}}  <i *ngIf="eachFeatProduct.variants.length != kk+1" class="dot fa fa-circle"></i></div>
              </div>
              <span *ngIf="eachFeatProduct.discount_price == 0" class="price">{{eachFeatProduct.formated_price}}</span>
              <div class="product-price-wrapper" *ngIf="eachFeatProduct.discount_price > 0" >
                <div class="current-price">
                  <div class="value">{{eachFeatProduct.formated_price}}</div>
                </div>
                <div class="real-price">
                  <span class="value">{{eachFeatProduct.formated_list_price}}</span><br>
                  <span class="sub-slogan">List Price</span>
                </div>
              </div>
              <div class="retail_save" *ngIf="eachFeatProduct.discount_percent > 0">save {{eachFeatProduct.discount_percent}}% vs retail</div>
            </div>
          </a>
          <div class="options-wrapper">
            <button type="button" class="btn btn-outline-secondary add-cart-btn" (click)="addToCart(eachFeatProduct,  $event)">ADD</button>
            <div class="love-wrapper">
                  <span (click)="addTowishBestSeller(eachFeatProduct, index)" class="cursor-pointer">
                     <i *ngIf="eachFeatProduct.wishlist === 0" aria-hidden="true" class="fa fa-heart-o"></i>
                      <i *ngIf="eachFeatProduct.wishlist === 1" aria-hidden="true" class="fa fa-heart"></i>
                  </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="each-banner-section-wrapper">
    <div class="single-cal-banner">
      <div class="row">
        <div class="col-md-12">
          <div class="hz-banner-wrapper">
            <div class="banner-hz-wrapper">
              <a  href="javascript:void(0)">
                <div class="banner-wrapper clearfix">
                  <div class="image-wrapper">
                    <img class="lazy shown" src="{{homeData.banner[3].image}}" data-src="" alt="">
                  </div>
                  <div class="banner-title">
                    <h2 class="heading">{{homeData.banner[3].caption}}</h2>
                    <p class="slogan">{{homeData.banner[3].description}}</p>
                    <a href="{{homeData.banner[3].link}}" class="btn banner-btn">EXPLORE</a>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 <!-- <div class="each-list-section-wrapper">
    <div class="section-title">SHOP BY PREFERENCE</div>
    <div class="section-contents category-list">
      <div class="owl-carousel category-carousel">
        <div class="each-item item">
          <div class="cover-image">
            <img src="{{ENV.ASSET_URL}}/dummy/category/stocked.png" alt="">
          </div>
          <div class="item-title">Stay Stocked &nbsp;<i class="fa fa-angle-double-right" aria-hidden="true"></i></div>
        </div>
        <div class="each-item item">
          <div class="cover-image">
            <img src="{{ENV.ASSET_URL}}/dummy/category/stay_clean.png" alt="">
          </div>
          <div class="item-title">Stay Clean &nbsp;<i class="fa fa-angle-double-right" aria-hidden="true"></i></div>
        </div>
        <div class="each-item item">
          <div class="cover-image">
            <img src="{{ENV.ASSET_URL}}/dummy/category/stay_safe.png" alt="">
          </div>
          <div class="item-title">Stay Safe &nbsp;<i class="fa fa-angle-double-right" aria-hidden="true"></i></div>
        </div>
        <div class="each-item item">
          <div class="cover-image">
            <img src="{{ENV.ASSET_URL}}/dummy/category/stay_home.png" alt="">
          </div>
          <div class="item-title">Stay Home &nbsp;<i class="fa fa-angle-double-right" aria-hidden="true"></i></div>
        </div>
      </div>
    </div>
  </div>-->
  <div class="each-list-section-wrapper">
    <div class="section-title">All New</div>
    <div class="slogan">The essentials, must-haves and must wants to fill your pantry and more., view more <a
      class="link_trigger"  [routerLink]="['/product/all-new']">here</a></div>
    <div class="section-contents product-list">
      <div class="owl-carousel product-carousel pp1">
        <div class="each-item item" *ngFor="let eachFeatProduct of AllNewProductData; let index = index">
          <div class="sale" *ngIf="eachFeatProduct.discount_price > 0">sale</div>
          <a [routerLink]="['/product', eachFeatProduct.slug]">
            <div class="cover-image">
              <img [ngClass]="{'img0' : eachFeatProduct.photos.length > 0}" src="{{eachFeatProduct.image_url}}" alt="">
              <img *ngIf="eachFeatProduct.photos.length > 0" class="img1" src="{{eachFeatProduct.photos[0].photo_url}}" alt="">
              <div class="sub-feature"*ngIf="eachFeatProduct.discount_price > 0">OFFERED PRODUCT</div>
            </div>
            <div class="product-details-info">
              <div class="product-title"> {{eachFeatProduct.name}}</div>
              <div class="p-subtitle"  *ngIf="eachFeatProduct.variants != []">
                <div class="text-1" *ngFor="let v of eachFeatProduct.variants; let kk = index">{{v.name}}  <i *ngIf="eachFeatProduct.variants.length != kk+1" class="dot fa fa-circle"></i></div>
              </div>
              <span *ngIf="eachFeatProduct.discount_price == 0" class="price">{{eachFeatProduct.formated_price}}</span>
              <div class="product-price-wrapper" *ngIf="eachFeatProduct.discount_price > 0" >
                <div class="current-price">
                  <div class="value">{{eachFeatProduct.formated_price}}</div>
                </div>
                <div class="real-price">
                  <span class="value">{{eachFeatProduct.formated_list_price}}</span><br>
                  <span class="sub-slogan">List Price</span>
                </div>
              </div>
              <div class="retail_save" *ngIf="eachFeatProduct.discount_percent > 0">save {{eachFeatProduct.discount_percent}}% vs retail</div>
            </div>
          </a>
          <div class="options-wrapper">
            <button type="button" class="btn btn-outline-secondary add-cart-btn" (click)="addToCart(eachFeatProduct,  $event)">ADD</button>
            <div class="love-wrapper">
                  <span (click)="addTowishAllNew(eachFeatProduct, index)" class="cursor-pointer">
                     <i *ngIf="eachFeatProduct.wishlist === 0" aria-hidden="true" class="fa fa-heart-o"></i>
                      <i *ngIf="eachFeatProduct.wishlist === 1" aria-hidden="true" class="fa fa-heart"></i>
                  </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="each-list-section-wrapper">
    <div class="section-title">Promotional Products</div>
    <div class="slogan">view more <a
      class="link_trigger" [routerLink]="['/product/promotional']">here</a></div>
    <div class="section-contents product-list">
      <div class="owl-carousel product-carousel pp3">
        <div class="each-item item" *ngFor="let eachFeatProduct of homeData.promotional_products; let index = index">
          <div class="sale" *ngIf="eachFeatProduct.discount_price > 0">sale</div>
          <a [routerLink]="['/product', eachFeatProduct.slug]">
            <div class="cover-image">
              <img [ngClass]="{'img0' : eachFeatProduct.photos.length > 0}" src="{{eachFeatProduct.image_url}}" alt="">
              <img *ngIf="eachFeatProduct.photos.length > 0" class="img1" src="{{eachFeatProduct.photos[0].photo_url}}" alt="">
              <div class="sub-feature"*ngIf="eachFeatProduct.discount_price > 0">OFFERED PRODUCT</div>
            </div>
            <div class="product-details-info">
              <div class="product-title"> {{eachFeatProduct.name}}</div>
              <div class="p-subtitle"  *ngIf="eachFeatProduct.variants != []">
                <div class="text-1" *ngFor="let v of eachFeatProduct.variants; let kk = index">{{v.name}}  <i *ngIf="eachFeatProduct.variants.length != kk+1" class="dot fa fa-circle"></i></div>
              </div>
              <span *ngIf="eachFeatProduct.discount_price == 0" class="price">{{eachFeatProduct.formated_price}}</span>
              <div class="product-price-wrapper" *ngIf="eachFeatProduct.discount_price > 0" >
                <div class="current-price">
                  <div class="value">{{eachFeatProduct.formated_price}}</div>
                </div>
                <div class="real-price">
                  <span class="value">{{eachFeatProduct.formated_list_price}}</span><br>
                  <span class="sub-slogan">List Price</span>
                </div>
              </div>
              <div class="retail_save" *ngIf="eachFeatProduct.discount_percent > 0">save {{eachFeatProduct.discount_percent}}% vs retail</div>
            </div>
          </a>
          <div class="options-wrapper">
            <button type="button" class="btn btn-outline-secondary add-cart-btn" (click)="addToCart(eachFeatProduct,  $event)">ADD</button>
            <div class="love-wrapper">
                  <span (click)="addTowishPromo(eachFeatProduct, index)" class="cursor-pointer">
                     <i *ngIf="eachFeatProduct.wishlist === 0" aria-hidden="true" class="fa fa-heart-o"></i>
                      <i *ngIf="eachFeatProduct.wishlist === 1" aria-hidden="true" class="fa fa-heart"></i>
                  </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="each-banner-section-wrapper hidden-xs">
    <div class="two-cal-banner">
      <div class="row">
        <div class="col-md-6">
          <div class="hz-banner-wrapper">
            <div class="banner-hz-wrapper">
              <a  href="javascript:void(0)">
                <div class="banner-wrapper clearfix">
                  <div class="section-left">
                    <div class="banner-title">
                    <h2 class="heading" style="text-transform: uppercase">{{homeData.banner[4].caption}}</h2>
                    <p class="slogan">{{homeData.banner[4].description}}</p>
                    <a href="{{homeData.banner[4].link}}" class="btn banner-btn">EXPLORE</a>
                  </div>
                  </div>
                  <div class="section-right">
                    <div class="image-wrapper">
                    <img class="lazy shown" src="{{homeData.banner[4].image}}" data-src="" alt="">
                  </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="hz-banner-wrapper">
            <div class="banner-hz-wrapper">
              <a  href="javascript:void(0)">
                <div class="banner-wrapper clearfix">
                  <div class="section-left">
                    <div class="banner-title">
                    <h2 class="heading"  style="text-transform: uppercase">{{homeData.banner[5].caption}}</h2>
                    <p class="slogan">{{homeData.banner[5].description}}</p>
                    <a href="{{homeData.banner[5].link}}" class="btn banner-btn">EXPLORE</a>
                  </div>
                  </div>
                  <div class="section-right">
                    <div  class="image-wrapper">
                    <img  class="lazy shown" src="{{homeData.banner[5].image}}"
                          data-src="" alt="">
                  </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
