import { Component, OnInit } from '@angular/core';
import {environment} from '../../../environments/environment';
declare const $: any;

@Component({
  selector: 'app-add-payment',
  templateUrl: './AddPayment.component.html'
})
export class AddPaymentComponent implements OnInit {

  ENV = environment;
  menuOpen = false;
  use_commercial_address: false;
  constructor() {
    window.scrollTo(0, 0);
    this.closeSideMenu();
  }
  ngOnInit(): void {}
  checkCheckBoxvalue = (event) => {
    this.use_commercial_address = event.target.checked;
  };

  closeSideMenu() {
    $('.nav-bar-mobile').removeClass('visible');
    $('.hamburger .fa-times').hide();
    $('.hamburger .fa-bars').show();
    $('.display-overflow').hide();
    this.menuOpen = false;
  }
}
