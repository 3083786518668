// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  ASSET_URL: 'http://localhost/projects/a1daam-dev/src/assets',
  API_URL: 'https://admin.a1daam.com/api/v1',
  XApiKey: '8cc0880ckk08k0wgswsook4o00oo0gw84wo8ggo0',
  ASSETS_URL: function () {
    if(JSON.parse(localStorage.getItem('_site_config_')) !== null){
      return  JSON.parse(localStorage.getItem('_site_config_')).assets;
    }
    return false;
  },
  STORAGE_URL: function () {
    if(JSON.parse(localStorage.getItem('_site_config_')) !== null){
      return  JSON.parse(localStorage.getItem('_site_config_')).upload_uri;
    }
    return false;
  },

  checkUserIsLogin: () => {

  }


};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
