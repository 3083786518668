import { Component, OnInit } from '@angular/core';
import {environment} from '../../environments/environment';
import {Router} from "@angular/router";
declare const $: any;

@Component({
  selector: 'app-price-spring',
  templateUrl: './PrinceSpring.component.html',
})
export class PrinceSpringComponent implements OnInit {

  ENV = environment;
  menuOpen = false;
  constructor(private router: Router) {
    this.ProductList = this.shuffle(this.ProductList);
    setTimeout(() => {
      this.Loading = false;
    }, 1000);

    router.events.subscribe((val) => {
      this.Loading = true;
      setTimeout(() => {
        this.Loading = false;
      }, 1000);
    });
    window.scrollTo(0, 0);
    this.closeSideMenu();

  }
  Loading = true;
  ProductList = [
    {img: 'product_1_0.jpg', price: '$3.99', like: '120'},
    {img: 'product_2_0.jpg', price: '$13.99', like: '25'},
    {img: 'product_3_0.jpg', price: '$32.99', like: '85'},
    {img: 'product_4_0.jpg', price: '$42.99', like: '74'},
    {img: 'product_5_0.jpg', price: '$15.99', like: '65'},
    {img: 'product_6_0.jpg', price: '$3.99', like: '120'},
    {img: 'product_7_0.jpg', price: '$13.99', like: '25'},
    {img: 'product_8_0.jpg', price: '$32.99', like: '85'},
    {img: 'product_9_0.jpg', price: '$42.99', like: '74'},
    {img: 'product_10_0.jpg', price: '$15.99', like: '65'},
    {img: 'product_11_0.jpg', price: '$3.99', like: '120'},
    {img: 'product_12_0.jpg', price: '$13.99', like: '25'},
    {img: 'product_1_0.jpg', price: '$3.99', like: '120'},
    {img: 'product_2_0.jpg', price: '$13.99', like: '25'},
    {img: 'product_3_0.jpg', price: '$32.99', like: '85'},
    {img: 'product_4_0.jpg', price: '$42.99', like: '74'},
    {img: 'product_5_0.jpg', price: '$15.99', like: '65'},
    {img: 'product_6_0.jpg', price: '$3.99', like: '120'},
    {img: 'product_7_0.jpg', price: '$13.99', like: '25'},
    {img: 'product_8_0.jpg', price: '$32.99', like: '85'},
    {img: 'product_9_0.jpg', price: '$42.99', like: '74'},
    {img: 'product_10_0.jpg', price: '$15.99', like: '65'},
    {img: 'product_11_0.jpg', price: '$3.99', like: '120'},
    {img: 'product_12_0.jpg', price: '$13.99', like: '25'},
  ];

  selectAcc(e) {
    let trigger = $(e.target);
    $('.box').removeClass('active');
    trigger.closest('.option-wrapper').find('.box').toggleClass('active');
  }


  shuffle = (a) => {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  };







  ngOnInit(): void {


  }

  closeSideMenu() {
    $('.nav-bar-mobile').removeClass('visible');
    $('.hamburger .fa-times').hide();
    $('.hamburger .fa-bars').show();
    $('.display-overflow').hide();
    this.menuOpen = false;
  }
}
