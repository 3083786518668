<div class="category-wrapper">
  <div class="breadcum-wrapper">
    <div class="breadcrumbs">
      <span>
        <a [routerLink]="['']">Home</a>
        <span class="icon"> > </span>
      </span>
      <span>
        <a [routerLink]="['/product/subscriptions']">Favorites</a>
        <span class="icon">  </span>
      </span>
    </div>
  </div>
  <div class="page-wrapper">
    <div class="under-category">
      <div class="loading" *ngIf="Loading == true">
        <div class="blobs">
          <div class="blob-center"></div>
          <div class="blob"></div>
          <div class="blob"></div>
          <div class="blob"></div>
          <div class="blob"></div>
          <div class="blob"></div>
          <div class="blob"></div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
          <defs>
            <filter id="goo">
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
              <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
              <feBlend in="SourceGraphic" in2="goo" />
            </filter>
          </defs>
        </svg>
      </div>
      <div class="container-fluid">
        <div class="page-heading">
          <div class="row">
            <div class="col-md-6">
              <div class="title">Favorites</div>
              <div class="slogan">We keep track of the products you love so you can find them all in one place</div>
            </div>
            <div class="col-md-6">
            </div>
          </div>
        </div>
        <div class="products-list-wrapper">
          <div class="row" *ngIf="ProductData != null">
            <div class="col-md-2" *ngFor="let product of ProductData; let index = index;">
              <div class="each-product" *ngIf="product.wishlist == 1" [ngStyle]="{'filter': (product.hide == 1) ? 'grayscale(100%)' : ''}">
                <div class="sale" *ngIf="product.discount_price > 0">sale</div>
                <a  [routerLink]="['/product',product.slug]">
                  <div class="cover-image">
                    <img [ngClass]="{'img0' : product.photos.length > 0}" src="{{product.image_url}}" alt="">
                    <img *ngIf="product.photos.length > 0" class="img1" src="{{product.photos[0].photo_url}}" alt="">
		    <div class="sub-feature" *ngIf="product.discount_price > 0 && product.hide == 0">OFFERED PRODUCT</div>
	            <div class="sub-feature" *ngIf="product.hide == 1">OUT OF STOCK</div>
                  </div>
                </a>
                <div class="product-details-info">
                  <a  [routerLink]="['/product',product.slug]">
                    <div class="product-title">{{product.name}} </div>
                    <div class="p-subtitle"  *ngIf="product.variants != []">
                      <div class="text-1" *ngFor="let v of product.variants; let kk = index">{{v.name}}  <i *ngIf="product.variants.length != kk+1" class="dot fa fa-circle"></i></div>
                    </div>
                    <span *ngIf="product.discount_price == 0" class="price">{{product.formated_price}}</span>
                    <div  *ngIf="product.discount_price > 0" class="product-price-wrapper">
                      <div class="current-price">
                        <div class="value">{{product.formated_price}}</div>
                      </div>
                      <div class="real-price">
                        <span class="value">{{product.formated_list_price}}</span><br>
                        <span class="sub-slogan">List Price</span>
                      </div>
                    </div>
                    <div class="retail_save" *ngIf="product.discount_percent > 0">save {{product.discount_percent}}% vs retail</div>
                  </a>
                  <div class="options-wrapper">
		    <button type="button" class="btn btn-outline-secondary add-cart-btn" [ngClass]="{'disabled': product.hide == 1}" (click)="product.hide == 0 ? addToCart(product, $event) : null">ADD</button>
                    <div class="love-wrapper">
                      <span class="cursor-pointer" (click)="addTowish(product)">
                        <i aria-hidden="true" class="fa fa-heart"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="margin-40"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
     <!-- <div class="container-fluid">
        <div class="page-heading">
          <div class="row">
            <div class="col-md-6">
              <div class="title mini">Here's Some Recommendations For You</div>
            </div>
          </div>
        </div>
        <div class="products-list-wrapper">
          <div class="row">
            <div class="col-md-2" *ngFor="let product of ProductListRecommended; let index = index;">
              <div class="each-product">
                <a [routerLink]="['/product/'+index]">
                  <div class="cover-image">
                    <img src="{{ENV.ASSET_URL}}/dummy/product/{{product.img}}" alt="">
                  </div>
                </a>
                <div class="product-details-info">
                  <a [routerLink]="['/product/'+index]">
                    <div class="product-title">Hint Fruit Infused Water Variety Pack</div>
                    <div class="product-quantity">12 x 16 oz</div>
                    <div class="product-price-wrapper">
                      <div class="current-price">
                        <div class="value">$14.56</div>
                      </div>
                    </div>
                    <div class="retail_save">save 32% vs retail</div>
                  </a>
                  <div class="options-wrapper">
                    <button type="button" class="btn btn-outline-secondary add-cart-btn">ADD</button>
                    <div class="love-wrapper"><span><i class="fa fa-heart-o" aria-hidden="true"></i></span> <span
                      class="count">10.3k</span></div>
                  </div>
                </div>
                <div class="margin-40"></div>
              </div>
            </div>
          </div>
        </div>
      </div>-->
    </div>
  </div>
</div>



