import { Component, OnInit } from '@angular/core';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import {environment} from '../../../environments/environment';
import {CategoryService} from '../../services/category.service';
import {AuthenticationService} from '../../services/authentication.service';
declare const $: any;
declare let toastr: any;
declare let Ladda: any;

@Component({
  selector: 'app-best-sellers',
  templateUrl: './bestSellers.component.html',
})
export class BestSellersComponent implements OnInit {

  menuOpen = false;
  ENV = environment;
  Loading = true;
  is_logged = false;
  ProductData: any = [];
  CategoryData: any = null;
  BrandData: any = null;
  lastPage: any = '';
  errMsg: any = '';
  param = {
    max_price: '',
    min_price: '',
    brand_code: '',
    order_by: 'views,desc',
    page: 1,
    search: '',
  };
  cartParam: any = {
    quantity: 1,
    option: 2,
    cart_id: '',
  };
  catCode: any = '';
  constructor(private router: Router, protected authenticationService : AuthenticationService,   protected categoryService : CategoryService,  private route: ActivatedRoute) {
    const Auth = this.authenticationService.getAuthFromSession();
    if (Auth !== null) {
      this.is_logged = Auth.is_logged;
      this.cartParam.cart_id = Auth.cart_id;
    }
    this.categoryService.currentCategory.subscribe(data => {
      if (data == null) {
        // do nothing
      } else {
        this.CategoryData = data;
        this.getProductByCategory()
      }
    });
    this.categoryService.currentBrand.subscribe(data => {
      if (data == null) {
        // do nothing
      } else {
        this.BrandData = data;
      }
    });
    window.scrollTo(0, 0);
    this.closeSideMenu();

  }


  getProductByCategory() {
    this.Loading = true;
    this.errMsg = '';
    this.categoryService.getProductByCategory(this.catCode, this.param).subscribe( res => {
      if (res.status === true) {
        if (this.param.page === 1) {
          this.ProductData = [];
          this.lastPage = res.page_info.total;
          this.param.page += 1;
          res.data.forEach((v) => {
            this.ProductData.push(v);
          });
          console.log(this.lastPage)
        } else {
          this.param.page += 1;
          res.data.forEach((v) => {
            this.ProductData.push(v);
          });
          this.lastPage = res.page_info.total;
        }
        this.Loading = false;
      } else {
        this.ProductData = [];
        this.Loading = false;
        this.errMsg = res.message
      }
    })
  }

  loadMore() {
    this.getProductByCategory()
  }

  addTowish(product, i) {
    const productId = product.id;
    if (this.is_logged == true) {
      if (product.wishlist == 0) {
        this.categoryService.apiAddWish(productId).subscribe( res => {
          if (res.status === true) {
            this.ProductData[i].wishlist = 1;
          } else {
            $('#login').modal('show');
          }
        });
      } else {
        this.categoryService.apiDeleteWish(productId).subscribe( res => {
          if (res.status === true) {
            this.ProductData[i].wishlist = 0;
          }
        });
      }
    }  else {
      $('#login').modal('show');
    }
  }

  addToCart(product, e) {
    let ldd = e.target;
    let ladda = Ladda.create(ldd);
    if (this.is_logged === true) {
      ladda.start();
      this.categoryService.apiAddCart(this.cartParam, product.id).subscribe( res => {
        if (res.status === true && res.is_logged === true) {
          this.getCartDetail();
          ladda.stop();
        } else if (res.status === false) {
          toastr.error(res.message);
          toastr.options.closeButton = true;
          ladda.stop();
        }
      })
    } else {
      let cart_id = localStorage.getItem('guestCartID');
      cart_id = JSON.parse(cart_id);
      if (cart_id != null) {
        this.cartParam.cart_id = cart_id;
        ladda.start();
        this.categoryService.apiAddCart(this.cartParam, product.id).subscribe( res => {
          ladda.stop();
          if (res.status === true ) {
            this.getCartDetail();
          } else {
            toastr.error(res.message);
            toastr.options.closeButton = true;
          }
        })
      } else {
        ladda.start();
        this.categoryService.apiAddCart(this.cartParam, product.id).subscribe( res => {
          ladda.stop();
          if (res.status === true ) {
            this.cartParam.cart_id = res.cart_id;
            let cartID_set = JSON.stringify(res.cart_id);
            localStorage.setItem("guestCartID", cartID_set);
            this.getCartDetail();
          } else {
            toastr.error(res.message);
            toastr.options.closeButton = true;
          }
        });
      }
    }
  }

  getCartDetail() {
    this.categoryService.apiGetCart(this.cartParam.cart_id).subscribe( res => {
      if (res.status === true) {
        this.authenticationService.updateCart(res.data);
      }
    })
  }



  ngOnInit(): void {
  }

  selectAcc(e, code) {
    this.param.page = 1
    this.param.brand_code = code;
    this.getProductByCategory();
    let trigger = $(e.target);
    $('.box').removeClass('active');
    trigger.closest('.option-wrapper').find('.box').toggleClass('active');

  }

  openCatUnderMenu(e) {
    let trigger = $(e.target);
    trigger.closest('.list-li').find('.under-menu').toggle();
    /* let me =  trigger.closest('.list-li').find('.under-menu');
     if(me.is(":visible")){
       $('.under-menu').hide();
     } else{
       $('.under-menu').hide();

     }*/
  }

  closeSideMenu() {
    $('.nav-bar-mobile').removeClass('visible');
    $('.hamburger .fa-times').hide();
    $('.hamburger .fa-bars').show();
    $('.display-overflow').hide();
    this.menuOpen = false;
  }


}
