<div class="-user-settings-wrapper">
  <div class="breadcum-wrapper">
    <div class="breadcrumbs">
      <span>
        <a [routerLink]="['']">Home</a>
        <span class="icon"> > </span>
      </span>
      <span>
        <a [routerLink]="['/user/account-settings']">Settings</a>
        <span class="icon"> > </span>
      </span>
      <span>
        <a [routerLink]="['/user/list-address']">Manage Shipping</a>
        <span class="icon">  </span>
      </span>
    </div>
  </div>
  <div class="container">
    <div class="page-heading">
      <div class="title">Manage Shipping</div>
      <div class="slogan">Add and delete your addresses for quick checkout</div>
      <div class="margin-30"></div>
    </div>
    <div class="row" *ngIf="addressData != null">
      <div class="col-12" >
        <div>Select a shipping address:</div>
        <div class="margin-30"></div>
        <div class="add-box" *ngFor="let address of addressData">
          <div class="name">{{address.first_name}} {{address.last_name}}</div>
          <div class="">{{address.line1}}</div>
          <div class="">{{address.line2}}</div>
          <div class="">{{address.city}}, {{address.postal_code}}</div>
          <div class="">{{address.phone}}</div>
          <div class="margin-10"></div>
          <div class=""> <span class="default mr-5 cursor-pointer font-weight-bold">Default</span>  <span class="text-danger cursor-pointer font-weight-bold" (click)="openModal(address.id)">Remove</span></div>
        </div>
      </div>
    </div>
    <div class="margin-30"></div>
    <div class="row">
      <div class="col-md-6">
        <a [routerLink]="['/user/add-address']" class="btn btn-theme w-100">ADD NEW ADDRESS</a>
        <div class="margin-30"></div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="delete_add" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><img width="20px" src="{{ENV.ASSET_URL}}/icons/close.png" alt=""></span>
        </button>
      </div>
      <div class="modal-body">
        <div>Delete This Item?</div>
        <div class="margin-10"></div>
        <div class="text-muted text-danger"> Are you sure you want to delete this address from your saved shipping options?</div>
        <div class="margin-30"></div>
        <button *ngIf="loading === false" type="submit" class="btn btn-theme w-100" (click)="deleteAddress()">YES, DELETE</button>
        <button *ngIf="loading === true" disabled type="button" class="btn btn-theme w-100">YES, DELETE <i class="fa fa-spinner fa-spin"></i></button>
      </div>
    </div>
  </div>
</div>
